import {
  SymbolArsw,
  SymbolAstr,
  SymbolBnb,
  SymbolBusd,
  SymbolDai,
  SymbolMatic,
  SymbolUsdc,
  SymbolUsdt,
  SymbolWbtc,
  SymbolWeth,
  SymbolWsdn,
  SymbolSio2,
  SymbolWastr,
  SymbolBai,
  SymbolWbnb,
  SymbolWdot,
  SymbolNastr,
} from "src/assets/img/symbols/symbols";
import { Asset, AssetSymbol } from "src/types/models";

export const LISTED_ASSET_SYMBOLS = [
  "ASTR",
  "DOT",
  "BAI",
  "USDC",
  "USDT",
  "BUSD",
  "DAI",
  "WETH",
  "WBTC",
  "SBY",
  "BNB",

  "MATIC",

  "SDN",
  "ARSW",
  "SiO2",
  "WASTR",
  "WDOT",
  "WBNB",

  "NSBY",
  "nASTR"
] as const;

export const SYMBOL_ORDER: Record<AssetSymbol, number> =
  LISTED_ASSET_SYMBOLS.reduce(
    (res, symbol, idx) => ({
      ...res,
      [symbol]: idx,
    }),
    {}
  ) as Record<AssetSymbol, number>;

export const ASSETS_DICT: { [key in AssetSymbol]: Asset } = {
  ASTR: {
    symbol: "ASTR",
    name: "Astar",
    icon: SymbolAstr,
  },
  WETH: {
    symbol: "WETH",
    name: "Wrapped Ether",
    icon: SymbolWeth,
  },
  WBTC: {
    symbol: "WBTC",
    name: "Wrapped BTC",
    icon: SymbolWbtc,
  },
  SDN: {
    symbol: "SDN",
    displaySymbol: "WSDN",
    name: "Wrapped SDN",
    icon: SymbolWsdn,
  },
  USDC: {
    symbol: "USDC",
    name: "ceUSD Coin",
    displaySymbol: "ceUSDC",
    icon: SymbolUsdc,
  },
  USDT: {
    symbol: "USDT",
    name: "Tether ceUSD",
    displaySymbol: "ceUSDT",
    icon: SymbolUsdt,
  },
  DAI: {
    symbol: "DAI",
    name: "DAI",
    icon: SymbolDai,
  },
  BUSD: {
    symbol: "BUSD",
    name: "Binance USD",
    icon: SymbolBusd,
  },
  BNB: {
    symbol: "BNB",
    name: "Binance Coin",
    icon: SymbolBnb,
  },
  MATIC: {
    symbol: "MATIC",
    name: "Polygon Matic",
    icon: SymbolMatic,
  },
  ARSW: {
    symbol: "ARSW",
    name: "ArthSwap Token",
    icon: SymbolArsw,
    borrowUnsupported: true,
  },
  SiO2: {
    symbol: "SiO2",
    name: "SiO2 Token",
    icon: SymbolSio2,
    borrowUnsupported: true,
  },
  SBY: {
    symbol: "SBY",
    name: "Shibuya Token",
    icon: SymbolAstr,
  },
  WASTR: {
    symbol: "WASTR",
    name: "WASTR",
    icon: SymbolWastr,
  },
  BAI: {
    symbol: "BAI",
    name: "BAI",
    icon: SymbolBai,
  },
  WBNB: {
    symbol: "WBNB",
    name: "WBNB",
    icon: SymbolWbnb,
  },
  DOT: {
    symbol: "DOT",
    name: "Polkadot",
    icon: SymbolWdot,
  },
  WDOT: {
    symbol: "WDOT",
    name: "Polkadot",
    icon: SymbolWdot,
  },
  NSBY: {
    symbol: "NSBY",
    name: "NSBY",
    icon: SymbolNastr,
  },
  nASTR: {
    symbol: "nASTR",
    name: "nASTR",
    icon: SymbolNastr,
  },
} as const;

export const ASSETS: Asset[] = Object.values(ASSETS_DICT);

export const ARTHSWAP_ASSETS_DICT: {
  [key in string]: { symbol: string; icon: string };
} = {
  ...ASSETS_DICT,
  WSDN: ASSETS_DICT.SDN,
} as const;
