/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "Được kiểm toán bởi",
    "Powered by": "Được cung cấp bởi",
    "Please, connect your wallet": "Hãy liên kết với ví của bạn",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      "Liên kết ví để có thể theo dõi được số tiền đã ký gửi, vay và những vị thế đang mở",
    "Connect Wallet": "Liên kết ví",
    "You need to change to a supported chain":
      "Bạn cần phải chuyển sang chain được hỗ trợ",
    "Please switch the chain with your wallet.":
      "Hãy chuyển sang chainđược hỗ trợ trên ví của bạn",
    "Failed to switch the chain": "Chuyển đổi chain không thành công",
    "In local-dev mode, only localhost:8545 network can be connected":
      "Ở chế độ local-dev mode, chỉ localhost:8545 network có thể kết nối",
    "SiO2 Finance is not yet depolyed to this network":
      "Sio2 chưa được triển khai trên chuỗi này",
    "Please switch to": "Hãy chuyển sang",
    "Change Network": "Thay đổi mạng lưới",
    "No Data": "Không có dữ liệu",
    Show: "buổi bieu diễn",
    Hide: "ẩn giấu",
    "Copy Success": "Sao chép thành công",
    "No account": "Không có tài khoản",
    Network: "Mạng lưới",
    "Copy Address": "Sao chép địa chỉ",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "Ngắt kết nối ví",
    Menu: "Thực đơn",
    "Global Setting": "Cài đặt",
    "Dark Mode": "Chế độ tối",
    "Select Language": "Lựa chọn ngôn ngữ",
    "Add Tokens": "Thêm Token",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "Chúng tôi chưa ra mắt công khai mã thông báo SIO2, vì vậy mã thông báo SIO2 không có thị trường \\ ngiá ngay bây giờ. Do đó, chúng tôi tính APR dựa trên giá mã thông báo ước tính, $ \\ n",
        ["SIO2_PRICE"],
        ". Để biết thêm thông tin, vui lòng truy cập tài liệu của chúng tôi.",
      ],
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn&#39;t have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        " Hiện tại chúng tôi chưa tổ chức vòng bán công khai cho token SIO2 vì vậy token vẫn chưacó giá trên thị trường. Vì thế chúng tôi tính toán APR dựa trênđịnh giá ước tính ",
        ["SIO2"],
        ".Để biết",
      ],
    Borrow: "Vay",
    Repay: "Thanh toán",
    Amount: "Số lượng",
    Max: "Tối đa",
    "Transaction overview": "Thông tin giao dịch",
    "Borrow APY": "Chi phí vay",
    "Borrow APR": "Thưởng khi vay",
    Borrowed: "Lượng tiền đã vay",
    "Borrow Limit Used": "Giới hạn đã vay",
    "Health Factor": "Yếu tố sức khỏe",
    Liquidity: "Thanh khoản",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "Vì lãi suất và giá trị luôn biến động, giá trị lớn nhất của max có thể không trả hết được tất",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      'Josh đây! Tuần đầu tiên là "Tuần lễ Canary " của mỏ pha lê của chúng tôi. Chúng tôi khuyến khích người dùng duy trì hệ số sức khỏe> 4 để chuẩn bị cho mọi điều kiện thị trường. Đọc <0> bài báo </0> để biết thêm chi tiết.',
    "Josh here!": "Josh here!",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      'Josh đây! Tuần đầu tiên là "Tuần lễ Canary " của mỏ pha lê của chúng tôi. Chúng tôi khuyến khích người dùng duy trì hệ số sức khỏe> 4 để chuẩn bị cho mọi điều kiện thị trường. Đọc <0> bài báo </0> để biết thêm chi tiết.',
    "{0}": [["0"]],
    Collateral: "Tài sản thế chấp",
    ON: "trên",
    OFF: "TẮT",
    Collateral_Amount: "Số tài sản thế chấp",
    "Borrowed Available": "Lượng vay có sẵn",
    Disable: "Vô hiệu hoá",
    Enable: "Cho phép",
    Deposited: "Đã ký gửi",
    Supply: "Cung cấp",
    Withdraw: "Rút",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "Để nhận <0>phần thưởng đặt cược ASTR</0> từ <1>Algem</1>, vui lòng cung cấp $nASTR của bạn thông qua <2>bộ chuyển đổi SiO2</2> trong Algem DApp.",
    "Supply APY": "Lãi suất ký gửi",
    "Supply APR": "Thưởng khi ký gửi",
    "Borrow Available": "Lượng tiền có thể vay",
    "This asset can't be used as collateral.":
      "Không thể sử dụng tài sản này làm tài sản thế chấp.",
    "Wallet Balance": "Tổng tài sản",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "Vì tiền lãi sẽ tiếp tục được tích lũy khi xác nhận giao dịch, nên số tối đa đã nhập có thể không phản ánh tổng số tiền bạn kiếm được khi giao dịch hoàn tất",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      "Bởi vì lãi suất và giá trị luôn biến động theo thời gian, giá trị tối đa có thể sẽ không thể xoá",
    "This asset can&#39;t be used as collateral.":
      "Tài sản này không thể dùng làm thế chấp",
    Dashboard: "bảng điều khiển",
    Market: "Thị trường",
    Stake: "Cổ phần",
    "Public Sale": "Vòng bán công khai",
    Faucet: "Vòi",
    "Transaction Preparing": "Chuẩn bị giao dịch OFF",
    "Waiting for transaction to be ready...": "Đang chờ giao dịch sẵn sàng ...",
    "Confirm Transaction": "Xác nhận giao dịch",
    "Approve sending the ERC-20 asset to the pool.":
      "Chấp thuận gửi tài sản ERC-20 vào nhóm.",
    "Transaction Pending": "Giao dịch đang chờ xử lý",
    "Waiting for the transaction to be confirmed...":
      "Đang chờ giao dịch được xác nhận ...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "Phê duyệt hợp đồng vay tài sản ERC-20 bằng khoản tín dụng của bạn.",
    "Confirm the transaction.": "Xác nhận giao dịch.",
    "Transaction Submitted": "Giao dịch đã được gửi",
    "Succeeded!": "Đã thành công!",
    "Your transaction confirmed!": "Giao dịch của bạn đã được xác nhận!",
    "Transaction Canceled": "Giao dịch bị hủy",
    "You have canceled the transaction.": "Bạn đã hủy giao dịch.",
    Error: "Lỗi",
    "Enter amount": "Nhập số tiền",
    "No balance to deposit": "Không có số dư để gửi",
    "No balance to withdraw": "Không có số dư để rút",
    "Insufficient collateral": "Tài sản thế chấp không đủ",
    "No liquidity to withdraw": "Không có thanh khoản để rút",
    "Health factor too low": "Hệ số sức khỏe quá thấp",
    "Borrowing limit reached": "Đã đạt đến hạn mức vay",
    "No liquidity to borrow": "Không có thanh khoản để vay",
    "Not enough wallet balance to repay": "Không đủ số dư trong ví để trả nợ",
    "No borrowed balance to repay": "Không có số dư đã vay để trả lại",
    "Enter leverage": "Nhập đòn bẩy",
    "No balance to loop": "Không có số dư để lặp lại",
    "Supply Balance": "Lượng tiền ký gửi",
    "Borrow Balance": "Lượng tiền vay",
    Asset: "Tài sản",
    "Reward APR": "Tỷ lệ thưởng",
    "Interest Rate": "Lãi suất",
    "Borrow Markets": "Thị trường vay",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "Trung bình lợi suất của tất cả tài sản ký gửi bao gồm cả phần thưởng khuyến khích.",
    Supplied: "Đã ký gửi",
    "Supply Markets": "Thị trường ký gửi",
    "Net APY": "Lãi suất ròng",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "APY ròng là hiệu quả tổng hợp của tất cả các vị thế cung và vay trên giá trị ròng, bao gồm cả các ưu đãi. Có thể có APY ròng âm nếu APY nợ cao hơn APY cung.",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "Yếu tố sức khỏe của bạn và khoản vay để định giá xác định sự đảm bảo cho tài sản thế chấp của bạn. Để tránh bị thanh lý, bạn có thể",
    "1. supply more collateral": "1. Cung cấp thêm tài sản thế chấp",
    "2. repay borrow positions": "2. hoàn trả các vị trí đã vay",
    "When your health factor is below 1, your collateral will be liquidated.":
      "Khi chỉ số health factor dưới 1, tài sản thế chấp của vợ sẽ bị thanh lý.",
    "RISK DETAILS": "THÔNG TIN RỦI RO",
    Claim: "Yêu cầu",
    "Available SIO2 Rewards": " Phần thưởng SIO2 có sẵn",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      "Mặc dù phần thưởng SIO2 tích lũy mỗi phút, giá trị trên giao diện người dùng chỉ cập nhật khi bạn giao dịch với thị trường cung ứng và thị trường vay. Do đó, bạn có thể nhận được nhiều phần thưởng hơn số phần thưởng được hiển thị khi nhấp vào 'Yêu cầu'.",
    "Learn more": "Tìm hiểu thêm",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including":
      "Lợi suất ròng là hiệu quả tổng hợp của tất cả các vị thế cung và vay trên giá trị ròng, bao",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid":
      "Health factor của bạn và tỷ lệ cho vay trên giá trị xácđịnh sựđảm bảo tài sản thế chấp của",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when youtransact with the supply and borrow markets. Therefore you may receive more rewards than is":
      "Mặc dù phần thưởng SIO2 tích lũy mỗi phút, giá trị trên giao diện người dùng chỉ cập nhậtkhi bạn giao dịch với thị trường cung ứng và thị trường vay. Do đó, bạn có thể nhận được",
    Name: "Tên",
    Action: "Hoạt động",
    Mint: "cây bạc hà",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "Để ngăn chặn chương trình thư rác, vòi testnet của chúng tôi chỉ cho phép mỗi người dùng một phút mỗi nội dung mỗi ngày.",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each":
      "Để tránh bot spam, testnet faucet của chúng tôi chỉ hỗ trợ một lượt trên mỗi người dùng",
    "Total Supplied": "Tổng tiền ký gửi",
    "Total Borrowed": "Tổng tiền đã vay",
    "Supply Reward APR": "Thưởng khi ký gửi",
    "Borrow Interest Rate": "Lãi suất vay",
    "Borrow Reward APR": "Thưởng khi vay",
    Details: "Thông tin chi tiết",
    Price: "Giá",
    Borrowing: "Vay",
    Yes: "Đồng ý",
    No: "Không đồng ý",
    "Reserve Factor": "Reverse Factor",
    "Used as Collateral": "Sử dụng làm tài sản thế chấp",
    "Loan to Value": "Tỷ lệ cho vay",
    "Liquidation Threshold": "Ngưỡng thanh lý",
    "Liquidation Penalty": "Tiền phạt thanh lý",
    "Market Details": "Thông tin thị trường",
    "Asset Pools": "Bể tài sản",
    Detail: "Thông tin chi tiết",
    Others: "Khác",
    total: "tổng",
    "Top3 Markets": "3 thị trường đứng đầu",
    "Astar Network": "Mạng Astar",
    Token: "Token",
    "SIO2 Token(SIO2)": "Mã thông báo SIO2 (SIO2)",
    "Total Raised": "Số tiền đã kêu gọi",
    "Sales Opened": "Vòng bán đã mở",
    TBD: "chưa giải quyết",
    "Sales Closed": "Vòng bán đã kết thúc",
    "This page is developed for public sale on mainnet, but not testnet.":
      "Trang web này được thiết kế cho vòng mở bán công khai trên mainet, không hỗ trợ trên",
    "SIO2 PUBLIC SALE": "SIO2 BÁN CÔNG CHÚNG",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      "Phân bổ token: Số token sẽ được phân bổ trong vòng hai năm, bắt đầu sau 6 tháng tính từ",
    Purchase: "Mua",
    "$ {0}": ["$ ", ["0"]],
    "Stake SIO2": "Stake SIO2",
    Staking: "Staking",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "SIO2 holder có thể stake SIO2 của họ vào Safety Module để cung cấp bảo mật cho giaothức và nhận được phần thưởng khuyến khích khi tham gia. Trong trường hợp giáđi xuốngmạnh, trên 30% lượng stake của bạn có thể bị mất để bù đắp sự thiếu hụt, cung cấp một lớp",
    "Total emission per day": "Tổng lượng phát thải mỗi ngày",
    "Claim Rewards": "Nhận phần thưởng",
    "Funds in the Safety Module": "Lượng tài sản trong Safety Module",
    "1 year": "1 năm",
    "2 years": "2 năm",
    "3 years": "3 năm",
    "The amount you're trying to stake exceeds your balance by {0} SIO2": [
      "Số tiền bạn đang cố đặt cược vượt quá số dư của bạn ",
      ["0"],
      " SIO2",
    ],
    "Locked Staking": "Staking đã được khoá",
    Balance: "Số lượng",
    "Duration (years)": "Thời gian (năm)",
    "Pool Share": "Cổ phần",
    Confirm: "Xác nhận",
    "The amount you&#39;re trying to stake exceeds your balance by {0} SIO2": [
      "Số tiền bạn đang stake vượt quá số dư ",
      ["0"],
      " SIO2 mà bạn đang có",
    ],
    ID: "ID",
    "Staked Amount": "Số lượng stake",
    "Weighted Staked Amount": "Số lượng",
    "Date Time": "Ngày giờ",
    "Locked Until": "Khoá cho tới",
    Redeem: "Chuộc lại",
    "Locked Stakes": "Stake đã khoá lại",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      "Bạn chưa có bất kỳ cổ phần bị khóa nào. Bắt đầu bằng cách đặt mã thông báo SIO2 của bạn.",
    "You don&#39;t have any locked stakes yet. Start by staking your SIO2 tokens.":
      "Bạn chưa khoá token SIO2 nào, hãy stake SIO2",
    Summary: "Tổng quan",
    "Staking APR": "Staking APR",
    "Unweighted Stake": "Stake",
    "Weighted Stake": "Stake trọng tâm",
  },
};
