import { BigNumber } from "submodule/packages/math-utils/dist/cjs";
import dayjs from "dayjs";
import { ChainId, getNetworkConfig } from "src/libs/config";
import { PoolDataProviderInterface } from "src/libs/pool-data-provider";
import { onlyListed } from "src/utils/assets";
import useSWR from "swr";
import { usePoolDataProvider } from "../usePoolDataProvider";
import { toAssetMarketData } from "./converters";
import { MarketData } from "./types";

export type { MarketData };

export const useMarketData = () => {
  const { data } = usePoolDataProvider();
  //const { data: sio2PriceInUSD } = useSiO2Price();
  // TODO: just for test, remove if with a solution
  const sio2PriceInUSD = new BigNumber("1");
  return useSWR(
    () => data && sio2PriceInUSD && ["marketdata", data?.chainId],
    (_key: string, chainId: ChainId) => getMarketData(data!.provider, chainId, sio2PriceInUSD!),
    { dedupingInterval: 10000, refreshInterval: 15000 }
  );
};

const getMarketData = async (
  provider: PoolDataProviderInterface,
  chainId: ChainId,
  sio2PriceInUSD: BigNumber
): Promise<MarketData> => {
  const currentTimestamp = dayjs().unix();
  const { rewardToken } = getNetworkConfig(chainId);
  const { reservesData, incentivesByUnderlyingAsset, marketReferenceCurrencyPriceInUSD, ...rawData } =
    await provider.getReservesWithIncentives(currentTimestamp, {
      address: rewardToken.underlyingAsset,
      priceInUSD: sio2PriceInUSD,
    });
  console.log(`reservesData:`, reservesData, marketReferenceCurrencyPriceInUSD, incentivesByUnderlyingAsset);
  const assets = reservesData
    .filter(onlyListed)
    .filter((asset) => !asset.isFrozen || asset.symbol === "BUSD")
    .map((reserve) =>
      toAssetMarketData(
        marketReferenceCurrencyPriceInUSD,
        reserve,
        incentivesByUnderlyingAsset[reserve.underlyingAsset]
      )
    );

  console.log(`assets:`, assets);

  return {
    assets,
    marketReferenceCurrencyPriceInUSD,
    marketTimestamp: currentTimestamp,
    chainId,
    ...rawData,
  };
};
