import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import "./index.less";
type StatisticProps = {
  imgSrc: string;
  titleDom?: React.ReactNode;
  label: string;
  tooltip?: string;
};
const Statistic: React.FC<StatisticProps> = ({
  imgSrc,
  titleDom,
  label,
  tooltip,
}) => {
  return (
    <div className="statistic">
      <img src={imgSrc} alt="" />
      <div className="statistic-con">
        <div className="label-wrap">
          <span className="label">{label}</span>
          {tooltip ? (
            <Popover
              content={tooltip}
              getPopupContainer={(trigger) =>
                trigger.parentElement || document.body
              }
            >
              <InfoCircleOutlined />
            </Popover>
          ) : null}
        </div>

        <div className="title-wrap">{titleDom || "-"}</div>
      </div>
    </div>
  );
};
export default Statistic;
