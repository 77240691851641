import { useWallet } from "@/hook/useWallet";
import { Button } from "antd";
import { useState } from "react";
import "./index.less";
import { t } from "@lingui/macro";
const MissConnectWallet: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const [loading, setLoading] = useState(false);
  const { connect } = useWallet();
  const connectWallet = async () => {
    try {
      setLoading(true);
      await connect("Metamask").catch(() => {});
      setLoading(false);
    } catch (e) {
      console.log("error on activation", e);
      // disconnectWallet();
    }
  };
  return (
    <div className="miss-connect-wallet" style={style}>
      <div className="img-wrap">
        <img src={require("@img/no-wallet.png")} alt="" />
      </div>
      <h3>{t`Please, connect your wallet`}</h3>
      <p>
        {t`Please connect your wallet to see your supplies, borrowings, and open positions.`}
      </p>
      <Button
        loading={loading}
        type="primary"
        className="btn-wrap"
        onClick={connectWallet}
      >
        {t`Connect Wallet`}
      </Button>
    </div>
  );
};
export default MissConnectWallet;
