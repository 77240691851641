import { useModalContext } from "@/hook/app/useModalContext";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "../index.less";
import { t } from "@lingui/macro";
const StakeRewardClaimModal: React.FC = () => {
  const { closeModal } = useModalContext();
  return (
    <div className="modal-body modal-stake-reward-claim">
      <div className="close-wrap" onClick={closeModal}>
        <CloseOutlined />
      </div>
      <div className="title-wrap">
        {/* <img src={1} alt="" /> */}
        <h3>{2}</h3>
      </div>

      <div className="overview-wrap">
        <span className="label">{t`Transaction overview`}</span>
        <div className="list-wrap"></div>
      </div>
      <div className="bottom-btn-wrap">
        <Button block type="primary">
          {t`Borrow`}
        </Button>
      </div>
    </div>
  );
};
export default StakeRewardClaimModal;
