import {
  normalize,
  normalizeBN,
  valueToBigNumber,
  valueToZDBigNumber,
} from '../../bignumber';
import { SECONDS_PER_YEAR, WEI_DECIMALS } from '../../constants';

const SECONDS_PER_MONTH = valueToZDBigNumber(2_592_000);
const BN_DECIMAL_PRECISION = valueToZDBigNumber(1e18);

export interface CalculateIncentiveAPRRequest {
  incentiveIntialSupply: string;
  incentiveInflactionStart: string;
  incentiveDecayRatio: string;
  rewardTokenPriceInMarketReferenceCurrency: string; // Can be priced in ETH or USD depending on market
  totasTokenSupply: string;
  priceInMarketReferenceCurrency: string; // Can be priced in ETH or USD depending on market
  decimals: number;
  rewardTokenDecimals: number;
}

// Calculate the APR for an incentive emission
export function calculateIncentiveAPR({
  incentiveIntialSupply,
  incentiveInflactionStart,
  incentiveDecayRatio,
  rewardTokenPriceInMarketReferenceCurrency,
  priceInMarketReferenceCurrency,
  totasTokenSupply,
  decimals,
}: CalculateIncentiveAPRRequest): string {
  const currentTime: number = Date.parse(new Date().toString()) / 1000;
  const curSpan = valueToZDBigNumber(currentTime)
    .minus(valueToZDBigNumber(incentiveInflactionStart))
    .dividedBy(SECONDS_PER_MONTH);
  const emissionPerSecond = valueToBigNumber(incentiveIntialSupply)
    .multipliedBy(
      valueToBigNumber(incentiveDecayRatio)
        .dividedBy(BN_DECIMAL_PRECISION)
        .exponentiatedBy(curSpan),
    )
    .dividedBy(SECONDS_PER_MONTH);

  const emissionPerSecondNormalized = normalizeBN(
    emissionPerSecond,
    WEI_DECIMALS,
  ).multipliedBy(rewardTokenPriceInMarketReferenceCurrency);

  if (emissionPerSecondNormalized.eq(0)) {
    return '0';
  }

  const emissionPerYear =
    emissionPerSecondNormalized.multipliedBy(SECONDS_PER_YEAR);

  const totalSupplyNormalized = valueToBigNumber(
    normalize(totasTokenSupply, decimals),
  ).multipliedBy(priceInMarketReferenceCurrency);

  return emissionPerYear.dividedBy(totalSupplyNormalized).toFixed();
}
