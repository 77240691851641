/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "Diaudit oleh",
    "Powered by": "Dipersembahkan oleh",
    "Please, connect your wallet": "Tolong, hubungkan dompet Anda ",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      "Silakan hubungkan dompet Anda untuk melihat persediaan, pinjaman, dan posisi terbuka Anda ",
    "Connect Wallet": "hubungkan dompet",
    "You need to change to a supported chain":
      "Anda perlu mengubah ke rantai yang didukung ",
    "Please switch the chain with your wallet.":
      "Silakan ganti rantai dengan dompet Anda.",
    "Failed to switch the chain": "Gagal mengganti rantai ",
    "In local-dev mode, only localhost:8545 network can be connected":
      "Dalam mode pengembang lokal, hanya jaringan localhost:8545 yang dapat terhubung ",
    "SiO2 Finance is not yet depolyed to this network":
      "SiO2 Finance belum diterapkan ke jaringan ini ",
    "Please switch to": "Silakan beralih ke ",
    "Change Network": "Ubah Jaringan ",
    "No Data": "Tidak ada data ",
    Show: "Menunjukkan",
    Hide: "Bersembunyi",
    "Copy Success": "Salin Sukses ",
    "No account": "Tidak Ada Akun",
    Network: "Jaringan",
    "Copy Address": "Salin Alamat",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "Memutuskan Dompet",
    Menu: "Menu",
    "Global Setting": "Pengaturan global ",
    "Dark Mode": "Mode Gelap",
    "Select Language": "Pilih Bahasa",
    "Add Tokens": "Tambahkan Token",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "Kami belum meluncurkan token SIO2 secara publik, jadi token SIO2 tidak memiliki pasar\nharga sekarang. Oleh karena itu kami menghitung APR berdasarkan perkiraan harga token, $\n",
        ["SIO2_PRICE"],
        ". Untuk informasi lebih lanjut, kunjungi dokumentasi kami.",
      ],
    Borrow: "Meminjam",
    Repay: "Membalas",
    Amount: "Jumlah",
    Max: "Maksimal",
    "Transaction overview": "Ikhtisar transaksi ",
    "Borrow APY": "Meminjam APY",
    "Borrow APR": "Meminjam APR",
    Borrowed: "Dipinjam ",
    "Borrow Limit Used": "Batas Pinjam Digunakan ",
    "Health Factor": "Faktor Kesehatan ",
    Liquidity: "Likuiditas",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "Karena bunga dan nilai berfluktuasi sepanjang waktu, nilai maksimum\ndari max mungkin tidak dapat sepenuhnya melunasi semua pinjaman.",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      'Josh di sini! Minggu pertama adalah "Minggu Canary" tambang kristal kami. Kami mendorong pengguna untuk menjaga faktor kesehatan > 4 untuk bersiap menghadapi semua kondisi pasar. Baca <0>artikel</0> untuk detail selengkapnya.',
    "Josh here!": "Josh here!",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      'Josh di sini! Minggu pertama adalah "Minggu Canary" tambang kristal kami. Kami mendorong pengguna untuk menjaga faktor kesehatan> 4 untuk mempersiapkan semua kondisi pasar. Baca <0>artikel</0> untuk detail selengkapnya.',
    Collateral: "Jaminan ",
    ON: "PADA",
    OFF: "MATI",
    Collateral_Amount: "Jumlah Jaminan",
    "Borrowed Available": "Dipinjam Tersedia ",
    Disable: "Cacat ",
    Enable: "Memungkinkan ",
    Deposited: "Didepositokan ",
    Supply: "Memasok",
    Withdraw: "Menarik ",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "Untuk menerima <0>hadiah staking ASTR</0> dari <1>Algem</1>, harap berikan $nASTR Anda melalui <2>adaptor SiO2</2> dalam Algem DApp.",
    "Supply APY": "Memasok APY ",
    "Supply APR": "Memasok APR",
    "Borrow Available": "Pinjam Tersedia ",
    "This asset can't be used as collateral.":
      "Aset ini tidak dapat digunakan sebagai jaminan。",
    "Wallet Balance": "Saldo Dompet ",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "Karena bunga akan terus terakumulasi saat konfirmasi transaksi, jumlah maksimal yang dimasukkan mungkin tidak mencerminkan total yang Anda peroleh saat transaksi selesai",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      "Karena minat dan nilai berfluktuasi dari waktu ke waktu, maks\nnilai mungkin tidak dapat sepenuhnya menghapus semua simpanan",
    Dashboard: "Dasbor ",
    Market: "Pasar ",
    Stake: "Mempertaruhkan ",
    "Public Sale": "Penjualan Umum ",
    Faucet: "Keran ",
    "Transaction Preparing": "Persiapan Transaksi",
    "Waiting for transaction to be ready...": "Menunggu transaksi siap...",
    "Confirm Transaction": "Konfirmasi Transaksi",
    "Approve sending the ERC-20 asset to the pool.":
      "Setujui pengiriman aset ERC-20 ke kumpulan.",
    "Transaction Pending": "Transaksi Tertunda",
    "Waiting for the transaction to be confirmed...":
      "Menunggu transaksi dikonfirmasi...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "Menyetujui kontrak untuk meminjam aset ERC-20 dengan kredit Anda.",
    "Confirm the transaction.": "Konfirmasi transaksi.",
    "Transaction Submitted": "Transaksi Dikirim",
    "Succeeded!": "Berhasil!",
    "Your transaction confirmed!": "Transaksi Anda dikonfirmasi!",
    "Transaction Canceled": "Transaksi Dibatalkan",
    "You have canceled the transaction.": "Anda telah membatalkan transaksi.",
    Error: "Kesalahan",
    "Enter amount": "Masukkan jumlah",
    "No balance to deposit": "Tidak ada saldo untuk disetor",
    "No balance to withdraw": "Tidak ada saldo untuk ditarik",
    "Insufficient collateral": "Agunan tidak mencukupi",
    "No liquidity to withdraw": "Tidak ada likuiditas untuk ditarik",
    "Health factor too low": "Faktor kesehatan terlalu rendah",
    "Borrowing limit reached": "Batas peminjaman tercapai",
    "No liquidity to borrow": "Tidak ada likuiditas untuk dipinjam",
    "Not enough wallet balance to repay":
      "Saldo dompet tidak cukup untuk membayar",
    "No borrowed balance to repay": "Tidak ada saldo pinjaman untuk dilunasi",
    "Enter leverage": "Masukkan leverage",
    "No balance to loop": "Tidak ada keseimbangan untuk diulang",
    "Supply Balance": "Saldo Pasokan ",
    "Borrow Balance": "Pinjam Saldo ",
    Asset: "Aset",
    "Reward APR": "Hadiah APR",
    "Interest Rate": "Suku bunga ",
    "Borrow Markets": "Pinjam Pasar ",
    " Penghargaan APR": "奖励 APR ",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "Rata-rata tertimbang APY untuk semua aset yang dipasok, termasuk insentif",
    Supplied: "Disediakan ",
    "Supply Markets": "Pasar Pasokan ",
    "Net APY": "APY Bersih ",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "APY Bersih adalah efek gabungan dari semua posisi penawaran dan pinjaman pada kekayaan bersih, termasuk insentif. Adalah mungkin untuk memiliki APY bersih negatif jika APY utang lebih tinggi dari APY pasokan.",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "Faktor kesehatan Anda dan nilai pinjaman menentukan jaminan jaminan Anda. Untuk menghindari likuidasi, Anda dapat",
    "1. supply more collateral": "1. berikan lebih banyak agunan",
    "2. repay borrow positions": "2. melunasi posisi pinjaman",
    "When your health factor is below 1, your collateral will be liquidated.":
      "Ketika faktor kesehatan Anda di bawah 1, jaminan Anda akan dilikuidasi.",
    "RISK DETAILS": "DETAIL RISIKO",
    Claim: "Mengeklaim ",
    "Available SIO2 Rewards": "Imbalan SIO2 yang Tersedia",
    "Learn more": "Belajarlah lagi ",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      "Meskipun hadiah SIO2 bertambah setiap menit, nilai pada UI hanya diperbarui saat Anda bertransaksi dengan pasar pasokan dan pinjaman. Oleh karena itu, Anda mungkin menerima lebih banyak hadiah daripada yang ditampilkan saat mengklik 'Klaim'.",
    Name: "Nama",
    Action: "Aksi",
    Mint: "daun mint",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "Untuk mencegah bot spam, faucet testnet kami hanya mengizinkan satu mint per aset per hari untuk setiap pengguna.",
    "Total Supplied": "Total Disediakan ",
    "Total Borrowed": "Total Dipinjam ",
    "Supply Reward APR": "Hadiah Pasokan APR ",
    "Borrow Interest Rate": "Suku Bunga Pinjaman",
    "Borrow Reward APR": "Pinjam Hadiah APR ",
    Details: "rincian",
    Price: "Harga",
    Borrowing: "Peminjaman ",
    Yes: "Iya",
    No: "Tidak",
    "Reserve Factor": "Faktor Cadangan ",
    "Used as Collateral": "Digunakan sebagai Jaminan ",
    "Loan to Value": "Pinjaman untuk Nilai ",
    "Liquidation Threshold": "Ambang Likuidasi ",
    "Liquidation Penalty": "Hukuman Likuidasi ",
    "Market Details": "Detail Pasar ",
    "Asset Pools": "Kumpulan Aset ",
    Others: "Yang lain",
    total: "total",
    "Top3 Markets": "Pasar 3 Teratas ",
    " total ": "总共",
    "Astar Network": "Jaringan Bintang",
    Token: "Token",
    "SIO2 Token(SIO2)": "SIO2 Token(SIO2)",
    "Total Raised": "Total Dibesarkan ",
    "Sales Opened": "Penjualan Dibuka ",
    TBD: "tertunda",
    "Sales Closed": "Penjualan Ditutup ",
    "This page is developed for public sale on mainnet, but not testnet.":
      "Halaman ini dikembangkan untuk penjualan publik di mainnet, tetapi tidak untuk testnet ",
    "SIO2 PUBLIC SALE": "PENJUALAN UMUM SIO2 ",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      'Aturan Rilis Token: vesting 2 tahun dengan tebing 6 bulan setelah\nmeluncurkan" ',
    Purchase: "Pembelian ",
    "Stake SIO2": "Mempertaruhkan SIO2",
    Staking: "mempertaruhkan ",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "Pemegang SIO2 dapat mempertaruhkan SIO2 mereka di Modul Keamanan untuk menambahkan lebih banyak\nkeamanan pada protokol dan dapatkan Insentif Keamanan. Dalam hal\nacara kekurangan, hingga 30% dari taruhan Anda dapat dipotong untuk menutupi\ndefisit, memberikan lapisan perlindungan tambahan untuk protokol.",
    "Total emission per day": "Total emisi per hari",
    "Claim Rewards": "Klaim Hadiah ",
    "Funds in the Safety Module": "Dana dalam Modul Keamanan ",
    "1 year": "1 tahun",
    "2 years": "2 tahun",
    "3 years": "3 tahun",
    "The amount you're trying to stake exceeds your balance by {0} SIO2": [
      "Jumlah yang Anda coba pertaruhkan melebihi saldo Anda sebesar ",
      ["0"],
      " SIO2",
    ],
    "Locked Staking": "Taruhan Terkunci ",
    Balance: "Saldo",
    "Duration (years)": "Durasi (Tahun)",
    "Pool Share": "Berbagi Kolam Renang ",
    Confirm: "Konfirmasi",
    ID: "ID",
    "Staked Amount": "Jumlah yang Dipertaruhkan ",
    "Weighted Staked Amount": "Jumlah Taruhan Tertimbang ",
    "Date Time": "Tanggal Waktu",
    "Locked Until": "Terkunci Sampai ",
    Redeem: "Menukarkan",
    "Locked Stakes": "Taruhan Terkunci ",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      "Anda belum memiliki taruhan yang terkunci. Mulailah dengan mengintai token SIO2 Anda.",
    Summary: "Summary ",
    "Staking APR": "Mempertaruhkan APR ",
    "Unweighted Stake": "Taruhan Tidak Tertimbang",
    "Weighted Stake": "Taruhan Tertimbang ",
  },
};
