/* Autogenerated file. Do not edit manually. */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import type { IPriceAggregatorAdapter } from './IPriceAggregatorAdapter';

export class IPriceAggregatorAdapterFactory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider,
  ): IPriceAggregatorAdapter {
    return new Contract(
      address,
      _abi,
      signerOrProvider,
    ) as IPriceAggregatorAdapter;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
    ],
    name: 'currentPrice',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
