import { useWallet } from "@/hook/useWallet";
import { shortenAddress } from "@/utils/address";
import { DownOutlined } from "@ant-design/icons";
import { useResponsive } from "ahooks";
import { Dropdown, message } from "antd";
import "./index.less";
import { t } from "@lingui/macro";
import { ChainId, getNetworkConfig } from "@/libs/config";
const ConnectDropDown: React.FC = () => {
  const { account, chainId, disconnect, activeWalletType } = useWallet();
  const responsive = useResponsive();
  const { name } = getNetworkConfig(chainId as ChainId) || {};
  const copy = async () => {
    if (account) {
      await navigator.clipboard.writeText(account);
      message.success(t`Copy Success`);
    } else {
      message.error(t`No account`);
    }
  };

  const dropDownContent = () => {
    return (
      <div className="connect-drop-down">
        <div className="account-wrap">
          <img src={require("@img/account.png")} alt="" />
          <span>{shortenAddress(account || "")}</span>
        </div>
        <div className="network-wrap">
          <span className="label">{t`Network`}</span>
          {name ? <span>{name + " " + chainId}</span> : <span>{chainId}</span>}
        </div>
        <div className="handle-wrap">
          <div className="handle-item" onClick={copy}>
            <span>{t`Copy Address`}</span>
          </div>
          {activeWalletType === "Safe" ? (
            <div className="handle-item">
              <span>{t`Astar Safe Wallet`}</span>
            </div>
          ) : (
            <div className="handle-item" onClick={disconnect}>
              <span>{t`Disconnect Wallet`}</span>
            </div>
          )}
        </div>
      </div>
    );
  };
  return account ? (
    <Dropdown
      overlay={dropDownContent}
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
    >
      <div className="select-wrap btn-main">
        <div className="select-left">
          {responsive.lg ? <img src={require("@img/account.png")} alt="" /> : null}
          <span>{shortenAddress(account)}</span>
        </div>
        <div className="select-right">
          <DownOutlined></DownOutlined>
        </div>
      </div>
    </Dropdown>
  ) : null;
};
export default ConnectDropDown;
