import { useModalContext } from "@/hook/app/useModalContext";
import { useMarketData } from "@/hook/useMarketData";
import { useWalletBalance } from "@/hook/useWalletBalance";
import { Button, Input, Segmented } from "antd";
import { useLendingPool } from "@/hook/contracts/useLendingPool";
import { BN_ZERO, formatAmt, formatAmtShort, formatPct, formattedToBigNumber, formatUSD } from "src/utils/number";
import { useEffect, useState } from "react";
import "../index.less";
import { AssetMarketData } from "@/types/models";
import { estimateWithdrawal, estimateDeposit } from "@/utils/estimationHelper";
import ListItem from "./ListItem";
import { useTracking } from "@/hook/useTracking";
import { BigNumber, valueToBigNumber } from "submodule/packages/math-utils/dist/cjs";
import { useWallet } from "@/hook/useWallet";
import { ASSETS_DICT } from "@/constants/assets";

import { CloseOutlined } from "@ant-design/icons";
import { useUserData } from "@/hook/useUserData";
import { Trans, t } from "@lingui/macro";
import Link from "antd/lib/typography/Link";

const SupplyModal: React.FC = () => {
  const { args, closeModal, messageVisible, contentVisible } = useModalContext();

  const { data: marketData } = useMarketData();
  const { data: balance } = useWalletBalance();
  const { data: user } = useUserData();
  const [activeTab, setActiveTab] = useState<string>(args.type);
  const [depositAmount, setDepositAmount] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");

  const { account, signer } = useWallet();
  const { deposit, withdraw } = useLendingPool(account, signer);

  const { withTracking } = useTracking();

  useEffect(() => {
    if (!messageVisible) {
      reset();
    }
  }, [messageVisible]);
  useEffect(() => {
    if (!contentVisible) {
      reset();
    }
  }, [contentVisible]);
  useEffect(() => {
    reset();
  }, [args]);

  const reset = () => {
    setActiveTab(args.type);
    setDepositAmount("");
    setWithdrawalAmount("");
  };

  if (!args.asset || !user) {
    return null;
  }
  const { marketReferenceCurrencyPriceInUSD = BN_ZERO, marketReferenceCurrencyDecimals = 0 } = marketData || {};

  const { asset }: { asset: AssetMarketData } = args;

  const userSummary = user.summary;
  const userAssetBalance = {
    ...user?.balanceByAsset[asset?.symbol],
    inWallet: balance[asset.symbol],
  };
  const { symbol, displaySymbol, depositAPY, depositIncentiveAPR, isFrozen } = asset;
  const { availableBorrowsInUSD, borrowLimitUsed, healthFactor } = userSummary;
  const { inWallet, deposited } = userAssetBalance;

  const depositAmountBn = formattedToBigNumber(depositAmount);
  const withdrawalAmountBn = formattedToBigNumber(withdrawalAmount);
  const estimation =
    activeTab === "deposit"
      ? estimateDeposit({
          amount: depositAmountBn,
          userAssetBalance,
          userSummary,
          asset,
          marketReferenceCurrencyPriceInUSD,
          marketReferenceCurrencyDecimals,
        })
      : estimateWithdrawal({
          amount: withdrawalAmountBn,
          userAssetBalance,
          userSummary,
          asset,
          marketReferenceCurrencyPriceInUSD,
          marketReferenceCurrencyDecimals,
        });

  const depositWithTracking = withTracking("deposit", deposit);
  const withdrawWithTracking = withTracking("withdraw", withdraw);

  const depositHandle = (amount: BigNumber) =>
    depositWithTracking({
      amount,
      underlyingAsset: asset.underlyingAsset,
    });
  const withdrawHandle = (amount: BigNumber, all: boolean) =>
    withdrawWithTracking({
      amount,
      underlyingAsset: asset.underlyingAsset,
      sTokenAddress: asset.sTokenAddress,
      all,
    });

  const isShowTip = asset.name === "nASTR" && activeTab === "deposit";
  return (
    <div className="modal-body modal-deposit">
      <div className="close-wrap" onClick={closeModal}>
        <CloseOutlined />
      </div>
      <div className="title-wrap">
        <img src={asset.icon} alt="" />
        <h3>{asset.displaySymbol || asset.name}</h3>
      </div>
      <Segmented
        options={[
          { label: t`Supply`, value: "deposit", disabled: isFrozen || symbol === "BUSD" },
          { label: t`Withdraw`, value: "withdraw" },
        ]}
        block
        value={activeTab}
        onChange={(value) => {
          setActiveTab(value as string);
        }}
      />
      {isShowTip ? (
        <div className="astr-tip-wrap">
          <Trans>
            To receive <span>ASTR staking rewards</span> from <span>Algem</span>, please supply your $nASTR via the{" "}
            <Link href="https://partners.algem.io/lending/sio2" target="_blank">
              SiO2 adapter
            </Link>{" "}
            within the Algem DApp.
          </Trans>
        </div>
      ) : null}

      <div className="input-wrap">
        {/* <Tooltip title="amount" placement="right">
          <span className="label">
            Amount <QuestionCircleOutlined />
          </span>
        </Tooltip> */}
        <span className="label">{t`Amount`}</span>
        {activeTab === "deposit" ? (
          <Input
            allowClear
            placeholder="0.00"
            value={depositAmount}
            onChange={(e) => {
              setDepositAmount(e.target.value);
            }}
            suffix={<div onClick={() => setDepositAmount(formatAmt(estimation.maxAmount))}>{t`Max`}</div>}
          ></Input>
        ) : (
          <Input
            allowClear
            placeholder="0.00"
            value={withdrawalAmount}
            onChange={(e) => setWithdrawalAmount(e.target.value)}
            suffix={
              <div
                onClick={() =>
                  setWithdrawalAmount(
                    formatAmt(estimation.maxAmount, {
                      decimalPlaces: asset.decimals,
                      roundingMode: BigNumber.ROUND_FLOOR,
                    })
                  )
                }
              >
                {t`Max`}
              </div>
            }
          ></Input>
        )}
      </div>
      <div className="overview-wrap">
        <p className="label">{t`Transaction overview`}</p>
        <div className="list-wrap">
          <ListItem label={t`Supply APY`} value={formatPct(depositAPY)} img={{ src: asset.icon, alt: asset.name }} />
          <ListItem
            label={t`Supply APR`}
            value={formatPct(depositIncentiveAPR)}
            img={{ src: ASSETS_DICT.SiO2.icon, alt: ASSETS_DICT.SiO2.name }}
          />
          <ListItem
            label={t`Borrow Available`}
            value={availableBorrowsInUSD ? formatUSD(availableBorrowsInUSD) : "-"}
            after={estimation.availableBorrowsInUSD}
            formatAfter={formatUSD}
          />
          <ListItem
            label={t`Borrow Limit Used`}
            value={borrowLimitUsed ? formatPct(borrowLimitUsed) : "-"}
            after={estimation.borrowLimitUsed}
            formatAfter={formatPct}
          />
          <ListItem
            label={t`Health Factor`}
            value={
              healthFactor.isPositive()
                ? formatAmtShort(healthFactor).includes("T")
                  ? "-"
                  : formatAmtShort(healthFactor)
                : "-"
            }
            after={
              !estimation.healthFactor
                ? undefined
                : estimation.healthFactor.isPositive()
                ? estimation.healthFactor
                : "-"
            }
            formatAfter={formatAmtShort}
          />
          {!asset.usageAsCollateralEnabled && (
            <div className="list-item-tip">
              <div />
              <p>
                <p>{t`This asset can't be used as collateral.`}</p>
                {/* {"For more information, please "}
                <Link key="0" to={DOCS_RISK}>
                  see our docs.
                </Link> */}
              </p>
            </div>
          )}
        </div>
      </div>

      {activeTab === "deposit" ? (
        <div className="bottom-btn-wrap">
          <Button
            block
            type="primary"
            onClick={() => {
              closeModal();

              return depositHandle(valueToBigNumber(depositAmountBn!));
            }}
            disabled={!!estimation.unavailableReason}
          >
            {estimation.unavailableReason || t`Supply`}
          </Button>
          <div className="bottom-wrap">
            <span className="label">{t`Wallet Balance`}</span>
            <span className="value">
              {formatAmt(inWallet, {
                symbol: displaySymbol || symbol,
                shorteningThreshold: 20,
              })}
            </span>
          </div>
        </div>
      ) : (
        <div className="bottom-btn-wrap">
          <Button
            block
            type="primary"
            onClick={() => {
              closeModal();

              return withdrawHandle(valueToBigNumber(withdrawalAmountBn!), false);
            }}
            disabled={!!estimation.unavailableReason}
          >
            {estimation.unavailableReason || t`Withdraw`}
          </Button>
          <div className="tip-wrap">
            {t`Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete`}
          </div>
          <div className="bottom-wrap">
            <span className="label">{t`Supply`}</span>
            <span className="value">
              {formatAmt(deposited, {
                symbol: displaySymbol || symbol,
                shorteningThreshold: 20,
              })}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default SupplyModal;
