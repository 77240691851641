/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "审核",
    "Powered by": "供应",
    "Please, connect your wallet": "请连接钱包",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      "请连接您的钱包以查看您的存款、借款，和现有仓位。",
    "Connect Wallet": "连接钱包",
    "You need to change to a supported chain": "您需要更改为支持的链",
    "Please switch the chain with your wallet.": "请使用钱包切换链",
    "Failed to switch the chain": "切换链失败",
    "In local-dev mode, only localhost:8545 network can be connected":
      "在 local-dev 模式下，只能连接 localhost:8545 网络",
    "SiO2 Finance is not yet depolyed to this network":
      "SiO2 Finance 尚未部署到此网络",
    "Please switch to": "请切换到",
    "Change Network": "改变网络",
    "No Data": "暂无数据",
    Show: "展示",
    Hide: "隐藏",
    show: "展示",
    hide: "隐藏",
    "Copy Success": "复制成功",
    "No account": "没有账号",
    Network: "网络",
    "Copy Address": "复制地址",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "断开钱包",
    Menu: "菜单",
    "Global Setting": "全局设置",
    "Dark Mode": "夜间模式",
    "Select Language": "选择语言",
    "Add Tokens": "添加代币",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "我们还没有公开发行SIO2代币，所以SIO2代币目前没有市场价。\n因此我们根据估计的代币价格计算APR，$\n",
        ["SIO2_PRICE"],
        "。有关更多信息，请访问我们的文档。",
      ],
    Borrow: "借",
    Repay: "偿还",
    Amount: "金额",
    Max: "最大",
    "Transaction overview": "交易概览",
    "Borrow APY": "借 APY",
    "Borrow APR": "借 APR",
    Borrowed: "借入",
    "Borrow Limit Used": "已使用的借款限额",
    "Health Factor": "健康因子",
    Liquidity: "流动性",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "由于利息和价格一直在波动 \n最大价值可能无法完全清除所有存款",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      "乔希在这里！ 第一周是我们水晶矿的“金丝雀周”。 我们鼓励用户保持健康系数 > 4，以便为所有市场条件做好准备。 阅读<0>文章</0>了解更多详情。",
    "Josh here!": "Josh here!",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      "乔希在这里！ 第一周是我们水晶矿的“金丝雀周”。 我们鼓励用户保持健康系数>4，为所有市场状况做好准备。 阅读<0>文章</0>了解更多详情。",
    "{0}": [["0"]],
    Collateral: "抵押品",
    ON: "开",
    OFF: "关",
    Collateral_Amount: "抵押品金额",
    "Borrowed Available": "可借额度",
    Disable: "禁用",
    Enable: "启用",
    Deposited: "存款",
    Supply: "供应",
    Withdraw: "提现",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "要从 <1>Algem</1> 获得 <0>ASTR 质押奖励</0>，请通过 Algem DApp 中的<2>SiO2 适配器</2>提供您的 $nASTR。",
    "Supply APY": "供应 APY",
    "Supply APR": "供应 APR",
    "Borrow Available": "可借额度",
    "This asset can't be used as collateral.": "该资产不能用作抵押。",
    "Wallet Balance": "钱包余额",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "由于确认交易时利息会继续累积，因此输入的最大数字可能无法反映交易完成时您赚取的总金额",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      "由于利息和价值不时波动，最大\n价值可能无法完全清除所有存款",
    Dashboard: "仪表盘",
    Market: "市场",
    Stake: "质押",
    "Public Sale": "公售",
    Faucet: "水龙头",
    "Transaction Preparing": "交易准备中",
    "Waiting for transaction to be ready...": "等待交易完成...",
    "Confirm Transaction": "确认交易",
    "Approve sending the ERC-20 asset to the pool.":
      "批准将 ERC-20 资产发送到池中。",
    "Transaction Pending": "交易待定",
    "Waiting for the transaction to be confirmed...": "等待交易确认...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "批准合约以您的信用借出 ERC-20 资产。",
    "Confirm the transaction.": "确认交易。",
    "Transaction Submitted": "交易已提交",
    "Succeeded!": "成功了！",
    "Your transaction confirmed!": "您的交易已确认！",
    "Transaction Canceled": "交易取消",
    "You have canceled the transaction.": "您已取消交易。",
    Error: "错误",
    "Enter amount": "输入金额",
    "No balance to deposit": "没有余额可以存入",
    "No balance to withdraw": "没有余额可以提取",
    "Insufficient collateral": "抵押品不足",
    "No liquidity to withdraw": "没有可提取的流动性",
    "Health factor too low": "健康因子太低",
    "Borrowing limit reached": "达到借款限额",
    "No liquidity to borrow": "没有流动性可借",
    "Not enough wallet balance to repay": "没有足够的钱包余额来偿还",
    "No borrowed balance to repay": "没有借来的余额要偿还",
    "Enter leverage": "输入杠杆",
    "No balance to loop": "没有余额循环",
    "Supply Balance": "供应总额",
    "Borrow Balance": "借款总额",
    Asset: "资产",
    "Reward APR": "奖励 APR",
    "Interest Rate": "利率",
    "Borrow Markets": "借贷市场",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "所有提供资产（包括激励措施）的 APY 加权平均值",
    Supplied: "供应",
    "Supply Markets": "供应市场",
    "Net APY": "净 APY",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "净 APY 是所有供应和借款头寸对净值的综合影响，包括激励措施。 如果债务 APY 高于供应 APY，则可能有负的净 APY。",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "您的健康因子和贷款价值决定了您的抵押品的保证。 为避免清算，您可以",
    "1. supply more collateral": "1. 提供更多的抵押品",
    "2. repay borrow positions": "2. 偿还借入仓位",
    "When your health factor is below 1, your collateral will be liquidated.":
      "当您的健康因子低于1时，您的抵押品将被清算。",
    "RISK DETAILS": "风险详情",
    Claim: "领取",
    "Available SIO2 Rewards": "可用的 SIO2 奖励",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      "尽管 SIO2 奖励每分钟都会累积，但 UI 上的值仅在您与供应和借贷市场进行交易时才会更新。 因此，您可能会收到比点击“领取”时显示的更多的奖励",
    "Learn more": "了解更多",
    Name: "名称",
    Action: "操作",
    Mint: "铸币",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "为防止垃圾邮件机器人，我们的测试网水龙头每天只允许每个用户在单个资产上提取一次免费的测试用代币。",
    "Total Supplied": "供应总量",
    "Total Borrowed": "总借款额",
    "Supply Reward APR": "供应奖励 APR",
    "Borrow Interest Rate": "借款利率",
    "Borrow Reward APR": "借入奖励 APR",
    Details: "详情",
    Price: "价格",
    Borrowing: "借入",
    Yes: "是",
    No: "否",
    "Reserve Factor": "储备金率",
    "Used as Collateral": "用作抵押品",
    "Loan to Value": "贷款价值",
    "Liquidation Threshold": "清算阈值",
    "Liquidation Penalty": "清算罚款",
    "Market Details": "市场详情",
    "Asset Pools": "资产池",
    Detail: "详情",
    Others: "其他",
    total: "总共",
    "Top3 Markets": "前3大市场",
    "Astar Network": "Astar 网络",
    Token: "代币",
    "SIO2 Token(SIO2)": "SIO2 代币(SIO2)",
    "Total Raised": "筹款总额",
    "Sales Opened": "销售开启",
    TBD: "待定",
    "Sales Closed": "销售结束",
    "This page is developed for public sale on mainnet, but not testnet.":
      "此页面是为在主网上公开发售而开发的，不适用于测试网。",
    "SIO2 PUBLIC SALE": "SIO2 公开发售",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      "代币发行规则：2年vesting  + 6个月 cliff",
    Purchase: "购买",
    "$ {0}": ["$ ", ["0"]],
    "Stake SIO2": "质押 SIO2",
    Staking: "质押",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "SIO2 持有者可以将他们的 SIO2 质押在安全模块中，从而为协议增加更多安全性并获得安全奖励。如果出现了亏空事件，安全模块可以最多出售 30% 的质押份额以弥补赤字，以此为协议提供额外的保护。",
    "Total emission per day": "每日总排放量",
    "Claim Rewards": "领取奖励",
    "Funds in the Safety Module": "安全模块中的资金",
    "1 year": "1 年",
    "2 years": "2 年",
    "3 years": "3 年",
    "The amount you're trying to stake exceeds your balance by {0} SIO2": [
      "您尝试质押的金额超出您的余额 ",
      ["0"],
      " SIO2",
    ],
    "Locked Staking": "锁定的质押",
    Balance: "余额",
    "Duration (years)": "日期（年）",
    "Pool Share": "池中份额",
    Confirm: "确认",
    ID: "ID",
    "Staked Amount": "质押数量",
    "Weighted Staked Amount": "加权质押数量",
    "Date Time": "约定时间",
    "Locked Until": "解锁日期",
    Redeem: "赎回",
    "Locked Stakes": "锁定的质押",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      "您还没有任何锁定的质押。 请先质押您的 SIO2 代币。",
    Summary: "汇总",
    "Staking APR": "质押 APR",
    "Unweighted Stake": "非加权质押",
    "Weighted Stake": "加权质押",
  },
};
