import { Popover } from "antd";
import "./index.less";
import type { TableListProps, columnProps } from "./index";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { StateMessage } from "@/component";
import { useEffect, useState } from "react";
import NotificationBar from "../NotificationBar";
import { useUserData } from "@/hook/useUserData";
import { AssetMarketData } from "@/types/models";
import { useLocation } from "react-router-dom";

const getColumnWidth = (columns: columnProps[]): string => {
  let len = columns.length;
  const haveWidth = columns.reduce((preV, item) => {
    if (item.width) {
      len -= 1;
      return preV + parseInt(item.width);
    } else {
      return preV;
    }
  }, 0);
  const defaultArr = (100 - haveWidth) / len + "%";
  const widthArr = columns.map((item) => item.width || defaultArr);
  const widthStr = widthArr.join(" ");
  return widthStr;
};
const DesktopTable: React.FC<TableListProps> = ({
  columns,
  dataSource,
  title,
  rowClick,
  stateMessage,
  disabledKey,
}) => {
  /*
   * 数组，第一位表示排序的列的索引，第二列表示排序的规则
   * 0 无排序，1 大到小 ， 2 小到大
   */

  const { pathname } = useLocation();
  const [sortType, setSortType] = useState<number[]>([0, 0]);
  const [data, setData] = useState<any[]>(dataSource);
  const { data: user } = useUserData();

  useEffect(() => {
    switch (sortType[1]) {
      case 0:
        setData(dataSource);
        break;
      case 1:
        sortTable(1);
        break;
      case 2:
        sortTable(2);
        break;
      default:
        setData(dataSource);
        break;
    }
  }, [dataSource, sortType]);

  const handleSort = (column: columnProps, index: number) => {
    if (!column.sort) {
      return;
    }

    let sortType_ = sortType[1] === 2 ? 0 : sortType[1] + 1;

    if (index !== sortType[0]) {
      sortType_ = 1;
    }

    setSortType([index, sortType_]);
  };
  const sortTable = (type: number) => {
    let column = columns[sortType[0]];
    const dealData = (item: any) => {
      let value = column.render ? column.render("", item, 0) : item[column.dataIndex || ""];
      value = value?.replace(/%|\$/g, "");
      if (isNaN(Number(value))) {
        value = item[column.dataIndex || ""]?.toString();
      }

      return value;
    };

    let data_ = [...dataSource].sort((a: any, b: any) => {
      let valueA = dealData(a);
      let valueB = dealData(b);
      console.log(valueA, valueB);
      return type === 1 ? valueB - valueA : valueA - valueB;
    });
    console.log(data_, "data_");
    setData(data_);
  };

  return (
    <div className="desktop-table">
      <div className="table-header" style={{ gridTemplateColumns: getColumnWidth(columns) }}>
        {columns.map((column, index) => {
          return (
            <div
              className={`header-item ${column.sort ? "sort" : ""}`}
              key={column.title}
              onClick={() => handleSort(column, index)}
            >
              <div>
                <p className="title">{column.title}</p>
                {column.label ? <p className="label">{column.label}</p> : null}
              </div>

              {column.tooltip ? (
                <Popover content={column.tooltip}>
                  <InfoCircleOutlined />
                </Popover>
              ) : null}
              {column.sort ? (
                <div className="sort-wrap">
                  <CaretUpOutlined className={sortType[0] === index && sortType[1] === 2 ? "active" : ""} />
                  <CaretDownOutlined className={sortType[0] === index && sortType[1] === 1 ? "active" : ""} />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      {data.length ? (
        <div className="table-body">
          {data.map((item, index) => {
            const depositData = user?.balanceByAsset[(item as AssetMarketData).symbol]?.[disabledKey || "deposited"];
            const isDisabled = item.symbol === "BUSD" && (depositData?.toString() === "0" || pathname === "/market");
            return (
              <>
                <div
                  className={`list-row ${isDisabled ? "disabled" : ""}`}
                  style={{ gridTemplateColumns: getColumnWidth(columns) }}
                  onClick={() => rowClick?.(item)}
                >
                  {columns.map((column) => {
                    const text = item[column.dataIndex || ""];
                    const renderTextDom = column.render?.(text, item, index);

                    return (
                      <div
                        key={column.title}
                        className={`list-column ${!text && !renderTextDom ? "list-loading" : ""}`}
                      >
                        {renderTextDom ?? text}
                      </div>
                    );
                  })}
                </div>
                {item.symbol === "BUSD" ? (
                  <div className="notification-bar-wrap">
                    <NotificationBar noBusdIcon={true} />
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      ) : (
        <StateMessage {...stateMessage} />
      )}
    </div>
  );
};
export default DesktopTable;
