import { useModalContext } from "@/hook/app/useModalContext";
import { useWallet } from "@/hook/useWallet";
import { DEFAULT_CHAIN_ID } from "@/utils/env";
import { Button } from "antd";
import { useState } from "react";
import "./index.less";
import { t } from "@lingui/macro";
const MissConnectWallet: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const [loading, setLoading] = useState(false);

  const { openMessage, setMessageVisible } = useModalContext();
  const { switchChain } = useWallet();

  const handleUnSupportChain = () => {
    setLoading(true);
    openMessage({
      type: "warning",
      title: t`You need to change to a supported chain`,
      message: t`Please switch the chain with your wallet.`,
    });
    if (switchChain) {
      switchChain(DEFAULT_CHAIN_ID).then(({ error }) => {
        setLoading(false);
        if (!error) {
          setMessageVisible(false);

          return;
        }
        openMessage({
          title: t`Failed to switch the chain`,
          message: error,
          type: "error",
        });
      });
    }
  };
  return (
    <div className="miss-connect-wallet" style={style}>
      <div className="img-wrap">
        <img src={require("@img/no-wallet.png")} alt="" />
      </div>
      <h3>
        {process.env.REACT_APP_ENV === "dev"
          ? t`In local-dev mode, only localhost:8545 network can be connected`
          : t`SiO2 Finance is not yet depolyed to this network`}
      </h3>
      <p>
        {t`Please switch to`}{" "}
        {process.env.REACT_APP_ENV === "dev"
          ? "Localhost:8545"
          : process.env.REACT_APP_ENV === "shibuya"
          ? "Shibuya"
          : "Astar"}{" "}
      </p>
      <Button
        loading={loading}
        type="primary"
        className="btn-wrap"
        onClick={handleUnSupportChain}
      >
        {t`Change Network`}
      </Button>
    </div>
  );
};
export default MissConnectWallet;
