import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';
import { WalletConnector } from '../types';

const safeAppConnector = new SafeAppConnector({})

export const safeMultisigConnector: WalletConnector<SafeAppConnector> = {
    type: "Safe",
    connector : safeAppConnector,
};
  
