/* Autogenerated file. Do not edit manually. */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import type { IFaucet } from './IFaucet';

export class IFaucet__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider,
  ): IFaucet {
    return new Contract(address, _abi, signerOrProvider) as IFaucet;
  }
}

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: 'string',
            name: 'asset',
            type: 'string',
          },
          {
            internalType: 'address',
            name: 'addr',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'frozenDuration',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'maxToClaimed',
            type: 'uint256',
          },
        ],
        internalType: 'struct IFaucet.ClaimAssetsInfo[]',
        name: '_assets',
        type: 'tuple[]',
      },
    ],
    name: 'addAssets',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: '_asset',
        type: 'string',
      },
    ],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
