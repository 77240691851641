import { calculateIncentiveAPR } from './calculate-incentive-apr';
import { ReserveIncentiveWithFeedsResponse } from './types';

export interface CalculateReserveIncentivesRequest {
  reserveIncentiveData: ReserveIncentiveWithFeedsResponse;
  sRewardTokenPriceInMarketReferenceCurrency: string; // Can be priced in ETH or USD depending on market
  vdRewardTokenPriceInMarketReferenceCurrency: string;
  sdRewardTokenPriceInMarketReferenceCurrency: string;
  totalLiquidity: string;
  totalVariableDebt: string;
  totalStableDebt: string;
  decimals: number;
  priceInMarketReferenceCurrency: string; // Can be priced in ETH or USD depending on market
}

interface ReserveIncentiveResponse {
  incentiveAPR: string;
  rewardTokenAddress: string;
}
export interface CalculateReserveIncentivesResponse {
  underlyingAsset: string;
  sIncentivesData: ReserveIncentiveResponse;
  vdIncentivesData: ReserveIncentiveResponse;
  sdIncentivesData: ReserveIncentiveResponse;
}

// Calculate deposit, variableBorrow, and stableBorrow incentives APR for a reserve asset
export function calculateReserveIncentives({
  reserveIncentiveData,
  sRewardTokenPriceInMarketReferenceCurrency,
  vdRewardTokenPriceInMarketReferenceCurrency,
  sdRewardTokenPriceInMarketReferenceCurrency,
  totalLiquidity,
  totalVariableDebt,
  totalStableDebt,
  decimals,
  priceInMarketReferenceCurrency,
}: CalculateReserveIncentivesRequest): CalculateReserveIncentivesResponse {
  const lIncentivesAPR = calculateIncentiveAPR({
    incentiveIntialSupply:
      reserveIncentiveData.sIncentiveData.incentiveIntialSupply,
    incentiveInflactionStart:
      reserveIncentiveData.sIncentiveData.incentiveInflactionStart,
    incentiveDecayRatio:
      reserveIncentiveData.sIncentiveData.incentiveDecayRatio,
    rewardTokenPriceInMarketReferenceCurrency:
      sRewardTokenPriceInMarketReferenceCurrency,
    priceInMarketReferenceCurrency,
    totasTokenSupply: totalLiquidity,
    decimals,
    rewardTokenDecimals:
      reserveIncentiveData.sIncentiveData.rewardTokenDecimals,
  });

  const vdIncentivesAPR = calculateIncentiveAPR({
    incentiveIntialSupply:
      reserveIncentiveData.vdIncentiveData.incentiveIntialSupply,
    incentiveInflactionStart:
      reserveIncentiveData.vdIncentiveData.incentiveInflactionStart,
    incentiveDecayRatio:
      reserveIncentiveData.vdIncentiveData.incentiveDecayRatio,
    rewardTokenPriceInMarketReferenceCurrency:
      vdRewardTokenPriceInMarketReferenceCurrency,
    priceInMarketReferenceCurrency,
    totasTokenSupply: totalVariableDebt,
    decimals,
    rewardTokenDecimals:
      reserveIncentiveData.sIncentiveData.rewardTokenDecimals,
  });

  const sdIncentivesAPR = calculateIncentiveAPR({
    incentiveIntialSupply:
      reserveIncentiveData.sdIncentiveData.incentiveIntialSupply,
    incentiveInflactionStart:
      reserveIncentiveData.sdIncentiveData.incentiveInflactionStart,
    incentiveDecayRatio:
      reserveIncentiveData.sdIncentiveData.incentiveDecayRatio,
    rewardTokenPriceInMarketReferenceCurrency:
      sdRewardTokenPriceInMarketReferenceCurrency,
    priceInMarketReferenceCurrency,
    totasTokenSupply: totalStableDebt,
    decimals,
    rewardTokenDecimals:
      reserveIncentiveData.sIncentiveData.rewardTokenDecimals,
  });

  return {
    underlyingAsset: reserveIncentiveData.underlyingAsset,
    sIncentivesData: {
      incentiveAPR: lIncentivesAPR,
      rewardTokenAddress:
        reserveIncentiveData.sIncentiveData.rewardTokenAddress,
    },
    vdIncentivesData: {
      incentiveAPR: vdIncentivesAPR,
      rewardTokenAddress:
        reserveIncentiveData.vdIncentiveData.rewardTokenAddress,
    },
    sdIncentivesData: {
      incentiveAPR: sdIncentivesAPR,
      rewardTokenAddress:
        reserveIncentiveData.sdIncentiveData.rewardTokenAddress,
    },
  };
}
