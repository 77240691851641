import {
  StakingService,
  Stake,
} from "submodule/packages/contract-helpers/dist/cjs";
import { getStakeConfig } from "../config";
import { StaticRPCProvider } from "../pool-data-provider";

export const stakenContract = ({ chainId, provider }: StaticRPCProvider) =>
  new StakingService(provider, {
    TOKEN_STAKING_ADDRESS:
      getStakeConfig(chainId).tokens[Stake.sio2].TOKEN_STAKING,
    STAKING_HELPER_ADDRESS:
      getStakeConfig(chainId).tokens[Stake.sio2].STAKING_HELPER,
  });
