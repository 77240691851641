import { useModalContext } from "@/hook/app/useModalContext";
import { useMarketData } from "@/hook/useMarketData";
import { useWalletBalance } from "@/hook/useWalletBalance";
import { Button } from "antd";
import { useLendingPool } from "@/hook/contracts/useLendingPool";
import { ReactComponent as ImgArrowRight } from "@img/icon_arrow-right.svg";
import {
  BN_ZERO,
  formatAmt,
  formatAmtShort,
  formatPct,
  formatUSD,
} from "src/utils/number";
import "../index.less";
import { AssetMarketData } from "@/types/models";
import { estimateUsageAsCollateral } from "@/utils/estimationHelper";
import ListItem from "./ListItem";

import { useWallet } from "@/hook/useWallet";

import { CloseOutlined } from "@ant-design/icons";
import { useUserData } from "@/hook/useUserData";
import { convertToUSD } from "@/utils/calculator";
import { t } from "@lingui/macro";

const BorrowModal: React.FC = () => {
  const { args, closeModal } = useModalContext();

  const { data: marketData } = useMarketData();
  const { data: balance } = useWalletBalance();
  const { data: user } = useUserData();

  const { account, signer } = useWallet();
  const { setUsageAsCollateral } = useLendingPool(account, signer);

  if (!args.asset || !user) {
    return null;
  }
  const {
    marketReferenceCurrencyPriceInUSD = BN_ZERO,
    marketReferenceCurrencyDecimals = 0,
  } = marketData || {};

  const { asset }: { asset: AssetMarketData } = args;

  const userSummary = user.summary;
  const userAssetBalance = {
    ...user?.balanceByAsset[asset?.symbol],
    inWallet: balance[asset.symbol],
  };
  const { priceInMarketReferenceCurrency, symbol, displaySymbol } = asset;
  const {
    totalCollateralInMarketReferenceCurrency,
    availableBorrowsInUSD,
    borrowLimitUsed,
    healthFactor,
  } = userSummary;
  const { deposited, usageAsCollateralEnabled } = userAssetBalance;

  const estimation = estimateUsageAsCollateral({
    usageAsCollateralEnabled: !usageAsCollateralEnabled,
    userAssetBalance,
    userSummary,
    asset,
    marketReferenceCurrencyPriceInUSD,
    marketReferenceCurrencyDecimals,
  });

  const totalCollateralInUSD =
    totalCollateralInMarketReferenceCurrency.multipliedBy(
      marketReferenceCurrencyPriceInUSD
    );
  const assetCollateralInUSD = convertToUSD(
    priceInMarketReferenceCurrency,
    marketReferenceCurrencyPriceInUSD,
    deposited
  );

  return (
    <div className="modal-body modal-collateral">
      <div className="close-wrap" onClick={closeModal}>
        <CloseOutlined />
      </div>
      <div className="title-wrap">
        <img src={asset.icon} alt="" />
        <h3>
          {asset.displaySymbol || asset.name} {t`Collateral`}
        </h3>
      </div>

      <div className="change-des">
        <span>{usageAsCollateralEnabled ? t`ON` : t`OFF`}</span>
        <div className="arrow-right-svg-wrap">
          <ImgArrowRight />
          <ImgArrowRight />
          <ImgArrowRight />
        </div>
        <span>{usageAsCollateralEnabled ? t`OFF` : t`ON`}</span>
      </div>

      <div className="overview-wrap">
        <p className="label">{t`Transaction overview`}</p>
        <div className="list-wrap">
          <ListItem
            label={t`Collateral_Amount`}
            value={totalCollateralInUSD ? formatUSD(totalCollateralInUSD) : "-"}
            after={totalCollateralInUSD.plus(
              usageAsCollateralEnabled
                ? assetCollateralInUSD.negated()
                : assetCollateralInUSD
            )}
            formatAfter={formatUSD}
          />
          <ListItem
            label={t`Borrowed Available`}
            value={
              availableBorrowsInUSD ? formatUSD(availableBorrowsInUSD) : "-"
            }
            after={estimation.availableBorrowsInUSD}
            formatAfter={formatUSD}
          />
          <ListItem
            label={t`Borrow Limit Used`}
            value={borrowLimitUsed ? formatPct(borrowLimitUsed) : "-"}
            after={estimation.borrowLimitUsed}
            formatAfter={formatPct}
          />
          <ListItem
            label={t`Health Factor`}
            value={
              healthFactor.isPositive()
                ? formatAmtShort(healthFactor).includes("T")
                  ? "-"
                  : formatAmtShort(healthFactor)
                : "-"
            }
            after={
              !estimation.healthFactor
                ? undefined
                : estimation.healthFactor.isPositive()
                ? estimation.healthFactor
                : "-"
            }
            formatAfter={formatAmtShort}
          />
        </div>
      </div>

      <div className="bottom-btn-wrap">
        <Button
          block
          type="primary"
          onClick={() => {
            closeModal();
            setUsageAsCollateral(
              !usageAsCollateralEnabled,
              asset.underlyingAsset
            );
          }}
          disabled={!!estimation.unavailableReason}
        >
          {estimation.unavailableReason ||
            (usageAsCollateralEnabled ? t`Disable` : t`Enable`)}
        </Button>
        <div className="bottom-wrap">
          <span className="label">{t`Deposited`}</span>
          <span className="value">
            {formatAmt(deposited, {
              symbol: displaySymbol || symbol,
              shorteningThreshold: 20,
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
export default BorrowModal;
