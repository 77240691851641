import { CHAIN_MARKET_CONFIG, MarketDataType } from "./market";
import {
  ChainId,
  isSupportedChain,
  NetworkConfig,
  NETWORK_CONFIG,
} from "./network";

import { StakeConfig, STAKE_CONFIG } from "./staken";

import {FaucetConfig, faucetConfig} from "./faucet";

export type { ChainId, NetworkConfig, MarketDataType, StakeConfig };
export { isSupportedChain };

export const getNetworkConfig = (chainId: ChainId): NetworkConfig =>
  NETWORK_CONFIG[chainId];

export const getMarketConfig = (chainId: ChainId): MarketDataType =>
  CHAIN_MARKET_CONFIG[chainId];

export const getStakeConfig = (chainId: ChainId): StakeConfig =>
  STAKE_CONFIG[chainId];

export const getFauctConfig=():FaucetConfig => faucetConfig;
