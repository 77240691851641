//common errors
export const error: { [key: string]: string } = {
  "33": "The caller must be the pool admin",
  "59": "User borrows on behalf, but allowance are too small",

  //contract specific errors
  "1": "Amount must be greater than 0",
  "2": "Action requires an active reserve",
  "3": "Action cannot be performed because the reserve is frozen",
  "4": "The current liquidity is not enough",
  "5": "User cannot withdraw more than the available balance",
  "6": "Transfer cannot be allowed.",
  "7": "Borrowing is not enabled",
  "8": "Invalid interest rate mode selected",
  "9": "The collateral balance is 0",
  "10": "Health factor is lesser than the liquidation threshold",
  "11": "There is not enough collateral to cover a new borrow, It may be that the value obtained by the front-end max button is too large due to the fluctuation of interest and value at any time. Please try again, or adjust the value of max slightly.",
  "12": "stable borrowing not enabled",
  "13": "collateral is (mostly) the same currency that is being borrowed",
  "14": "The requested amount is greater than the max loan size in stable rate mode",
  "15": "for repayment of stable debt, the user needs to have stable debt, otherwise, he needs to have variable debt",
  "16": "To repay on behalf of an user an explicit amount to repay is needed",
  "17": "User does not have a stable rate loan in progress on this reserve",
  "18": "User does not have a variable rate loan in progress on this reserve",
  "19": "The underlying balance needs to be greater than 0",
  "20": "User supply is already being used as collateral",
  "21": "User does not have any stable rate loan for this reserve",
  "22": "Interest rate rebalance conditions were not met",
  "23": "Liquidation call failed",
  "24": "There is not enough liquidity available to borrow",
  "25": "The requested amount is too small for a FlashLoan.",
  "26": "The actual balance of the protocol is inconsistent",
  "27": "The caller of the function is not the lending pool configurator",
  "28": "",
  "29": "The caller of this function must be a lending pool",
  "30": "User cannot give allowance to himself",
  "31": "Transferred amount needs to be greater than zero",
  "32": "Reserve has already been initialized",
  "34": "The liquidity of the reserve needs to be 0",
  "35": "The liquidity of the reserve needs to be 0",
  "36": "The liquidity of the reserve needs to be 0",
  "37": "The liquidity of the reserve needs to be 0",
  "38": "The liquidity of the reserve needs to be 0",
  "39": "The liquidity of the reserve needs to be 0",
  "40": "The liquidity of the reserve needs to be 0",
  "75": "Invalid risk parameters for the reserve",
  "76": "The caller must be the emergency admin",
  "41": "Provider is not registered",
  "42": "Health factor is not below the threshold",
  "43": "The collateral chosen cannot be liquidated",
  "44": "User did not borrow the specified currency",
  "45": "There isn't enough liquidity available to liquidate",
  "46": "No errors",
  "47": "Invalid flashloan mode selected",
  "48": "",
  "49": "",
  "50": "",
  "51": "Liquidity index overflows uint128",
  "52": "Variable borrow index overflows uint128",
  "53": " Liquidity rate overflows uint128",
  "54": "Variable borrow rate overflows uint128",
  "55": "Stable borrow rate overflows uint128",
  "56": "invalid amount to mint",
  "57": "",
  "58": "invalid amount to burn",
  "60": "",
  "61": "",
  "62": "",
  "63": "",
  "64": "Pool is paused",
  "65": "",
  "66": "",
  "67": "",
  "68": "",
  "69": "",
  "70": "",
  "71": "",
  "72": "",
  "73": "",
  "74": "",
  "77": "",
  "78": "",
  "79": "",
  "80": "",
};
