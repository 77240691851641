import { EthereumAddress } from "@/types/web3";

export type FaucetConfig = {
    address:EthereumAddress
}

export const faucetConfig:FaucetConfig = {
address:"0x0161fdceD6A654e74ea99E9c0A15d4468DaE9AfB"
}

export default faucetConfig;
