import { useModalContext } from "@/hook/app/useModalContext";
import { useMarketData } from "@/hook/useMarketData";
import { useWalletBalance } from "@/hook/useWalletBalance";
import { Button, Input, Segmented } from "antd";
import { useLendingPool } from "@/hook/contracts/useLendingPool";
import { ASSETS_DICT } from "src/constants/assets";
import { BN_ZERO, formatAmt, formatAmtShort, formatPct, formattedToBigNumber, formatUSD } from "src/utils/number";
import { useEffect, useState } from "react";
import "../index.less";
import { AssetMarketData } from "@/types/models";
import { estimateBorrow, estimateRepayment } from "@/utils/estimationHelper";
import ListItem from "./ListItem";
import { useTracking } from "@/hook/useTracking";
import { BigNumber, valueToBigNumber } from "submodule/packages/math-utils/dist/cjs";
import { useWallet } from "@/hook/useWallet";
import { CloseOutlined } from "@ant-design/icons";
import { useUserData } from "@/hook/useUserData";
import { t } from "@lingui/macro";

const BorrowModal: React.FC = () => {
  const { args, closeModal, messageVisible, contentVisible } = useModalContext();

  const { data: marketData } = useMarketData();
  const { data: balance } = useWalletBalance();
  const { data: user } = useUserData();
  const [activeTab, setActiveTab] = useState<string>(args.type);

  const [borrowingAmount, setBorrowingAmount] = useState("");
  const [repaymentAmount, setRepaymentAmount] = useState("");

  const { account, signer } = useWallet();
  const { borrow, repay } = useLendingPool(account, signer);

  const { withTracking } = useTracking();

  useEffect(() => {
    if (!messageVisible) {
      reset();
    }
  }, [messageVisible]);
  useEffect(() => {
    if (!contentVisible) {
      reset();
    }
  }, [contentVisible]);
  useEffect(() => {
    reset();
  }, [args]);

  const reset = () => {
    setActiveTab(args.type);
    setBorrowingAmount("");
    setRepaymentAmount("");
  };

  if (!args.asset || !user) {
    return null;
  }

  const { marketReferenceCurrencyPriceInUSD = BN_ZERO, marketReferenceCurrencyDecimals = 0 } = marketData || {};

  const { asset }: { asset: AssetMarketData } = args;

  const userSummary = user.summary;
  const userAssetBalance = {
    ...user?.balanceByAsset[asset?.symbol],
    inWallet: balance[asset.symbol],
  };
  const {
    symbol,
    displaySymbol,
    variableBorrowAPY,
    variableBorrowIncentiveAPR,
    liquidity,
    borrowingEnabled,
    isFrozen,
    icon,
    name,
  } = asset;
  const { totalBorrowedInUSD, borrowLimitUsed, healthFactor } = userSummary;
  const { borrowed } = userAssetBalance;
  const borrowable = borrowingEnabled && !isFrozen && symbol !== "BUSD";

  const borrowingAmountBn = formattedToBigNumber(borrowingAmount);
  const repaymentAmountBn = formattedToBigNumber(repaymentAmount);

  const estimation =
    activeTab === "borrow"
      ? estimateBorrow({
          amount: borrowingAmountBn,
          userAssetBalance,
          userSummary,
          asset,
          marketReferenceCurrencyPriceInUSD,
          marketReferenceCurrencyDecimals,
        })
      : estimateRepayment({
          amount: repaymentAmountBn,
          userAssetBalance,
          userSummary,
          asset,
          marketReferenceCurrencyPriceInUSD,
          marketReferenceCurrencyDecimals,
        });
  const borrowWithTracking = withTracking("borrow", borrow);
  const repayWithTracking = withTracking("repay", repay);
  const borrowHandle = (amount: BigNumber) =>
    borrowWithTracking({
      amount,
      underlyingAsset: asset.underlyingAsset,
      vdTokenAddress: asset.vdTokenAddress,
    });
  const repayHandle = (amount: BigNumber, all: boolean) =>
    repayWithTracking({
      amount,
      underlyingAsset: asset.underlyingAsset,
      all,
    });
  return (
    <div className="modal-body modal-borrow">
      <div className="close-wrap" onClick={closeModal}>
        <CloseOutlined />
      </div>
      <div className="title-wrap">
        <img src={asset.icon} alt="" />
        <h3>{asset.displaySymbol || asset.name}</h3>
      </div>
      <Segmented
        options={[
          { label: t`Borrow`, value: "borrow", disabled: !borrowable },
          { label: t`Repay`, value: "repay" },
        ]}
        block
        value={activeTab}
        onChange={(value) => {
          setActiveTab(value as string);
        }}
      />
      <div className="input-wrap">
        {/* <Tooltip title="amount" placement="right">
          <span className="label">
            Amount <QuestionCircleOutlined />
          </span>
        </Tooltip> */}
        <span className="label">{t`Amount`}</span>
        {activeTab === "borrow" ? (
          <Input
            allowClear
            placeholder="0.00"
            value={borrowingAmount}
            onChange={(e) => setBorrowingAmount(e.target.value)}
            suffix={
              <div
                onClick={() =>
                  setBorrowingAmount(
                    formatAmt(estimation.maxAmount, {
                      decimalPlaces: 10,
                      roundingMode: BigNumber.ROUND_FLOOR,
                    })
                  )
                }
              >
                {t`Max`}
              </div>
            }
          ></Input>
        ) : (
          <Input
            allowClear
            placeholder="0.00"
            value={repaymentAmount}
            onChange={(e) => setRepaymentAmount(e.target.value)}
            suffix={<div onClick={() => setRepaymentAmount(formatAmt(estimation.maxAmount))}>{t`Max`}</div>}
          ></Input>
        )}
      </div>

      <div className="overview-wrap">
        <span className="label">{t`Transaction overview`}</span>
        <div className="list-wrap">
          <ListItem label={t`Borrow APY`} value={formatPct(variableBorrowAPY)} img={{ src: icon, alt: name }} />
          <ListItem
            label={t`Borrow APR`}
            value={formatPct(variableBorrowIncentiveAPR)}
            img={{ src: ASSETS_DICT.SiO2.icon, alt: ASSETS_DICT.SiO2.name }}
          />
          <ListItem
            label={t`Borrowed`}
            value={totalBorrowedInUSD ? formatUSD(totalBorrowedInUSD) : "-"}
            after={estimation.totalBorrowedInUSD}
            formatAfter={formatUSD}
          />
          <ListItem
            label={t`Borrow Limit Used`}
            value={borrowLimitUsed ? formatPct(borrowLimitUsed) : "-"}
            after={estimation.borrowLimitUsed}
            formatAfter={formatPct}
          />
          <ListItem
            label={t`Health Factor`}
            value={
              healthFactor.isPositive()
                ? formatAmtShort(healthFactor).includes("T")
                  ? "-"
                  : formatAmtShort(healthFactor)
                : "-"
            }
            after={
              !estimation.healthFactor
                ? undefined
                : estimation.healthFactor.isPositive()
                ? estimation.healthFactor
                : "-"
            }
            formatAfter={formatAmtShort}
          />
        </div>
      </div>
      {activeTab === "borrow" ? (
        <div className="bottom-btn-wrap">
          <Button
            block
            type="primary"
            onClick={() => {
              closeModal();

              return borrowHandle(valueToBigNumber(borrowingAmountBn!));
            }}
            disabled={!!estimation.unavailableReason}
          >
            {estimation.unavailableReason || t`Borrow`}
          </Button>
          <div className="bottom-wrap">
            <span className="label">{t`Liquidity`}</span>
            <span className="value">
              {formatAmt(liquidity, {
                symbol: displaySymbol || symbol,
                shorteningThreshold: 20,
              })}
            </span>
          </div>
        </div>
      ) : (
        <div className="bottom-btn-wrap">
          <Button
            block
            type="primary"
            onClick={() => {
              closeModal();

              return repayHandle(valueToBigNumber(repaymentAmountBn!), false);
            }}
            disabled={!!estimation.unavailableReason}
          >
            {estimation.unavailableReason || t`Repay`}
          </Button>
          <div className="tip-wrap">
            {t`Since interest and value fluctuate all the time, the maximum value
            of max may not be able to fully pay off all loans.`}
          </div>
          <div className="bottom-wrap">
            <span className="label">{t`Borrowed`}</span>
            <span className="value">
              {formatAmt(borrowed, {
                symbol: displaySymbol || symbol,
                shorteningThreshold: 20,
              })}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default BorrowModal;
