import BigNumber from 'bignumber.js';

import { BigNumber as BigNumberEthers, BigNumberish } from 'ethers';
import { valueToBigNumber, valueToZDBigNumber } from '../../bignumber';
export type BigNumberValue =
  | string
  | number
  | BigNumber
  | BigNumberEthers
  | BigNumberish;

export const BigNumberZD = BigNumber.clone({
  DECIMAL_PLACES: 0,
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
});

export const SECONDS_PER_MONTH = valueToZDBigNumber(2_592_000);
export const BN_ZERO = valueToZDBigNumber(0);
export const BN_ONE = valueToZDBigNumber(1);
export const BN_DECIMAL_PRECISION = valueToZDBigNumber(1e18);

export interface CalculateAccruedIncentivesRequest {
  principalUserBalance: BigNumber; // principal deposit or borrow amount
  reserveIndex: BigNumber; // tracks the interest earned by a reserve
  userIndex: BigNumber; // tracks the interest earned by a user from a particular reserve
  precision: number; // decimal precision of rewards calculation
  reserveIndexTimestamp: number; // timestamp of last protocol interaction
  totalSupply: BigNumber; // total deposits or borrows of a reserve
  currentTimestamp: number;
  incentiveIntialSupply: string;
  incentiveInflactionStart: string;
  incentiveDecayRatio: string;
}

// Calculate incentives earned by user since reserveIndexTimestamp
// Incentives earned before reserveIndexTimestamp are tracked seperately (userUnclaimedRewards from UiIncentiveDataProvider)
// This function is used for deposit, variableDebt, and stableDebt incentives
export function calculateAccruedIncentives({
  principalUserBalance,
  reserveIndex,
  userIndex,
  precision,
  reserveIndexTimestamp,
  totalSupply,
  currentTimestamp,
  incentiveIntialSupply,
  incentiveInflactionStart,
  incentiveDecayRatio,
}: CalculateAccruedIncentivesRequest): BigNumber {
  if (totalSupply.isEqualTo(new BigNumber(0))) {
    return new BigNumber(0);
  }

  let currentReserveIndex;
  if (reserveIndexTimestamp === Number(currentTimestamp)) {
    currentReserveIndex = reserveIndex;
  } else {
    let cumulativeDelta = BN_ZERO;

    const ratio = valueToBigNumber(incentiveDecayRatio)
      .dividedBy(1e18)
      .toFixed(2);

    // scope to avoid stack too deep
    let lastSpan = BN_ZERO;
    if (
      valueToZDBigNumber(reserveIndexTimestamp).gt(
        valueToZDBigNumber(incentiveInflactionStart),
      )
    ) {
      lastSpan = valueToZDBigNumber(reserveIndexTimestamp).minus(
        valueToZDBigNumber(incentiveInflactionStart),
      );
    }

    let curSpan = valueToZDBigNumber(currentTimestamp).minus(
      valueToZDBigNumber(incentiveInflactionStart),
    );
    const lastDecayPeriod = lastSpan.dividedBy(SECONDS_PER_MONTH);
    const curDecayPeriod = valueToZDBigNumber(curSpan).div(SECONDS_PER_MONTH);

    for (let cur = curDecayPeriod; cur.gte(lastDecayPeriod); ) {
      const tail = BigNumber.max(lastSpan, cur.multipliedBy(SECONDS_PER_MONTH));

      cumulativeDelta = cumulativeDelta.plus(
        curSpan
          .minus(tail)
          .multipliedBy(BN_DECIMAL_PRECISION)
          .dividedBy(SECONDS_PER_MONTH)
          .multipliedBy(
            valueToZDBigNumber(ratio).exponentiatedBy(cur).multipliedBy(1e18),
          ),
      );

      if (cur.eq(BN_ZERO)) {
        break;
      } else {
        curSpan = cur.multipliedBy(SECONDS_PER_MONTH);
        cur = cur.minus(BN_ONE);
      }
    }

    currentReserveIndex = valueToZDBigNumber(incentiveIntialSupply)
      .multipliedBy(cumulativeDelta.dividedBy(BN_DECIMAL_PRECISION))
      .dividedBy(totalSupply);

    currentReserveIndex = currentReserveIndex.plus(reserveIndex);
  }

  // just for test purpose
  if (currentReserveIndex.isLessThan(userIndex)) {
    throw new Error('The latest share shall not be less than the previous one');
  }

  const reward = principalUserBalance
    .multipliedBy(currentReserveIndex.minus(userIndex))
    .shiftedBy(precision * -1);

  return reward;
}
