import { languageDict, useAppSetting } from "@/hook/app/useAppSetting";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, MenuProps, Space, Switch } from "antd";
import { useState } from "react";
import "./index.less";
import { t } from "@lingui/macro";
import { ReactComponent as ImgSetting } from "@img/icon-setting.svg";
const ConnectDropDown: React.FC = () => {
  const { settingData, changeTheme, changeLanguage } = useAppSetting();

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const handleChangeTheme = () => {
    setVisible(true);
    if (settingData?.theme === "dark") {
      changeTheme("light");
    } else {
      changeTheme("dark");
    }
  };
  const handleLanguageClick: MenuProps["onClick"] = (e) => {
    setVisible(true);
    changeLanguage(e.key);
  };

  const dropDownContent = () => {
    const menu = (
      <Menu
        onClick={handleLanguageClick}
        defaultSelectedKeys={settingData?.language}
        items={Object.keys(languageDict).map((key) => ({
          key,
          label: languageDict[key],
        }))}
      />
    );

    return (
      <div className="setting-drop-down">
        <h3>{t`Global Setting`}</h3>
        <div className="handle-wrap">
          <div className="handle-item" onClick={handleChangeTheme}>
            <span>{t`Dark Mode`}</span>
            <Switch checked={settingData.theme === "dark"} />
          </div>

          <Dropdown overlay={menu}>
            <div className="handle-item">
              <span>{t`Select Language`}</span>
              <Space>
                {languageDict[settingData.language]}
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
        </div>
      </div>
    );
  };
  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={visible}
      overlay={dropDownContent}
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
    >
      <ImgSetting className="setting-menu"></ImgSetting>
      {/* <img
        className="setting-menu"
        src={require("@img/icon-mobile-menu.png")}
        alt=""
      /> */}
    </Dropdown>
  );
};
export default ConnectDropDown;
