import { Descriptions } from "antd";
import "./index.less";
import type { TableListProps } from "./index";
import { StateMessage } from "@/component";
const MobileTable: React.FC<TableListProps> = ({
  columns,
  dataSource,
  title,
  rowClick,
  stateMessage,
}) => {
  const list = columns.filter((item) => item.noShow !== "mobile");

  return (
    <div className="mobile-table">
      {dataSource.length ? (
        <div className="table-body">
          {dataSource.map((item, index) => {
            return (
              <div
                key={item.symbol || index}
                className="list-row"
                onClick={() => rowClick?.(item)}
              >
                <Descriptions column={1}>
                  {list.map((column) => {
                    const text = item[column.dataIndex || ""];
                    return (
                      <Descriptions.Item
                        key={column.title}
                        label={column.title}
                      >
                        {column.render?.(text, item, index) ?? text}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </div>
            );
          })}
        </div>
      ) : (
        <StateMessage {...stateMessage} />
      )}
    </div>
  );
};
export default MobileTable;
