import { Stake } from "submodule/packages/contract-helpers/dist/cjs";
import { CHAIN_ID, ChainId } from "./network";

export interface StakeConfig {
  chainId: ChainId;
  stakeDataProvider: string;
  tokens: {
    [token: string]: {
      TOKEN_STAKING: string;
      STAKING_REWARD_TOKEN: string;
      STAKING_HELPER?: string;
    };
  };
  queryStakeDataUrl?: string;
  wsStakeDataUrl?: string;
}

export const astarStakeConfig: StakeConfig = {
  chainId: CHAIN_ID.astar,
  stakeDataProvider: "",
  tokens: {
    [Stake.sio2]: {
      TOKEN_STAKING: "0xEabE3E7F94F9471A252819F8DC9d1F30c355E902",
      STAKING_REWARD_TOKEN: "0xcCA488aEEf7A1D5C633f877453784F025e7cF160",
      STAKING_HELPER: "0xcC869E8F7918a5B307685cAe6db80F9151915Cd4",
    },
  },
  queryStakeDataUrl: "https://cache-api-1.aave.com/graphql",
  wsStakeDataUrl: "wss://cache-api-1.aave.com/graphql",
};

export const shibuyaStakeConfig: StakeConfig = {
  chainId: CHAIN_ID.shibuya,
  // stakeDataProvider: "0x59df8ACc223622d244de32112d4561A009C25Fe5",
  stakeDataProvider: "",
  tokens: {
    [Stake.sio2]: {
      TOKEN_STAKING: "0xE73d66ba54c491b98041cb62C791361536d1cB1a",
      STAKING_REWARD_TOKEN: "0x76948c31A88496323fEC78B00C68D4b7b612bFE1",
      STAKING_HELPER: "0x0CE543f6d8E60E6AAf4d61d5ab5846819b7C8528",
    },
  },
  queryStakeDataUrl: "https://cache-api-1.aave.com/graphql",
  wsStakeDataUrl: "wss://cache-api-1.aave.com/graphql",
};
export const devStakeConfig: StakeConfig = {
  ...shibuyaStakeConfig,
  chainId: CHAIN_ID.dev,
};

// export const getStakeConfig = () => {
//   return shibuyaStakeConfig; // TODO: replace on mainnet
// };

export const STAKE_CONFIG: {
  [key in ChainId]: StakeConfig;
} = {
  [CHAIN_ID.astar]: astarStakeConfig,
  [CHAIN_ID.shibuya]: shibuyaStakeConfig,
  [CHAIN_ID.dev]: devStakeConfig,
} as const;
