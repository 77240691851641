import { BigNumber } from "submodule/packages/math-utils/dist/cjs";
import "../index.less";
import { ReactComponent as ImgArrowRight } from "@img/icon_arrow-right.svg";
type ListItemProps = {
  img?: {
    src: string;
    alt: string;
  };
  label: string;
  value: string;
  after?: BigNumber | string;
  formatAfter?: (num: BigNumber) => string;
};
const ListItem: React.FC<ListItemProps> = ({ img, label, value, after, formatAfter }) => {
  return (
    <div className="modal-list-item">
      <div className="list-left">
        {img ? <img src={img.src} alt={img.alt} /> : null}
        <span className="list-label">{label}</span>
      </div>
      <div className="list-right">
        <span className="list-value">{value}</span>
        {after ? (
          <div className="after-wrap">
            <div className="arrow-right-svg-wrap">
              <ImgArrowRight />
              <ImgArrowRight />
              <ImgArrowRight />
            </div>
            <span className="after-tip">{typeof after === "string" ? after : formatAfter?.(after)}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ListItem;
