import { CHAIN_ID } from "src/libs/config/network";

export const DEFAULT_CHAIN_ID =
  process.env.REACT_APP_ENV === "dev"
    ? CHAIN_ID.dev
    : process.env.REACT_APP_ENV === "prod"
    ? CHAIN_ID.astar
    : CHAIN_ID.shibuya;

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
