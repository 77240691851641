/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "監査人",
    "Powered by": "搭載",
    "Please, connect your wallet": "ウォレットを接続してください",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      "預入、借入、ポジションを見るためにウォレットを接続してください。",
    "Connect Wallet": "ウォレットを接続する",
    "You need to change to a supported chain":
      "サポート済みのチェーンに変更する必要があります",
    "Please switch the chain with your wallet.":
      "ウォレットのチェーンを切り替えてください。",
    "Failed to switch the chain": "チェーンの切り替えに失敗しました",
    "In local-dev mode, only localhost:8545 network can be connected":
      "ローカル開発版では、localhost:8545 ネットワークのみ接続可能です。",
    "SiO2 Finance is not yet depolyed to this network":
      "SiO2ファイナンスはまだこのネットワークで展開されていません。",
    "Please switch to": "に切り替えてください",
    "Change Network": "ネットワークを変更してください",
    "Please switch to ~": "〜に切り替えてください。",
    "No Data": "データがありません",
    Show: "見せる",
    Hide: "隠れる",
    "Copy Success": "コピー成功",
    "No account": "アカウントがありません",
    Network: "ネットワーク",
    "Copy Address": "アドレスをコピー",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "ウォレットを切断",
    Menu: "メニュー",
    "Global Setting": "グローバル設定",
    "Dark Mode": "ダークモード",
    "Select Language": "言語を選択してください",
    "Add Tokens": "トークンを追加",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "私たちはSIO2トークンを一般に公開していませんので、SIO2トークンは現在市場価格を持っていません。そのため、推定トークン価格($",
        ["SIO2_PRICE"],
        ")に基づいて APR を計算しています。より詳しい情報は、ドキュメントをご覧ください。",
      ],
    Borrow: "借入",
    Repay: "返済",
    Amount: "金額",
    Max: "最大",
    "Transaction overview": "取引概要",
    "Borrow APY": "借入APY",
    "Borrow APR": "借入ボーナスAPR",
    Borrowed: "借入残高",
    "Borrow Limit Used": "借入限度額の使用率",
    "Health Factor": "ヘルスファクター",
    Liquidity: "流動性",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "金利と価格は常に変化しているため、’最大’で最大値を指定しても借入を完全に返済できないことがあります。",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      "ここでジョシュ！ 最初の週は私たちのクリスタル鉱山の「カナリアウィーク」です。 すべての市況に備えるために、ヘルスファクターを4以上に維持することをユーザーに推奨します。 詳細については、<0>記事</0>をお読みください。",
    "Josh here!": "Josh here!",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      "ここでジョシュ！ 最初の週は私たちのクリスタル鉱山の「カナリアウィーク」です。 すべての市況に備えるために、ヘルスファクターを4以上に維持することをユーザーに推奨します。 詳細については、<0>記事</0>をお読みください。",
    Collateral: "担保",
    ON: "オン",
    OFF: "オフ",
    Collateral_Amount: "担保額",
    "Borrowed Available": "借入限度額",
    Disable: "無効にする",
    Enable: "有効にする",
    Deposited: "残高",
    Supply: "預入",
    Withdraw: "引出し",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "<1>Algem</1> から <0>ASTR ステーキング報酬</0>を受け取るには、Algem DApp 内の <2>SiO2 アダプター</2> 経由で $nASTR を提供してください。",
    "Supply APY": "預入APY",
    "Supply APR": "供給 APR",
    "Borrow Available": "借入可否",
    "This asset can't be used as collateral.":
      "この資産は担保として利用できません。",
    "Wallet Balance": "ウォレット残高",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "取引の確認時に利息が蓄積され続けるため、入力された最大数は、取引が完了したときに獲得した合計を反映していない場合があります",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      "利子と残高は常に変動するので、”最大”を指定しても全てのローンを完全に返済することができないかもしれません。",
    Dashboard: "ダッシュボード",
    Market: "マーケット",
    Stake: "ステーク",
    "Public Sale": "パブリックセール",
    Faucet: "フォセット",
    "Transaction Preparing": "トランザクションの準備",
    "Waiting for transaction to be ready...":
      "トランザクションの準備が整うのを待っています...",
    "Confirm Transaction": "トランザクションの確認",
    "Approve sending the ERC-20 asset to the pool.":
      "ERC-20アセットをプールに送信することを承認します。",
    "Transaction Pending": "トランザクション保留中",
    "Waiting for the transaction to be confirmed...":
      "トランザクションが確認されるのを待っています...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "クレジットでERC-20資産を借りる契約を承認します。",
    "Confirm the transaction.": "トランザクションを確認してください。",
    "Transaction Submitted": "送信されたトランザクション",
    "Succeeded!": "成功しました！",
    "Your transaction confirmed!": "取引が確認されました！",
    "Transaction Canceled": "トランザクションがキャンセルされました",
    "You have canceled the transaction.": "取引をキャンセルしました。",
    Error: "エラー",
    "Enter amount": "金額を入力してください",
    "No balance to deposit": "入金する残高がありません",
    "No balance to withdraw": "引き出す残高がありません",
    "Insufficient collateral": "不十分な担保",
    "No liquidity to withdraw": "撤回する流動性がない",
    "Health factor too low": "ヘルスファクターが低すぎます",
    "Borrowing limit reached": "借入限度額に達しました",
    "No liquidity to borrow": "借りる流動性がない",
    "Not enough wallet balance to repay":
      "返済するのに十分なウォレット残高がありません",
    "No borrowed balance to repay": "返済する借入残高がありません",
    "Enter leverage": "レバレッジを入力してください",
    "No balance to loop": "ループするバランスがありません",
    "Supply Balance": "預入残高",
    "Borrow Balance": "借入残高",
    Asset: "資産",
    "Reward APR": "ボーナスAPR",
    "Interest Rate": "金利",
    "Borrow Markets": "借入一覧",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "預入資産とインセンティブの加重平均APY",
    Supplied: "預入残高",
    "Supply Markets": "預入一覧",
    "Net APY": "純利回り",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "純利回りは預入,借入,利子を総合して算出した利回りです。借入のAPYが預入のAPYを超える場合、純利回りがマイナスになります。",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "ヘルスファクターとLTVが担保の安全度を決めます。清算を避けるためには、以下の方法があります。",
    "1. supply more collateral": "1. 担保を追加する",
    "2. repay borrow positions": "2. 借入を返済する",
    "When your health factor is below 1, your collateral will be liquidated.":
      "ヘルスファクターが1を下回ると、あなたの担保は清算されます。",
    "RISK DETAILS": "リスク詳細",
    Claim: "受取",
    "Available SIO2 Rewards": "受取可能なSIO2報酬",
    "Learn more": "もっと詳しく",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      "SIO2の報酬は1分ごとに発生しますが、UI上の数値は預入・借入の取引時にのみ更新されます。そのため、'受取'をクリックしたときに表示されるよりも多くの報酬を受け取ることができます。",
    Name: "名前I think this word “Name” is too abstract.",
    Action: "行動I think this word “Action” is too abstract.",
    Mint: "ミント",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "スパムやBotを防止するため、テストネットでは各ユーザはアセット毎に1日1回までフォセットを利用することができます。",
    "Total Supplied": "預入総額",
    "Total Borrowed": "借入総額",
    "Supply Reward APR": "預入ボーナスAPR",
    "Borrow Interest Rate": "金利を借りる",
    "Borrow Reward APR": "借入ボーナスAPR",
    Details: "詳細",
    Price: "価格",
    Borrowing: "借入可否",
    Yes: "可",
    No: "不可",
    "Reserve Factor": "リザーブファクター",
    "Used as Collateral": "担保利用可否",
    "Loan to Value": "LTV",
    "Liquidation Threshold": "清算閾値",
    "Liquidation Penalty": "清算ペナルティ",
    "Market Details": "マーケット詳細",
    "Asset Pools": "資産プール",
    Others: "その他",
    total: "総額",
    "Top3 Markets": "トップ3市場",
    "Astar Network": "アスターネットワーク",
    Token: "トークン",
    "SIO2 Token(SIO2)": "SIO2 トークン(SIO2)",
    "Total Raised": "調達額合計",
    "Sales Opened": "販売開始",
    TBD: "未定",
    "Sales Closed": "販売終了",
    "This page is developed for public sale on mainnet, but not testnet.":
      "このページはテストネット上ではなく、メインネット上でのパブリックセール用に作成しています。",
    "SIO2 PUBLIC SALE": "SIO2パブリックセール",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      "トークンの配布ルール: ローンチ後6ヶ月の期間の後,2年かけて分配されます",
    Purchase: "購入",
    "Stake SIO2": "SIO2をステークする",
    Staking: "ステーキング",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "SIO2保有者は、SIO2をセーフティ・モジュールにステークして、プロトコルにセキュリティを追加し、セーフティ・インセンティブを獲得することができます。SIO2が不足した場合、最大で出資額の30%が削減され、不足分を補うことができます。プロトコルを保護するための追加的なレイヤーを提供します。",
    "Total emission per day": "1日あたりの総排出量",
    "Claim Rewards": "報酬を受取る",
    "Funds in the Safety Module": "セーフティ・モジュール内の資産",
    "1 year": "1年",
    "2 years": "2年",
    "3 years": "3年",
    "The amount you're trying to stake exceeds your balance by {0} SIO2":
      "ステーキング量が残高を超過しています",
    "Locked Staking": "ロック済ステーキング",
    Balance: "価格",
    "Duration (years)": "期間(年)",
    "Pool Share": "プールシェア率",
    Confirm: "確認",
    ID: "ID",
    "Staked Amount": "ステーキング額",
    "Weighted Staked Amount": "重み付きステーキング額",
    "Date Time": "日付時刻",
    "Locked Until": "ロック期限",
    Redeem: "償還",
    "Locked Stakes": "ロック済ステーキング",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      "ロック済のステーキングはまだありません。SIO2トークンをステークしましょう。",
    Summary: "概要",
    "Staking APR": "ステーキングAPR",
    "Unweighted Stake": "ステーキング量",
    "Weighted Stake": "重み付きステーキング量",
  },
};
