import { RouteObject_ } from "@/hook/app/useRoutesArr";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Drawer, Dropdown, Menu, MenuProps, Space, Switch } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import "./index.less";
import { t } from "@lingui/macro";
import { useNavigate } from "@/hook/app/useNavigate";
import { languageDict, useAppSetting } from "@/hook/app/useAppSetting";
import { ReactComponent as ImgSetting } from "@img/icon-setting.svg";
type MobileDrawProps = {
  linkList: RouteObject_[];
  onAddTokensClick: () => void;
};
const MobileDraw: React.FC<MobileDrawProps> = ({
  linkList,
  onAddTokensClick,
}) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigatePush = useNavigate();
  const { settingData, changeTheme, changeLanguage } = useAppSetting();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const clickLinkItem = (link: RouteObject_) => {
    navigatePush(link.path);
    setVisible(false);
  };

  const handleChangeTheme = () => {
    if (settingData?.theme === "dark") {
      changeTheme("light");
    } else {
      changeTheme("dark");
    }
  };
  const handleLanguageClick: MenuProps["onClick"] = (e) => {
    changeLanguage(e.key);
  };

  const menu = (
    <Menu
      onClick={handleLanguageClick}
      defaultSelectedKeys={settingData.language}
      items={Object.keys(languageDict).map((key) => ({
        key,
        label: languageDict[key],
      }))}
    />
  );
  return (
    <div className="mobile-draw">
      <div className="menu-btn">
        {visible ? (
          <CloseOutlined onClick={onClose} />
        ) : (
          <ImgSetting
            onClick={showDrawer}
            className="setting-menu"
          ></ImgSetting>
        )}
      </div>

      <Drawer
        placement="top"
        style={{ top: 64 }}
        onClose={onClose}
        visible={visible}
        height="auto"
        className="mobile-draw-top-header"
        closable={false}
      >
        <div className="drawer-wrap">
          <div className="menu-wrap">
            <h3>{t`Menu`}</h3>
            <div className="list-wrap">
              {linkList.map((link) => {
                let isActive = location.pathname.includes(link.path);
                if (link.label === "Dashboard" && location.pathname === "/") {
                  isActive = true;
                }
                return (
                  <div
                    className={`link-item ${isActive ? "active" : ""}`}
                    key={link.path}
                  >
                    <div className="link" onClick={() => clickLinkItem(link)}>
                      {isActive && (
                        <span className="iconfont icon-right"></span>
                      )}
                      {link.label}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="setting-wrap">
            <h3>{t`Global Setting`}</h3>
            <div className="handle-wrap">
              <div className="handle-item">
                <span>{t`Dark Mode`}</span>
                <Switch
                  checked={settingData?.theme === "dark"}
                  onChange={handleChangeTheme}
                />
              </div>

              <Dropdown overlay={menu}>
                <div className="handle-item">
                  <span>{t`Select Language`}</span>
                  <Space>
                    {languageDict[settingData.language]}
                    <DownOutlined />
                  </Space>
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="bottom-wrap">
            <Button
              type="primary"
              className="add-token"
              onClick={onAddTokensClick}
            >
              {t`Add Tokens`}
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default MobileDraw;
