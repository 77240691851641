import "./index.less";
import { t } from "@lingui/macro";
type StateMessageType = {
  text?: string;
  img?: string;
  render?: () => React.ReactNode;
};

const StateMessage: React.FC<StateMessageType> = ({ img, text, render }) => {
  return (
    <div className="state-message-wrap">
      {render ? (
        render()
      ) : (
        <>
          <img src={img || require("@img/no-wallet.png")} alt="" />
          <span>{text || t`No Data`}</span>
        </>
      )}
    </div>
  );
};
export default StateMessage;
