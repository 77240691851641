import { useModalContext } from "@/hook/app/useModalContext";
import { useResponsive } from "ahooks";
import { Drawer, Modal } from "antd";
import "./index.less";
const WrapModal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { contentVisible, closeModal } = useModalContext();
  const responsive = useResponsive();
  let WrapComponent: any = Drawer;
  let wrapProps: any = {
    className: "modal-content-wrap",
    height: "auto",
    placement: "bottom",

    visible: contentVisible,
    closable: false,

    onClose: () => closeModal(),
  };
  if (responsive.sm) {
    WrapComponent = Modal;
    wrapProps = {
      footer: null,
      wrapClassName: wrapProps.className,

      onCancel: wrapProps.onClose,
      visible: wrapProps.visible,
      closable: wrapProps.closable,
    };
  }
  return <WrapComponent {...wrapProps}>{children}</WrapComponent>;
};
export default WrapModal;
