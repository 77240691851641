import { useModalContext } from "@/hook/app/useModalContext";

import { Button, Input } from "antd";

import { useState } from "react";
import "../index.less";

import { CloseOutlined } from "@ant-design/icons";

const BorrowModal: React.FC = () => {
  const { closeModal } = useModalContext();

  const [amount, setAmount] = useState("");

  return (
    <div className="modal-body modal-borrow">
      <div className="close-wrap" onClick={closeModal}>
        <CloseOutlined />
      </div>
      <div className="title-wrap">
        <img src={require("@img/symbols/symbol_sio2.png")} alt="" />
        <h3>Purchase</h3>
      </div>

      <div className="input-wrap">
        <span className="label">Purchase Amount</span>
        <Input
          allowClear
          placeholder="0.00"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        ></Input>
      </div>

      <div className="overview-wrap">
        <span className="label">Total Price</span>
        <div className="list-wrap">
          <div>XXXX USDC</div>
        </div>
      </div>
      <div className="bottom-btn-wrap">
        <p className="disclaimer-wrap">
          Disclaimer: Users need to be from an eligible jurisdiction to
          participate in this token sale.
        </p>
        <Button
          block
          type="primary"
          onClick={() => {
            closeModal();
          }}
          disabled={!amount}
        >
          {`Purchase`}
        </Button>
      </div>
    </div>
  );
};
export default BorrowModal;
