import { settingDataProps } from "@/hook/app/useAppSetting";
import {
  BigNumber,
  valueToBigNumber,
} from "submodule/packages/math-utils/dist/cjs";
import { BN_ONE } from "./number";

export const getGasPriceMultiplier = () => {
  const value = localStorage.getItem("gasPriceMultiplier");
  return value ? valueToBigNumber(value) : BN_ONE;
};

export const setGasPriceMultiplier = (value: BigNumber) =>
  localStorage.setItem("gasPriceMultiplier", value.toString());

export const getSettingStorage = () => {
  let value = localStorage.getItem("app-setting");
  return value ? JSON.parse(value) : undefined;
};

export const setSettingStorage = (value: settingDataProps) => {
  return localStorage.setItem("app-setting", JSON.stringify(value));
};
