import { RouteObject } from "react-router-dom";
import React from "react";
import { useWallet } from "../useWallet";
import { DEV_CHAIN } from "@/constants/config";
import { t } from "@lingui/macro";

const HomePage = React.lazy(() => import("@/view/dashboard/Dashboard"));
const MarketsPage = React.lazy(() => import("@/view/market/Market"));

const StakingPage = React.lazy(() => import("@/view/staking/Staking"));

const PublicSalePage = React.lazy(() => import("@/view/public/PublicSale"));
const FaucetPage = React.lazy(() => import("@/view/faucet/Faucet"));

export type RouteObject_ = RouteObject & {
  isDev?: boolean;
  isTopHeader?: boolean;
  label?: string;
  isShowWithoutChain?: boolean;
  path: string;
};

export const useRoutesArr = () => {
  const { chainId } = useWallet();
  let baseRoutes: RouteObject_[] = [
    {
      index: true,
      path: "/dashboard",
      element: <HomePage />,
      isTopHeader: true,
      label: t`Dashboard`,
    },
    {
      path: "/market",
      element: <MarketsPage />,
      isTopHeader: true,
      label: t`Market`,
      isShowWithoutChain: true,
    },

    {
      path: "/staking",
      element: <StakingPage />,
      isTopHeader: true,
      label: t`Stake`,
    },

    { path: "*", element: <HomePage />, label: t`Dashboard` },
  ];
  let devRoutes: RouteObject_[] = [
    {
      path: "/public-sale",
      element: <PublicSalePage />,
      isTopHeader: true,
      label: t`Public Sale`,
    },
    {
      path: "/faucet",
      element: <FaucetPage />,
      isDev: true,
      isTopHeader: true,
      label: t`Faucet`,
    },
  ];
  let routes = [...baseRoutes];

  if (chainId && DEV_CHAIN.includes(chainId)) {
    routes = [...baseRoutes, ...devRoutes];
  }

  return {
    routes,
  };
};
