import "./App.less";
import { TopHeader } from "./component";
import { configResponsive } from "ahooks";
import { ModalContextProvider } from "./hook/app/useModalContext";
import { Spin } from "antd";
import React, { useMemo } from "react";
import { useRoutesArr } from "@/hook/app/useRoutesArr";
import { useLocation, useSearchParams, useRoutes } from "react-router-dom";
import { useWallet } from "./hook/useWallet";
import { isSupportedChain } from "./libs/config";
import MissNetwork from "./component/MissConnectWallet/MissNetwork";
import MissConnectWallet from "./component/MissConnectWallet/MissConnectWallet";
import { I18nProvider } from "src/libs/i18n-provider";
import { ConfigProvider } from "antd";
import { useAppSetting } from "./hook/app/useAppSetting";
import { SECRET_KEY, SECRET_VALUE } from "./hook/app/useNavigate";
import Footer from "./component/Footer";
import NotificationBar from "./component/NotificationBar";

configResponsive({
  xs: 0,
  sm: 576,
  md: 900,
  lg: 1440,
  xl: 1500,
});

function App() {
  let location = useLocation();
  let [searchParams] = useSearchParams();
  let { settingData } = useAppSetting();
  let { routes } = useRoutesArr();

  let element = useRoutes(routes);
  const { account, chainId } = useWallet();
  const isUnsupportedChain = useMemo(() => chainId && !isSupportedChain(chainId), [chainId]);

  if (window.location.hostname === "dev.sio2.finance" && searchParams.get(SECRET_KEY) !== SECRET_VALUE) {
    window.location.href = "https://google.com";
  }

  const isShowWithoutChain = routes.find((item) => item.path === location.pathname)?.isShowWithoutChain;

  const routeConDom = (
    <React.Suspense
      fallback={
        <Spin tip="Loading..." size="large">
          <div className="anchor-home-page-load-wrap" />
        </Spin>
      }
    >
      {element}
    </React.Suspense>
  );
  ConfigProvider.config({
    theme: {
      primaryColor: "#f02574",
    },
  });

  const isShowNotification = ["/", "/dashboard", "/market"].includes(location.pathname);
  return (
    <ConfigProvider prefixCls={settingData?.theme === "dark" ? "ant-dark" : undefined}>
      <I18nProvider>
        <ModalContextProvider>
          <div className="app-wrap">
            <TopHeader></TopHeader>
            {isShowNotification ? <NotificationBar></NotificationBar> : null}
            <div className="route-wrap">
              <div className="route-main">
                {isShowWithoutChain ? (
                  routeConDom
                ) : account ? (
                  isUnsupportedChain ? (
                    <MissNetwork></MissNetwork>
                  ) : (
                    routeConDom
                  )
                ) : (
                  <MissConnectWallet />
                )}
              </div>
            </div>
            <Footer></Footer>
          </div>
        </ModalContextProvider>
      </I18nProvider>
    </ConfigProvider>
  );
}

export default App;
