import { useStakeData, useStakeInfoPage } from "./../useStakeData";
import { useModalContext } from "../app/useModalContext";
import { eEthereumTxType, EthereumTransactionTypeExtended } from "submodule/packages/contract-helpers/dist/cjs";
import { serializeError } from "eth-rpc-errors";
import { BigNumber, ethers } from "ethers";
import { useMarketData } from "../useMarketData";
import { useUserData } from "../useUserData";
import { useWalletBalance } from "../useWalletBalance";
import { useGasPrice } from "../useGasPrice";
import { DEFAULT_GAS_PRICE } from "@/constants/config";
import { t } from "@lingui/macro";

export type LendingPoolTxs = {
  actionTx?: EthereumTransactionTypeExtended;
  erc20ApprovalTx?: EthereumTransactionTypeExtended;
  debtErc20ApprovalTx?: EthereumTransactionTypeExtended;
};

export const useTxHandler = () => {
  const { openMessage } = useModalContext();
  const { mutate: mutateMarketData } = useMarketData();
  const { mutate: mutateUserData } = useUserData();
  const { mutate: mutateWalletBalance } = useWalletBalance();
  const { mutate: mutateStakeInfoPage } = useStakeInfoPage();
  const { mutate: mutateStakeData } = useStakeData();
  const { data: estimatedGasPrice } = useGasPrice();
  const revalidate = () => {
    mutateMarketData();
    mutateUserData();
    mutateWalletBalance();
    mutateStakeInfoPage();
    mutateStakeData();
  };

  const handleTx = async (
    txs: EthereumTransactionTypeExtended[],
    signer: ethers.providers.JsonRpcSigner,
    onSucceeded?: VoidFunction
  ) => {
    openMessage({
      type: "loading",
      title: t`Transaction Preparing`,
      message: t`Waiting for transaction to be ready...`,
    });

    const gasPrice = estimatedGasPrice;
    console.log(gasPrice, "gasPrice");
    const { actionTx, erc20ApprovalTx, debtErc20ApprovalTx } = pickLendingPoolTxs(txs);

    try {
      if (erc20ApprovalTx) {
        openMessage({
          type: "loading",
          title: t`Confirm Transaction`,
          message: t`Approve sending the ERC-20 asset to the pool.`,
        });
        const tx = await erc20ApprovalTx.tx();
        const approveRes = await signer.sendTransaction({ ...tx, ...gasPrice });
        openMessage({
          type: "loading",
          title: t`Transaction Pending`,
          message: t`Waiting for the transaction to be confirmed...`,
        });
        await approveRes.wait(1);
      }
      if (debtErc20ApprovalTx) {
        openMessage({
          type: "loading",
          title: t`Confirm Transaction`,
          message: t`Approve the contract to borrow ERC-20 assets on your credit.`,
        });
        const tx = await debtErc20ApprovalTx.tx();
        const approveRes = await signer.sendTransaction({ ...tx, ...gasPrice });
        openMessage({
          type: "loading",
          title: t`Transaction Pending`,
          message: t`Waiting for the transaction to be confirmed...`,
        });
        await approveRes.wait(1);
      }
      if (actionTx) {
        openMessage({
          type: "loading",
          title: t`Confirm Transaction`,
          message: t`Confirm the transaction.`,
        });
        const tx = await actionTx.tx();
        const depositRes = await signer.sendTransaction({
          ...tx,
          value: tx.value ? BigNumber.from(tx.value) : undefined,
          ...gasPrice,
        });
        openMessage({
          type: "submitted",
          title: t`Transaction Submitted`,
        });
        await depositRes.wait(1);
        revalidate();
        openMessage({
          type: "success",
          title: t`Succeeded!`,
          message: t`Your transaction confirmed!`,
          onClose: onSucceeded,
        });
      }
    } catch (e) {
      const error = serializeError(e);
      if (error.code === 4001) {
        openMessage({
          type: "cancel",
          title: t`Transaction Canceled`,
          message: t`You have canceled the transaction.`,
        });
        return { error: error.code };
      }
      let msg_ = (error.data as any)?.originalError?.error?.message || "";

      let msg = msg_.match(/revert\s(.*)/)?.[1];
      openMessage({
        type: "error",
        title: t`Error`,
        // message: t`Something went wrong...`,
        message: msg,
      });
      console.error(error, error.message, error.data);
      return { error: error.code };
    }
  };

  return { handleTx };
};

const pickLendingPoolTxs = (txs: EthereumTransactionTypeExtended[]) =>
  txs.reduce<LendingPoolTxs>((prev, current) => {
    if (current.txType === eEthereumTxType.ERC20_APPROVAL) return { ...prev, erc20ApprovalTx: current };
    if (current.txType === eEthereumTxType.DEBTERC20_APPROVAL) return { ...prev, debtErc20ApprovalTx: current };
    return { ...prev, actionTx: current };
  }, {});
