import { StakeUiHelper } from "submodule/packages/contract-helpers/dist/cjs";
import {
  StakeDataHumanized,
  StakeGeneralDataT,
  StakeUserDataT,
} from "submodule/packages/contract-helpers/dist/cjs/stake-ui-helper/types/stake";
import {
  BigNumber,
  normalizeBN,
  valueToBigNumber,
  valueToZDBigNumber,
} from "submodule/packages/math-utils/dist/cjs";
import { BN_ZERO } from "src/utils/number";
import { getNetworkConfig } from "../config";
import { StaticRPCProvider } from "../pool-data-provider";
const SECONDS_PER_MONTH = valueToZDBigNumber(2_592_000);
const BN_DECIMAL_PRECISION = valueToZDBigNumber(1e18);

export const stakeUiHelperContract = (
  provider: StaticRPCProvider
): StakeUiHelperInterface => StakeUiHelperWrapper.new(provider);

export type StakeUiHelperInterface = {
  getUserUIDataHumanized: (account: string) => Promise<ComputedStakeData>;
};

class StakeUiHelperWrapper implements StakeUiHelperInterface {
  constructor(private proivder: StakeUiHelper) {}

  static new = ({ chainId, provider }: StaticRPCProvider) => {
    const {
      addresses: { stakeUiHelper },
    } = getNetworkConfig(chainId);
    return new StakeUiHelperWrapper(
      new StakeUiHelper({
        stakeUiHelperAddress: stakeUiHelper || "0x000",
        provider,
      })
    );
  };

  getUserUIDataHumanized = async (account: string) => {
    const { proivder } = this;
    const data = await proivder.getUserUIDataHumanized(account);

    return computeStakeData(data);
  };
}

type ComputedStakeData = StakeGeneralDataT<number, BigNumber> &
  StakeUserDataT<number, BigNumber> & {
    distributionPerDay: BigNumber;
    userCooldownEndTime: number;
    userEarningsPerDay: BigNumber;
  };

const getDistributionPerSecond = (
  intialSupply: string,
  inflactionStart: string,
  decayRatio: string
): BigNumber => {
  const currentTime: number = Date.parse(new Date().toString()) / 1000;
  const curSpan = valueToZDBigNumber(currentTime)
    .minus(valueToZDBigNumber(inflactionStart))
    .dividedBy(SECONDS_PER_MONTH);
  const emissionPerSecond = valueToZDBigNumber(intialSupply)
    .multipliedBy(
      valueToBigNumber(decayRatio)
        .dividedBy(BN_DECIMAL_PRECISION)
        .exponentiatedBy(curSpan)
    )
    .dividedBy(SECONDS_PER_MONTH);

  return emissionPerSecond || BN_ZERO;
};

const computeStakeData = (data: StakeDataHumanized): ComputedStakeData => ({
  stakeTokenTotalSupply: normalizeBN(data.stakeTokenTotalSupply, 18),
  stakeCooldownSeconds: valueToBigNumber(data.stakeCooldownSeconds),
  stakeUnstakeWindow: data.stakeUnstakeWindow,
  stakeTokenPriceEth: normalizeBN(data.stakeTokenPriceEth, 18),
  rewardTokenPriceEth: normalizeBN(data.rewardTokenPriceEth, 18),
  stakeApy: normalizeBN(data.stakeApy, 4),
  intialSupply: valueToBigNumber(data.intialSupply),
  inflactionStart: valueToBigNumber(data.inflactionStart),
  decayRatio: valueToBigNumber(data.decayRatio),
  stakeTokenUserBalance: normalizeBN(data.stakeTokenUserBalance, 18),
  underlyingTokenUserBalance: normalizeBN(data.underlyingTokenUserBalance, 18),
  userCooldown: data.userCooldown,
  userIncentivesToClaim: normalizeBN(data.userIncentivesToClaim, 18),
  userPermitNonce: valueToBigNumber(data.userPermitNonce),
  distributionPerDay: normalizeBN(
    getDistributionPerSecond(
      data.intialSupply,
      data.inflactionStart,
      data.decayRatio
    ).multipliedBy(60 * 60 * 24),
    18
  ),
  userCooldownEndTime: 0,
  userEarningsPerDay:
    data.stakeTokenUserBalance !== "0"
      ? normalizeBN(
          getDistributionPerSecond(
            data.intialSupply,
            data.inflactionStart,
            data.decayRatio
          )
            .multipliedBy(60 * 60 * 24)
            .multipliedBy(data.stakeTokenUserBalance)
            .div(data.stakeTokenTotalSupply)
            .toString(),
          18
        )
      : BN_ZERO,
});
