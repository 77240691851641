import { To, useSearchParams } from "react-router-dom";
import { useNavigate as useOriginNavigate } from "react-router-dom";

export const SECRET_KEY = "secret";
export const SECRET_VALUE = "dev-sio2";

export const useNavigate = () => {
  let [searchParams] = useSearchParams();
  const navigate = useOriginNavigate();
  const navigatePush = (url: To) => {
    let secret = searchParams.get(SECRET_KEY);
    if (
      window.location.hostname === "dev.sio2.finance" &&
      secret === SECRET_VALUE
    ) {
      navigate(`${url}?${SECRET_KEY}=${secret}`);
      return;
    }
    navigate(url);
  };

  return navigatePush;
};
