import { useModalContext } from "@/hook/app/useModalContext";
import {
  ArrowUpOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useEffect } from "react";
import "./index.less";
import { error } from "@/constants/error";

export type MessageType =
  | "loading"
  | "warning"
  | "submitted"
  | "error"
  | "success"
  | "cancel";
export type MessageData = {
  type: MessageType;
  title: string;
  message?: string;
  onClose?: () => void;
};

type ImageType = {
  [key in MessageType]: JSX.Element;
};

const imageTypes: ImageType = {
  loading: (
    <div className="img-wrap img-loading">
      <LoadingOutlined />
    </div>
  ),
  warning: (
    <div className="img-wrap img-warning">
      <ExclamationOutlined />
    </div>
  ),
  submitted: (
    <div className="img-wrap img-submitted">
      <ArrowUpOutlined />
    </div>
  ),

  error: (
    <div className="img-wrap img-error">
      <CloseOutlined />
    </div>
  ),
  success: (
    <div className="img-wrap img-success">
      <CheckOutlined />
    </div>
  ),
  cancel: (
    <div className="img-wrap img-warning">
      <ExclamationOutlined />
    </div>
  ),
};

const MESSAGE_VISIBILITY_DURATION = 3000 as const; // 3 seconds
const MESSAGE_TYPES_WITH_DURATION: MessageType[] = ["cancel", "success"];

const MessageModal: React.FC = () => {
  const { messageData, setMessageVisible, messageVisible } = useModalContext();
  const { type } = messageData;

  useEffect(() => {
    if (MESSAGE_TYPES_WITH_DURATION.includes(messageData.type)) {
      setTimeout(() => {
        close();
      }, MESSAGE_VISIBILITY_DURATION);
    }
  }, [messageData]);

  const close = () => {
    messageData.onClose?.();
    setMessageVisible(false);
  };
  return (
    <Modal
      wrapClassName={"modal-message"}
      visible={messageVisible}
      footer={null}
      closable={messageData.type !== "submitted"}
      onCancel={() => close()}
    >
      <div className="modal-body">
        <h3>{messageData.title}</h3>
        {imageTypes[type] || (
          <div className="img-wrap">
            <ExclamationOutlined />
          </div>
        )}

        {messageData.type === "error" ? (
          <span className="des">
            {error[messageData.message as string] || messageData.message}
          </span>
        ) : (
          <span className="des">{messageData.message}</span>
        )}
        {messageData.type === "submitted" ? (
          <Button className="btn-main" type="primary" onClick={() => close()}>
            Close
          </Button>
        ) : null}
      </div>
    </Modal>
  );
};

export default MessageModal;
