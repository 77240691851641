import useSWRImmutable from "swr/immutable";
import { getSettingStorage, setSettingStorage } from "@/utils/localStorage";
import { loadSync } from "@/libs/i18n-provider";
import { Locale } from "src/locales";

export const languageDict: {
  [key: string]: string;
} = {
  zh: "中文（简体）",
  en: "English",
  vi: "Tiếng Việt",
  ko: "한국인",
  tr: "Türk",
  id: "bahasa Indonesia",
  ja: "日本語",
  ru: "Русский",
};
export type settingDataProps = {
  theme: string;
  language: string;
};

export const defaultSetting = {
  theme: "light",
  language: "en",
};

export const useAppSetting = () => {
  const { data: settingData, mutate: mutateAppSetting } = useSWRImmutable(
    "app-setting",
    () => {
      let data = getSettingStorage() || defaultSetting;
      document.body.className = data.theme;
      return data;
    }
  );
  const changeTheme = (theme: string) => {
    let newSettingData = {
      ...settingData,
      theme,
    };

    changeSetting(newSettingData);
    document.body.className = theme;
  };
  const changeLanguage = (language: string) => {
    let newSettingData = {
      ...settingData,
      language,
    };
    loadSync(language as Locale);
    changeSetting(newSettingData);
  };

  const changeSetting = (data: settingDataProps) => {
    setSettingStorage(data);
    mutateAppSetting();
  };

  return {
    settingData,
    changeTheme,
    changeLanguage,
  };
};
