/* Autogenerated file. Do not edit manually. */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import type { UiIncentiveDataProvider } from './UiIncentiveDataProvider';

export class UiIncentiveDataProviderFactory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider,
  ): UiIncentiveDataProvider {
    return new Contract(
      address,
      _abi,
      signerOrProvider,
    ) as UiIncentiveDataProvider;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'contract ILendingPoolAddressesProvider',
        name: 'provider',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'getFullReservesIncentiveData',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'underlyingAsset',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'incentiveIntialSupply',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveInflactionStart',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveDecayRatio',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentivesLastUpdateTimestamp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokenIncentivesIndex',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'precision',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.IncentiveData',
            name: 'sIncentiveData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'incentiveIntialSupply',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveInflactionStart',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveDecayRatio',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentivesLastUpdateTimestamp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokenIncentivesIndex',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'precision',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.IncentiveData',
            name: 'vdIncentiveData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'incentiveIntialSupply',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveInflactionStart',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveDecayRatio',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentivesLastUpdateTimestamp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokenIncentivesIndex',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'precision',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.IncentiveData',
            name: 'sdIncentiveData',
            type: 'tuple',
          },
        ],
        internalType:
          'struct UiIncentiveDataProvider.AggregatedReserveIncentiveData[]',
        name: '',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'underlyingAsset',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenincentivesUserIndex',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'userUnclaimedRewards',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.UserIncentiveData',
            name: 'STokenIncentivesUserData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenincentivesUserIndex',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'userUnclaimedRewards',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.UserIncentiveData',
            name: 'vdTokenIncentivesUserData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenincentivesUserIndex',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'userUnclaimedRewards',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.UserIncentiveData',
            name: 'sdTokenIncentivesUserData',
            type: 'tuple',
          },
        ],
        internalType:
          'struct UiIncentiveDataProvider.UserReserveIncentiveData[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ILendingPoolAddressesProvider',
        name: 'provider',
        type: 'address',
      },
    ],
    name: 'getReservesIncentivesData',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'underlyingAsset',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'incentiveIntialSupply',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveInflactionStart',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveDecayRatio',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentivesLastUpdateTimestamp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokenIncentivesIndex',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'precision',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.IncentiveData',
            name: 'sIncentiveData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'incentiveIntialSupply',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveInflactionStart',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveDecayRatio',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentivesLastUpdateTimestamp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokenIncentivesIndex',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'precision',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.IncentiveData',
            name: 'vdIncentiveData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'incentiveIntialSupply',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveInflactionStart',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentiveDecayRatio',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'incentivesLastUpdateTimestamp',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'tokenIncentivesIndex',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
              {
                internalType: 'uint8',
                name: 'precision',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.IncentiveData',
            name: 'sdIncentiveData',
            type: 'tuple',
          },
        ],
        internalType:
          'struct UiIncentiveDataProvider.AggregatedReserveIncentiveData[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ILendingPoolAddressesProvider',
        name: 'provider',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'getUserReservesIncentivesData',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'underlyingAsset',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenincentivesUserIndex',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'userUnclaimedRewards',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.UserIncentiveData',
            name: 'STokenIncentivesUserData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenincentivesUserIndex',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'userUnclaimedRewards',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.UserIncentiveData',
            name: 'vdTokenIncentivesUserData',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'tokenincentivesUserIndex',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'userUnclaimedRewards',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'incentiveControllerAddress',
                type: 'address',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
            ],
            internalType: 'struct UiIncentiveDataProvider.UserIncentiveData',
            name: 'sdTokenIncentivesUserData',
            type: 'tuple',
          },
        ],
        internalType:
          'struct UiIncentiveDataProvider.UserReserveIncentiveData[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
