import { messages as messagesEn } from "src/locales/en/messages";
import { messages as messagesZh } from "src/locales/zh/messages";
import { messages as messagesVi } from "src/locales/vi/messages";
import { messages as messagesKo } from "src/locales/ko/messages";
import { messages as messagesTr } from "src/locales/tr/messages";
import { messages as messagesId } from "src/locales/id/messages";
import { messages as messagesJa } from "src/locales/ja/messages";
import { messages as messagesRu } from "src/locales/ru/messages";

export type Locale = typeof SUPPORTED_LOCALES[number];

export const SUPPORTED_LOCALES = [
  "en",
  "zh",
  "vi",
  "ko",
  "tr",
  "id",
  "ja",
  "ru",
] as const;

export const DEFAULT_LOCALE: Locale = "en";

export const isSupportedLocale = (arg: any): arg is Locale =>
  SUPPORTED_LOCALES.includes(arg);

export const MESSAGES: Record<Locale, any> = {
  en: messagesEn,

  zh: messagesZh,
  vi: messagesVi,
  ko: messagesKo,
  tr: messagesTr,
  id: messagesId,
  ja: messagesJa,
  ru: messagesRu,
};

export const LOCALES_DICT: Record<Locale, string> = {
  en: "English",
  zh: "中文（简体）",
  vi: "Tiếng Việt",
  ko: "Korean",
  tr: "Türk",
  id: "bahasa Indonesia",
  ja: "日本語",
  ru: "Türk",
};
