import { useResponsive } from "ahooks";
import { useState } from "react";
import DesktopTable from "./DesktopTable";
import "./index.less";
import MobileTable from "./MobileTable";
import { t } from "@lingui/macro";
import { Pagination } from "antd";

export type columnProps = {
  title: string;
  label?: string;
  dataIndex?: string;
  width?: string;
  tooltip?: string;
  sort?: boolean;
  noShow?: string;

  render?: (text: string, record: Record<string, any>, index: number) => React.ReactNode;
};

export type TableListProps = {
  title: string;
  columns: columnProps[];
  dataSource: Record<string, any>[];
  disabledKey?: "deposited" | "borrowed";
  rowClick?: (item: Record<string, any>) => void;
  pagination?: {
    pageSize: number;
    page: number;
    totalCount: number;
    changePagination: (page: number, pageSize: number) => void;
  };
  stateMessage?: {
    text?: string;
    img?: string;
    render?: () => React.ReactNode;
  };
};

// The sort function can only be added to columns with the dataindex attribute

const TableList: React.FC<TableListProps> = (props) => {
  const [isHide, setIsHide] = useState(false);
  const responsive = useResponsive();
  return (
    <div className="table-list">
      <div className="table-title-wrap">
        <h3>{props.title}</h3>
        <div className="hide-wrap" onClick={() => setIsHide(!isHide)}>
          {isHide ? t`Show` : t`Hide`}
          <span className={isHide ? "show" : "hide"}></span>
        </div>
      </div>
      {isHide ? null : (
        <div className="table-main">
          {responsive.sm ? <DesktopTable {...props} /> : <MobileTable {...props} />}
          {props.dataSource.length && props.pagination ? (
            <Pagination
              simple
              pageSize={props.pagination.pageSize}
              current={props.pagination.page}
              total={props.pagination.totalCount || props.dataSource.length}
              onChange={props.pagination.changePagination}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default TableList;
