import { ethers } from "ethers";
import { metamaskConnector} from "./providers/metamask";
import { safeMultisigConnector } from "./providers/safe";
import { WalletType } from "./types";

export * as metamask from "./providers/metamask";
export * from "./types";

export const getConnector = (type: WalletType) => {
  switch (type) {
    case "Metamask":
      return metamaskConnector;
    case "Safe":
      return safeMultisigConnector
    default:
      return metamaskConnector;
  }
};

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(
    provider,
    typeof provider.chainId === "number"
      ? provider.chainId
      : typeof provider.chainId === "string"
      ? parseInt(provider.chainId)
      : "any"
  );
  return library;
};

/**
 * Add an ERC20 token to metamask assets menu.
 *
 * @param tokenSymbol Symbol for token (e.g. "BAI")
 * @param tokenAddress Address to the ERC20 token contract.
 * @param tokenImage Optional image URL of the token to display
 * @param tokenPrecision Optional decimal precision value for Collateral tokens
 */
export async function addERC20TokenToMetamask(
  tokenSymbol: string,
  tokenAddress: string,
  tokenPrecision?: number,
  tokenImage?: string
) {
  if (!window.ethereum) {
    console.error(
      `Metamask not available. Cannot add ${tokenSymbol} to Metamask`
    );
    return;
  }

  const ethereum = window.ethereum! as any;
  const watchAssetOptions = {
    address: tokenAddress, // The address that the token is at.
    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
    decimals: tokenPrecision ?? 18, // The number of decimals in the token
  };
  if (tokenImage) {
    // TODO: Add token images
    (watchAssetOptions as any).image = tokenImage; // A string url of the token logo
  }
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: watchAssetOptions,
      },
    });

    if (wasAdded) {
      console.log(`${tokenSymbol} added to Metamask`);
    } else {
      console.log(`${tokenSymbol} NOT added to Metamask`);
    }
  } catch (error) {
    console.log(error);
  }
}
