/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "Denetleyen",
    "Powered by": "Tarafından desteklenmektedir",
    "Please, connect your wallet": "Lütfen cüzdanınızı bağlayın",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      ' Mevcut ürünlerinizi, borçlarınızı ve açık pozisyonlarınızı görmek için lütfen cüzdanınızı bağlayın."',
    "Connect Wallet": "Cüzdanınızı bağlayın",
    "You need to change to a supported chain":
      "Desteklenen bir zincire geçmeniz gerekiyor",
    "Please switch the chain with your wallet.": "Lütfen ağınızı değiştirin",
    "Failed to switch the chain": "Zincir değiştirilemedi ",
    "In local-dev mode, only localhost:8545 network can be connected":
      "Yerel geliştirici modunda, yalnızca localhost:8545 ağı bağlanabilir",
    "SiO2 Finance is not yet depolyed to this network":
      "SiO2 Finance henüz bu ağda çalışmamaktadır",
    "Please switch to": "Lütfen geçiş yapın",
    "Change Network": "Ağınızı değiştirin",
    "No Data": "Data yok",
    Show: "Göstermek",
    Hide: "Saklamak",
    "Copy Success": "Kopyalama başarılır",
    "No account": "Hesap yok",
    Network: "Ağ",
    "Copy Address": "Adresi Kopyala",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "Cüzdanının bağlantısını kesin",
    Menu: "Menü",
    "Global Setting": "Global ayarlar",
    "Dark Mode": "Karanlık mod",
    "Select Language": "Dilinizi seçin",
    "Add Tokens": "Token’i ekleyin",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "SIO2 jetonunu herkese açık olarak piyasaya sürmedik, bu nedenle SIO2 jetonunun bir pazarı yok\nşu anda fiyat. Bu nedenle, APR'yi tahmini jeton fiyatına, $\n'ye göre hesaplıyoruz",
        ["SIO2_PRICE"],
        ". Daha fazla bilgi için lütfen belgelerimizi ziyaret edin.",
      ],
    Borrow: "Ödünç Al",
    Repay: "Geri Öde",
    Amount: "Miktar",
    Max: "Maksimum",
    "Transaction overview": "İşlemlere genel bakış",
    "Borrow APY": "Ödünç alma APY’si",
    "Borrow APR": "Ödünç alma APR’si ",
    Borrowed: "Ödünç alındı",
    "Borrow Limit Used": "Kullanılan Ödünç Alma Sınırına Ulaşılmadı",
    "Health Factor": "Sağlık Faktörünüz",
    Liquidity: "Likidite",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "Faiz ve değer sürekli dalgalandığından, maksimum değer\nmaks, almış olduğunuz tüm kredileri tam olarak ödeyemeyebilir ",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      "Josh burada! İlk hafta kristal madenimizin 'Kanarya Haftası'dır. Kullanıcıları, tüm piyasa koşullarına hazırlanmak için > 4 sağlık faktörünü korumaya teşvik ediyoruz. Daha fazla ayrıntı için <0>makaleyi</0> okuyun.",
    "Josh here!": "Josh here!",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      'Josh burada! İlk hafta kristal madenimizin "Kanarya Haftası"dır. Kullanıcıları, tüm piyasa koşullarına hazırlanmak için> 4 sağlık faktörünü korumaya teşvik ediyoruz. Daha fazla ayrıntı için <0>makaleyi</0> okuyun.',
    Collateral: "Teminat",
    ON: "ÜZERİNDE",
    OFF: "KAPALI",
    Collateral_Amount: "Teminat Miktarı",
    "Borrowed Available": "Alınabilir Borç",
    Disable: "Devre dışı bırak",
    Enable: "Aktif et",
    Deposited: "Yatırıldı",
    Supply: "Arz",
    Withdraw: "Çek",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "<1>Algem</1>'den <0>ASTR staking ödüllerini</0> almak için lütfen $nASTR'nizi Algem DApp içindeki <2>SiO2 adaptörü</2> aracılığıyla sağlayın.",
    "Supply APY": "Arzın APY’si",
    "Supply APR": "Arzın APRsi",
    "Borrow Available": 'Ödünç Alınabilir"',
    "This asset can't be used as collateral.":
      "Bu ürün teminat olarak kullanılamaz",
    "Wallet Balance": "Cüzdan Bakiyesi",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "İşlem onaylanırken faiz birikmeye devam edeceğinden, girilen maksimum sayı, işlem tamamlandığında kazandığınız toplamı yansıtmayabilir.",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      '"Faiz ve değer zaman zaman dalgalandığından, max\ndeğer tüm mevduatları tamamen ortadan kaldıramayabilir"',
    Dashboard: "Gösterge Paneli",
    Market: "Piyasa",
    Stake: "Stake",
    "Public Sale": "Halka açık satış",
    Faucet: "Musluk",
    "Transaction Preparing": "İşlem Hazırlama",
    "Waiting for transaction to be ready...":
      "İşlemin hazır olması bekleniyor...",
    "Confirm Transaction": "İşlemi Onayla",
    "Approve sending the ERC-20 asset to the pool.":
      "ERC-20 varlığının havuza gönderilmesini onaylayın.",
    "Transaction Pending": "İşlem Beklemede",
    "Waiting for the transaction to be confirmed...":
      "İşlemin onaylanmasını bekliyorum...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "Kredinizle ERC-20 varlıklarını ödünç almak için sözleşmeyi onaylayın.",
    "Confirm the transaction.": "İşlemi onaylayın.",
    "Transaction Submitted": "İşlem Gönderildi",
    "Succeeded!": "Başarılı!",
    "Your transaction confirmed!": "İşleminiz onaylandı!",
    "Transaction Canceled": "İşlem İptal Edildi",
    "You have canceled the transaction.": "İşlemi iptal ettiniz.",
    Error: "Hata",
    "Enter amount": "Miktarı girin",
    "No balance to deposit": "Yatırılacak bakiye yok",
    "No balance to withdraw": "Çekilecek bakiye yok",
    "Insufficient collateral": "Yetersiz teminat",
    "No liquidity to withdraw": "Çekilecek likidite yok",
    "Health factor too low": "Çekilecek likidite yok",
    "Borrowing limit reached": "Borçlanma limitine ulaşıldı",
    "No liquidity to borrow": "Borçlanacak likidite yok",
    "Not enough wallet balance to repay":
      "Geri ödemek için yeterli cüzdan bakiyesi yok",
    "No borrowed balance to repay": "Geri ödenecek borç bakiyesi yok",
    "Enter leverage": "Kaldıraç girin",
    "No balance to loop": "Döngü için bakiye yok",
    "Supply Balance": "Arz Bakiyesi",
    "Borrow Balance": "Borç Bakiyesi",
    Asset: "Varlık",
    "Reward APR": "Ödül APR’si",
    "Interest Rate": "Faiz Oranı",
    "Borrow Markets": "Borç alma piyassaları",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "Teşvikler dahil, sağlanan tüm varlıklar için APY'nin ağırlıklı ortalaması",
    Supplied: "Sağlanan Arz",
    "Supply Markets": "Arz Piyasaları",
    "Net APY": "Net APY",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "Net APY, teşvikler de dahil olmak üzere, tüm arz ve borçlanma pozisyonlarının net değer üzerindeki birleşik etkisidir. Borç APY'si arz APY'sinden yüksekse, negatif bir net APY'ye sahip olmak mümkündür.",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "Sağlık faktörünüz ve değer krediniz teminatınızın güvencesini belirler. Tasfiyeleri önlemek için kontrol edebilirsiniz",
    "1. supply more collateral": "1. daha fazla teminat sağlayın",
    "2. repay borrow positions": "2. ödünç alma pozisyonlarını geri ödeyin ",
    "When your health factor is below 1, your collateral will be liquidated.":
      "Sağlık faktörünüz 1'in altına düştüğünde teminatınız nakde çevrilecektir.",
    "RISK DETAILS": "Risk Detayları",
    Claim: "Claim",
    "Available SIO2 Rewards": "Mevcut SİO2 Ödülleri",
    "Learn more": "Fazlasını öğren",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      " SIO2 ödülleri her dakika tahakkuk etse de, kullanıcı arayüzündeki değer yalnızca arz ve borçlanma piyasalarıyla işlem yaptığınızda güncellenir. Bu nedenle, 'Claim' seçeneğine tıkladığınızda görüntülenenden daha fazla ödül alabilirsiniz",
    Name: "İsim",
    Action: "Eylem",
    Mint: "nane",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "Spam botları önlemek için testnet musluğumuz, her kullanıcı için varlık başına günde yalnızca bir mint yapılmasına izin verir.",
    "Total Supplied": "Toplam Arz",
    "Total Borrowed": "Total Borçlanma",
    "Supply Reward APR": "Arz Ödül APRsi",
    "Borrow Interest Rate": "Borçlanma Faiz Oranı",
    "Borrow Reward APR": "Borç Alma APRsi",
    Details: "Detaylar",
    Price: "Tutar",
    Borrowing: "Ödünç Alınıyor",
    Yes: "Evet",
    No: "Hayır",
    "Reserve Factor": "Reserv Faktörü",
    "Used as Collateral": "Teminat olarak kullan",
    "Loan to Value": " Kredi Değerlendirme",
    "Liquidation Threshold": "Tasfiye Eşiği",
    "Liquidation Penalty": "Tasfite Cezası",
    "Market Details": "Piyasa Detayları",
    "Asset Pools": "Varlık Havuzu",
    Others: "Diğerleri",
    total: "Toplam",
    "Top3 Markets": "İlk üç pazar",
    "Astar Network": "Astar Ağı",
    Token: "Token",
    "SIO2 Token(SIO2)": "SIO2 Simgesi (SIO2)",
    "Total Raised": "Total Yükseliş",
    "Sales Opened": "Satış açıldı",
    TBD: "TBD",
    "Sales Closed": "Satış Kapandı",
    "This page is developed for public sale on mainnet, but not testnet.":
      "Bu sayfa ana ağda genel satış için geliştirilmiştir, ancak testnet'te değil.",
    "SIO2 PUBLIC SALE": "SİO2 Ön Satışı",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      "Token Yayın Kuralları: 2 yıllık hak kazanma ve sonrasında 6 aylık vesting süreci\n",
    Purchase: "Satın Al",
    "Stake SIO2": "Stake SIO2",
    Staking: "Staking",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "SIO2 sahipleri, daha fazlasını eklemek için SIO2'lerini Güvenlik Modülüne alabilir\nprotokol güvenliği ve Güvenlik Teşvikleri kazanın.\n\" durumundaeksik olay, bahsinizin %30'una kadar\neksik, protokol için ek bir koruma katmanı sağlar.",
    "Total emission per day": "Günlük toplam emisyon",
    "Claim Rewards": "Ödüllerinizi Talep edin",
    "Funds in the Safety Module": "Güvenlik Modülündeki Fonlar ",
    "1 year": "1 yıl",
    "2 years": "2 yıl",
    "3 years": "3 yıl",
    "The amount you're trying to stake exceeds your balance by {0} SIO2": [
      "Stake etmeye çalıştığınız miktar, SIO2 ",
      ["0"],
      ' bakiyenizi SIO2 aşıyor"',
    ],
    "Locked Staking": "Kilitli Stakeler",
    Balance: "Bakiye",
    "Duration (years)": "Süre (Yıl) ",
    "Pool Share": "Havuzun Paylaşımı",
    Confirm: "Onayla",
    ID: "ID",
    "Staked Amount": "Stake edilen miktar",
    "Weighted Staked Amount": "Ağırlıklı Stake edilen miktar",
    "Date Time": "Tarih Saat",
    "Locked Until": "Şu zamana kadar kilitli",
    Redeem: "Tazmin etmek",
    "Locked Stakes": "Kilitli Stakeler",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      " Henüz kilitli ürününüz yok. SIO2 jetonlarınızı stake ederek başlayın ",
    Summary: "Özet",
    "Staking APR": "Staking APR’si",
    "Unweighted Stake": "Ağırlıksız Stake",
    "Weighted Stake": "Ağırlıklı Stake",
  },
};
