/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "Проверено",
    "Powered by": "Питаться от",
    "Please, connect your wallet": "Пожалуйста,подключите свой кошелёк",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      "Пожалуйста, подключите свой кошелек, чтобы увидеть свои запасы, займы и открытые позиции",
    "Connect Wallet": "Подключите кошелек",
    "You need to change to a supported chain":
      "Вам нужно перейти на поддерживаемую цепочку",
    "Please switch the chain with your wallet.":
      "Пожалуйста, поменяйте цепочку своим кошельком",
    "Failed to switch the chain": "Не удалось переключить цепочку",
    "In local-dev mode, only localhost:8545 network can be connected":
      "В режиме локального разработчика можно подключить только сеть localhost:8545",
    "SiO2 Finance is not yet depolyed to this network":
      "«SiO2 Finance еще не развернут в этой сети",
    "Please switch to": "Пожалуйста, переключитесь на",
    "Change Network": "Сменить сеть",
    "No Data": "Нет данных",
    Show: "показывать",
    Hide: "Спрятать",
    "Copy Success": "Копия успешна",
    "No account": "Нет акаунта",
    Network: "Сеть",
    "Copy Address": "Копирование адреса",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "Отключить кошелёк",
    Menu: "Меню",
    "Global Setting": "Глобальные настройки",
    "Dark Mode": "Темный режим",
    "Select Language": "Изменить язык",
    "Add Tokens": "Добавить токены",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "msgstrМы не запускали токен SIO2 публично, поэтому у токена SIO2 нет рынка\nЦена прямо сейчас. Поэтому мы рассчитываем APR на основе предполагаемой цены токена, $\n",
        ["SIO2_PRICE"],
        ". Дополнительную информацию см. в нашей документации",
      ],
    Borrow: "Занять",
    Repay: "Погашать",
    Amount: "Количество",
    Max: "Максимум",
    "Transaction overview": "Обзор сделки",
    "Borrow APY": "Занять APY",
    "Borrow APR": "Занять APR",
    Borrowed: "Заимствовать",
    "Borrow Limit Used": "Использован лимит заимствования",
    "Health Factor": "Фактор здоровья",
    Liquidity: "Ликвидность",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "Поскольку проценты и стоимость все время колеблются, максимальное значение\n«Максимум может быть не в состоянии полностью погасить все кредиты»",
    Collateral: "Залог",
    ON: "НА",
    OFF: "ВЫКЛЮЧЕННЫЙ",
    Collateral_Amount: "Залог_Сума",
    "Borrowed Available": "Заемные доступные",
    Disable: "Запрещать",
    Enable: "Включить",
    Deposited: "Депозит",
    Supply: "Ставка",
    Withdraw: "Выводить",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "Чтобы получить <0>вознаграждения за стейкинг ASTR</0> от <1>Algem</1>, укажите свой $nASTR через <2>адаптер SiO2</2> в приложении Algem DApp.",
    "Supply APY": "Ставка",
    "Supply APR": "Ставка APR",
    "Borrow Available": "Доступный кредит",
    "This asset can't be used as collateral.":
      "Этот актив не может быть использован в качестве залога",
    "Wallet Balance": "Баланс кошелька",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "Поскольку проценты будут продолжать накапливаться при подтверждении транзакции, введенное максимальное число может не отражать общую сумму, которую вы заработали после завершения транзакции.",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      "Поскольку проценты и стоимость время от времени колеблются, max\nзначение не может полностью удалить все депозиты",
    Dashboard: "Панель",
    Market: "Рынок",
    Stake: "Ставка",
    "Public Sale": "Публична продажа",
    Faucet: "Кран",
    "Transaction Preparing": "Подготовка сделки",
    "Waiting for transaction to be ready...":
      "Ожидание готовности транзакции...",
    "Confirm Transaction": "Подтвердить транзакцию",
    "Approve sending the ERC-20 asset to the pool.":
      "Подтвердите отправку актива ERC-20 в пул.",
    "Transaction Pending": "Ожидание транзакции",
    "Waiting for the transaction to be confirmed...":
      "Ожидание подтверждения транзакции...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "Утвердить договор о заимствовании активов ERC-20 в ваш кредит.",
    "Confirm the transaction.": "Подтвердите транзакцию.",
    "Transaction Submitted": "Транзакция отправлена",
    "Succeeded!": "Успешно",
    "Your transaction confirmed!": "Ваша транзакция подтверждена!",
    "Transaction Canceled": "Транзакция отменена",
    "You have canceled the transaction.": "Вы отменили транзакцию.",
    Error: "Ошибка",
    "Enter amount": "Введите сумму",
    "No balance to deposit": "Нет баланса для депозита",
    "No balance to withdraw": "Нет баланса для вывода",
    "Insufficient collateral": "Недостаточный залог",
    "No liquidity to withdraw": "Нет ликвидности для вывода",
    "Health factor too low": "Коэффициент здоровья слишком низкий",
    "Borrowing limit reached": "Достигнут лимит займа",
    "No liquidity to borrow": "Нет ликвидности для заимствования",
    "Not enough wallet balance to repay":
      "Недостаточно средств на кошельке для погашения",
    "No borrowed balance to repay": "Нет заемного остатка для погашения",
    "Enter leverage": "Введите кредитное плечо",
    "No balance to loop": "Нет баланса для цикла",
    "Supply Balance": "Баланс поставок ",
    "Borrow Balance": "Занять баланс",
    Asset: "Актив",
    "Reward APR": "Награды APR ( годовых)",
    "Interest Rate": "Процентная Ставка",
    "Borrow Markets": "Рынки взаимствований",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "Средневзвешенное значение APY для всех предоставленных активов, включая поощрения",
    Supplied: "Поставленный",
    "Supply Markets": "Рынки предложений",
    "Net APY": "Чистая APY”( чистая годовая ставка)",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "Чистая годовая доходность представляет собой комбинированный эффект всех позиций предложения и займа на чистую стоимость активов, включая льготы. Можно иметь отрицательную чистую APY, если долг APY выше, чем APY предложения",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "Фактор вашего здоровья и стоимость кредита определяют гарантию вашего залога. Избежать ликвидации можно",
    "1. supply more collateral": "1. предоставить больше залога",
    "2. repay borrow positions": "2. погасить занимаемые позиции",
    "When your health factor is below 1, your collateral will be liquidated.":
      "«Когда ваш коэффициент здоровья ниже 1, ваш залог будет ликвидирован",
    "RISK DETAILS": "Детали риска",
    Claim: "Утверждать",
    "Available SIO2 Rewards": "Доступные награды SIO2",
    "Learn more": "Учить больше ",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      "Хотя вознаграждения SIO2 начисляются каждую минуту, значение в пользовательском интерфейсе обновляется только тогда, когда вы совершаете операции на рынках предложения и заимствования. Поэтому вы можете получить больше вознаграждений, чем отображается при нажатии «Получить",
    Name: "Имя",
    Action: "Действие",
    Mint: "Монетный двор",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "Чтобы предотвратить спам-ботов, наш кран тестовой сети разрешает только один монетный двор для каждого актива в день для каждого пользователя",
    "Total Supplied": "Всего поставлено",
    "Total Borrowed": "Всего заимствовано",
    "Supply Reward APR": "Вознаграждение за ставку в год APR",
    "Borrow Interest Rate": "Процентная ставка по кредиту",
    "Borrow Reward APR": "Вознаграждение зал займу в год APR",
    Details: "Подробности",
    Price: "Цена",
    Borrowing: "Взаимствование",
    Yes: "Да",
    No: "Нет",
    "Reserve Factor": "Резервный фактор",
    "Used as Collateral": "Используется в качестве залога",
    "Loan to Value": "Кредит на стоимость",
    "Liquidation Threshold": "Порог ликвидации",
    "Liquidation Penalty": "Штраф за ликвидацию",
    "Market Details": "Детали рынка",
    "Asset Pools": "Пулы активов",
    Others: "Другие",
    total: "Общий",
    "Top3 Markets": "Топ 3 ринка",
    "Astar Network": "Астар Сеть",
    Token: "Токен",
    "SIO2 Token(SIO2)": "SIO2 Токен(SIO2)",
    "Total Raised": "Всего собрано",
    "Sales Opened": "Продажи открыты",
    TBD: "подлежит уточнению",
    "Sales Closed": "Продажи закрыты",
    "This page is developed for public sale on mainnet, but not testnet.":
      "Эта страница разработана для публичной продажи в основной сети, но не в тестовой сети",
    "SIO2 PUBLIC SALE": "Публичная продажа Sio2",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      "Правила выпуска токенов: 2 года наделения правами с 6-месячным обрывом после\nзапуск",
    Purchase: "Покупка",
    "Stake SIO2": "Ставка Sio2",
    Staking: "Ставка",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "Владельцы SIO2 могут поставить свои SIO2 в модуль безопасности, чтобы добавить больше\nбезопасность протокола и получать поощрения за безопасность. В случае\nсобытие дефицита, до 30% вашей ставки может быть урезано, чтобы покрыть\n«дефицит, обеспечивающий дополнительный уровень защиты протокола",
    "Total emission per day": "Общий выброс в день",
    "Claim Rewards": "Получить награды",
    "Funds in the Safety Module": "Средства в модуле безопасности",
    "1 year": "1 год",
    "2 years": "2 года",
    "3 years": "3 года",
    "The amount you're trying to stake exceeds your balance by {0} SIO2": [
      "Сумма, которую вы пытаетесь поставить, превышает ваш баланс на ",
      ["0"],
      " SIO2",
    ],
    "Locked Staking": "Заблокированный стейкинг",
    Balance: "Балаес",
    "Duration (years)": "Продолжительность (лет)",
    "Pool Share": "Общий бассейн",
    Confirm: "Подтверждать",
    L: "Заблокированные средства",
    ID: "ID",
    "Staked Amount": "Сумма ставки",
    "Weighted Staked Amount": "Взвешенная сумма ставок",
    "Date Time": "Дата Время",
    "Locked Until": "Заблокировано до",
    Redeem: "Выкупать",
    "Locked Stakes": "Закрытые ставки",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      "«У вас еще нет заблокированных ставок. Начните с размещения ваших токенов SIO2",
    "Stake locket": "Заблокированная Ставка",
    Summary: "Резюме",
    "Staking APR": "Годовая Ставка",
    "Unweighted Stake": "Невзвешенная Ставка",
    "Weighted Stake": "Взвещеная Ставка",
  },
};
