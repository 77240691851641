import { AssetSymbol } from "src/types/models";
import { EthereumAddress } from "src/types/web3";
import { ValueOf } from "type-fest";

export const CHAIN_ID = {
  astar: 592,
  shibuya: 81,
  dev: 31337,
} as const;

export type ChainId = ValueOf<typeof CHAIN_ID>;

export const isSupportedChain = (arg: any): arg is ChainId => {
  if (process.env.REACT_APP_ENV === "dev") {
    return arg === CHAIN_ID.dev;
  }
  if (process.env.REACT_APP_ENV === "shibuya") {
    return arg === CHAIN_ID.shibuya;
  }
  // return Object.values(CHAIN_ID).includes(arg);
  return arg === CHAIN_ID.astar;
};

export type NetworkConfig = {
  name: string;
  privateJsonRPCUrl?: string;
  privateJsonRPCWSUrl?: string;
  publicJsonRPCUrl: string[];
  publicJsonRPCWSUrl?: string;
  addresses: {
    walletBalanceProvider: EthereumAddress;
    uiPoolDataProvider: EthereumAddress;
    uiIncentiveDataProvider: EthereumAddress;
    stakeUiHelper: EthereumAddress;
    priceAggregatorAdapterAddress: EthereumAddress;
  };
  protocolDataUrl?: string;
  cachingServerUrl?: string;
  cachingWSServerUrl?: string;
  baseAsset: {
    symbol: AssetSymbol;
    wrapperAddress: EthereumAddress;
  };
  rewardToken: {
    symbol: string;
    address: EthereumAddress;
    underlyingAsset: EthereumAddress;
    decimals: number;
  };
  snapshotProvider?: {
    endpoint: string;
    apiKey?: string;
  };

  arthswapDataProvider?: {
    endpoint: string;
  };
  explorerLinks?: string[];
  rpcOnly?: boolean;
  isTestnet?: boolean;
};

const SHIBUYA_CONFIG: NetworkConfig = {
  name: "Shibuya Network",
  publicJsonRPCUrl: ["https://shibuya.public.blastapi.io"],
  addresses: {
    walletBalanceProvider: "0x8E221306c4992190dc70610D2CC655f58D1c2338",
    uiPoolDataProvider: "0x5c2aC460f13CF257266413a711B7dAA6DCFB11B1",
    uiIncentiveDataProvider: "0x80316BE51fa5157ef7791E2E214fFb61eeF9DeFe",
    stakeUiHelper: "0xdd9e20F18214c0E5C8002707d9df31372C3fb8c9",
    priceAggregatorAdapterAddress: "0x9cE8a6795A2F268D803cc51AEe1e046D679e2918",
  },
  baseAsset: {
    symbol: "SBY",
    wrapperAddress: "0x8Fd43fea01125EcA2bEb0bB03509946ECA99eEf9",
  },
  rewardToken: {
    symbol: "stkSiO2",
    address: "0xE73d66ba54c491b98041cb62C791361536d1cB1a",
    underlyingAsset: "0x76948c31A88496323fEC78B00C68D4b7b612bFE1",
    decimals: 18,
  },
  snapshotProvider: {
    endpoint:
      "https://xok6alsasvgthgl5ozss7upnuu.appsync-api.us-east-1.amazonaws.com/graphql",
    apiKey: "da2-qvw2m7hf6jhonms4rhtqgix7ri",
  },

  arthswapDataProvider: {
    endpoint: "https://arthswap-apr-api.vercel.app/api/graphql",
  },
  explorerLinks: ["https://blockscout.com/shibuya"],
};

export const NETWORK_CONFIG: Record<ChainId, NetworkConfig> = {
  [CHAIN_ID.astar]: {
    name: "Astar Network",
    publicJsonRPCUrl: ["https://evm.astar.network"],
    addresses: {
      walletBalanceProvider: "0xd5BA2601c12456f16401B9DeF0226032d9dbeB3b",
      uiPoolDataProvider: "0xF0a90426292B4d1BA69F77B5B0cCB90474324ab8",
      uiIncentiveDataProvider: "0x9bcfaed879Df0d6Fe2Cc5059D84Cc67F13B31cF9",
      stakeUiHelper: "0x89B2417791b9f935b1fB9C2483cB14Cd662F3908",
      priceAggregatorAdapterAddress:
        "0x5f7c3639A854a27DFf64B320De5C9CAF9C4Bd323",
    },
    baseAsset: {
      symbol: "ASTR",
      wrapperAddress: "0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720",
    },
    rewardToken: {
      symbol: "stkSiO2",
      address: "0xEabE3E7F94F9471A252819F8DC9d1F30c355E902",
      underlyingAsset: "0xcCA488aEEf7A1D5C633f877453784F025e7cF160",
      decimals: 18,
    },
    snapshotProvider: {
      endpoint:
        "https://xok6alsasvgthgl5ozss7upnuu.appsync-api.us-east-1.amazonaws.com/graphql",
      apiKey: "da2-qvw2m7hf6jhonms4rhtqgix7ri",
    },

    arthswapDataProvider: {
      endpoint: "https://arthswap-apr-api.vercel.app/api/graphql",
    },
    explorerLinks: ["https://blockscout.com/astar"],
  },
  [CHAIN_ID.shibuya]: SHIBUYA_CONFIG,
  [CHAIN_ID.dev]: {
    ...SHIBUYA_CONFIG,
    name: "local dev Network",
    publicJsonRPCUrl: ["http://localhost:8545"],
  },
};
