import { ChainId } from "src/libs/config";
import {
  stakeUiHelperContract,
  StakeUiHelperInterface,
} from "src/libs/stake-ui-helper";
import { StakeData } from "src/types/models";
import { SWRResponseWithFallback } from "src/types/swr";
import { EthereumAddress } from "src/types/web3";
import { BN_ZERO } from "src/utils/number";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useStaticRPCProvider } from "./useStaticRPCProvider";
import { useWallet } from "./useWallet";
import { StakingInterface } from "submodule/packages/contract-helpers/dist/cjs/staking-contract/index";
import { StakeInfosByPage } from "submodule/packages/contract-helpers/dist/cjs/staking-contract/types";
import { stakenContract } from "../libs/staken";

export const ORGIN_PAGINATION = {
  page: 0,
  pageSize: 10,
  allPageSize: 1000,
};

const EMPTY_STAKE_DATA: StakeData = {
  stakeApy: BN_ZERO,
  stakeTokenTotalSupply: BN_ZERO,
  stakeTokenUserBalance: BN_ZERO,
  distributionPerDay: BN_ZERO,
  userIncentivesToClaim: BN_ZERO,
};

const EMPTY_STAKE_PAGE: StakeInfosByPage = {
  pageSt: "0",
  pageInfo: [],
};

export const useStakeData = () => {
  const { account } = useWallet();
  const { data: provider } = useStakeUiHelper();
  return useSWR(
    () => account && provider && ["stakedata", provider.chainId, account],
    (_key: string, _chainId: ChainId, account: EthereumAddress) =>
      getUnclaimedBalance(provider!.provider, account),
    { fallbackData: EMPTY_STAKE_DATA }
  ) as SWRResponseWithFallback<StakeData>;
};

const useStakeUiHelper = () => {
  const { data: provider } = useStaticRPCProvider();
  return useSWRImmutable(
    provider && ["stakeuihelper", provider.chainId],
    () => ({
      chainId: provider!.chainId,
      provider: stakeUiHelperContract(provider!),
    })
  );
};

const useStakeInfo = () => {
  const { data: provider } = useStaticRPCProvider();

  return useSWRImmutable(
    provider && ["stakeInfo", provider.chainId],
    (_key: string, _chainId: ChainId) => ({
      chainId: provider!.chainId,
      provider: stakenContract(provider!),
    })
  );
};

const getUnclaimedBalance = async (
  provider: StakeUiHelperInterface,
  account: EthereumAddress
): Promise<StakeData> => provider.getUserUIDataHumanized(account);

export const useStakeInfoPage = (pageSt?: string, pageSz?: string) => {
  const { account } = useWallet();
  const { data: provider } = useStakeInfo();
  return useSWR(
    () => account && provider && ["stakeInfoPage", provider.chainId, account],
    (_key: string, _chainId: ChainId, account: EthereumAddress) => {
      return getStakeInfoPage(provider!.provider, account, pageSt, pageSz);
    },

    { fallbackData: EMPTY_STAKE_PAGE }
  ) as SWRResponseWithFallback<StakeInfosByPage>;
};

const getStakeInfoPage = async (
  provider: StakingInterface,
  account: EthereumAddress,
  pageSt: string = ORGIN_PAGINATION.page.toString(),
  pageSz: string = ORGIN_PAGINATION.allPageSize.toString()
): Promise<StakeInfosByPage> => {
  let data = await provider.getStateInfosByPage(account, pageSt, pageSz);

  return {
    pageSt: data.pageSt,
    pageInfo: data.pageInfo?.map((item: any) => {
      return {
        ...item,
        veToken: (item.amount * Math.pow(2, item.mode)).toString(),
      };
    }),
  };
};

export const useStakeInfoSize = () => {
  const { account } = useWallet();
  const { data: provider } = useStakeInfo();
  return useSWR(
    () => account && provider && ["stakeInfoSz", provider.chainId, account],
    (_key: string, _chainId: ChainId, account: EthereumAddress) =>
      getStakeInfoSz(provider!.provider, account),
    { fallbackData: "0" }
  ) as SWRResponseWithFallback<string>;
};

const getStakeInfoSz = async (
  provider: StakingInterface,
  account: EthereumAddress
): Promise<string> => (await provider.getStakeInfoSize(account)).toString();
