import "./index.less";
import { ReactComponent as ImgPeck } from "@img/footer-peck.svg";
import { ReactComponent as ImgAstar } from "@img/footer-astar.svg";
import { useAppSetting } from "@/hook/app/useAppSetting";
import { t } from "@lingui/macro";
const Footer: React.FC = () => {
  const { settingData } = useAppSetting();
  return (
    <div className="footer-wrap">
      <div className="footer-content">
        <div className="footer-item">
          {t`Audited by`}{" "}
          <a
            target="_blank"
            href="https://github.com/SiO2-Finance/contracts/blob/main/audits/PeckShield-Audit-Report-SiO2%20(08-04-2022).pdf"
            className="footer-unit"
            rel="noreferrer"
          >
            {settingData?.theme === "dark" ? (
              <ImgPeck />
            ) : (
              <img src={require("@img/footer-peck-white.png")} alt="12" />
            )}
          </a>
        </div>
        <div className="footer-item">
          {t`Powered by`}{" "}
          <div className="footer-unit">
            {settingData?.theme === "dark" ? (
              <ImgAstar />
            ) : (
              <img src={require("@img/footer-astar-white.png")} alt="12" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
