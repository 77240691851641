import React from "react";
import { Button, Input } from "antd";
import Link from "antd/lib/typography/Link";
import "./index.less";
import { useLocation } from "react-router-dom";
const NotificationBar: React.FC<{ noBusdIcon?: boolean }> = ({ noBusdIcon }) => {
  const { pathname } = useLocation();
  const content = {
    title: <h3>Important notice for $BUSD users:</h3>,
    label: (
      <p>
        With{" "}
        <Link
          target="_blank"
          href="https://www.binance.com/en/support/announcement/notice-regarding-the-removal-of-busd-and-conversion-of-busd-to-fdusd-1c98ce7bb464422dbbaeda7066ae445b?ref=AZTKZ9XS&utm_source=BinanceTwitter&utm_medium=GlobalSocial&utm_campaign=GlobalSocial"
        >
          Binance ceasing support for BUSD on December 15, 2023
        </Link>
        , we will also gradually cease our BUSD lending service in response. You can convert BUSD into other available
        assets on Binance.{" "}
      </p>
    ),
  };
  // const repayContent = {
  //   title: <h3>Important Update: SiO2 Encourages Users to Withdraw and Repay BUSD Prior to December 15, 2023</h3>,
  //   label: (
  //     <p>
  //       With{" "}
  //       <Link
  //         target="_blank"
  //         href="https://www.binance.com/en/support/announcement/notice-regarding-the-removal-of-busd-and-conversion-of-busd-to-fdusd-1c98ce7bb464422dbbaeda7066ae445b?ref=AZTKZ9XS&utm_source=BinanceTwitter&utm_medium=GlobalSocial&utm_campaign=GlobalSocial"
  //       >
  //         Binance ceasing support for BUSD on December 15, 2023
  //       </Link>
  //       , we will also gradually cease our BUSD lending service in response. You can convert BUSD into other available
  //       assets on Binance.Alternatively, you also have the option to hold onto BAI.
  //     </p>
  //   ),
  // };
  const activeItem = content;
  return (
    <div className="notification-bar">
      <img className="icon-warning" src={require("@img/notification/icon-warning.png")} alt="" />
      <div className="label-wrap">
        {activeItem.title}
        {activeItem.label}
      </div>
      {noBusdIcon && pathname !== "/market" ? null : (
        <img className="icon-busd" src={require("@img/notification/icon-busd.png")} alt="" />
      )}
    </div>
  );
};
export default NotificationBar;
