import { DEFAULT_GAS_PRICE, GAS_ESTIMATOR_POLL_INTERVAL, GAS_PRICE_SPEED } from "@/constants/config";
import { CHAIN_ID } from "@/libs/config/network";
import { BigNumber } from "ethers";
import { ChainId } from "src/libs/config";

import useSWR from "swr";
import { useStaticRPCProvider } from "./useStaticRPCProvider";

const isProd = process.env.REACT_APP_ENV === "prod";
const GAS_PRICE_URL = isProd ? "https://gas.astar.network/api/gasnow" : "https://evm.shibuya.astar.network";

export interface EstimatedGasPrice {
  timestamp: number;
  fastest: string;
  fast: string;
  slow: string;
  tip: { slow: string; fast: string; average: string };
  eip1559: {
    baseFeePerGas: string;
    priorityFeePerGas: { average: string; fast: string; slow: string };
    maxFeePerGas: string;
    maxPriorityFeePerGas: string;
  };
  average: string;
}
interface EstimatedGasPriceResponse {
  code: number;
  data: EstimatedGasPrice;
}

export const getGasPriceByChainId = async (
  chainId: ChainId
): Promise<{ maxFeePerGas: string; maxPriorityFeePerGas: string }> => {
  const network = CHAIN_ID.astar === chainId ? "astar" : "shibuya";

  const response = await window.fetch(`${GAS_PRICE_URL}?network=${network}`, {
    method: "GET",
    redirect: "follow",
  });

  if (!response.ok) {
    return Promise.reject(`${response.status} ${response.statusText}`);
  }

  if (response.status === 200) {
    const estimatedGasPriceResponse = (await response.json()) as EstimatedGasPriceResponse;
    console.log(estimatedGasPriceResponse, "estimatedGasPriceResponse");
    return {
      maxFeePerGas: estimatedGasPriceResponse.data.eip1559.maxFeePerGas,
      maxPriorityFeePerGas: estimatedGasPriceResponse.data.eip1559.maxPriorityFeePerGas,
    };
    // return BigNumber.from(estimatedGasPriceResponse.data[GAS_PRICE_SPEED]);
  } else {
    return Promise.reject("Oops! Something went wrong!");
  }

  // const response = await window.fetch(GAS_PRICE_URL, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     method: "eth_gasPrice",
  //     params: [],
  //     id: 1,
  //     jsonrpc: "2.0",
  //   }),
  // });

  // if (!response.ok) {
  //   return Promise.reject(`${response.status} ${response.statusText}`);
  // }

  // if (response.status === 200) {
  //   const estimatedGasPriceResponse = await response.json();

  //   console.log(BigNumber.from(estimatedGasPriceResponse.result).toString(), "getGasPriceAstar");
  //   console.log(BigNumber.from(estimatedGasPriceResponse.result).mul(120).div(100).toString(), "getGasPriceAstar");
  //   return BigNumber.from(estimatedGasPriceResponse.result).mul(120).div(100);
  // } else {
  //   return Promise.reject("Oops! Something went wrong!");
  // }
};

export const useGasPrice = () => {
  const { data } = useStaticRPCProvider();

  return useSWR(() => data?.chainId, getGasPriceByChainId, {
    refreshInterval: GAS_ESTIMATOR_POLL_INTERVAL,
    fallback: DEFAULT_GAS_PRICE.MIN,
  });
};
