import { EthereumAddress } from "@/types/web3";
import { ValueOf } from "type-fest";
import { ChainId, CHAIN_ID } from "./network";

export const CUSTOM_MARKET = {
  proto_astar: "proto_astar",
  proto_shibuya: "proto_shibuya",
  proto_dev: "proto_dev",
} as const;
export type CustomMarket = ValueOf<typeof CUSTOM_MARKET>;

export type MarketDataType = {
  chainId: ChainId;
  enabledFeatures?: {
    liquiditySwap?: boolean;
    staking?: boolean;
    governance?: boolean;
    faucet?: boolean;
    collateralRepay?: boolean;
    incentives?: boolean;
    permissions?: boolean;
  };
  addresses: {
    LENDING_POOL_ADDRESS_PROVIDER: EthereumAddress;
    LENDING_POOL: EthereumAddress;
    INCENTIVES_CONTROLLER: EthereumAddress;
    WETH_GATEWAY?: EthereumAddress;
    SWAP_COLLATERAL_ADAPTER?: EthereumAddress;
    REPAY_WITH_COLLATERAL_ADAPTER?: EthereumAddress;
    FAUCET?: EthereumAddress;
    PERMISSION_MANAGER?: EthereumAddress;
    LEVERAGER?: EthereumAddress;
    LAUNCHPAD?: EthereumAddress;
  };
};

const SHIBUYA_CONFIG: MarketDataType = {
  chainId: CHAIN_ID.shibuya,
  enabledFeatures: {
    incentives: true,
  },
  addresses: {
    LENDING_POOL_ADDRESS_PROVIDER:
      "0xA3e2cc02E3b670Cb921861eD9109D2078705D6aF",
    INCENTIVES_CONTROLLER: "0x9dbb6A041349989D7c192CaE073FF8faF7dcAf78",
    LENDING_POOL: "0xCEE4Bd92d09AbE83704e12FC867FAC734a9aC8F1",
    WETH_GATEWAY: "0xE2D4c936c35f2d762204a26bB38DBb119dDF6C5F",
    LEVERAGER: "0x00",
  },
};

export const MARKET_CONFIGS: {
  [key in CustomMarket]: MarketDataType;
} = {
  [CUSTOM_MARKET.proto_astar]: {
    chainId: CHAIN_ID.astar,
    enabledFeatures: {
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER:
        "0x2660e0668dd5A18Ed092D5351FfF7B0A403f9721",
      INCENTIVES_CONTROLLER: "0xc41e6Da7F6E803514583f3b22b4Ff660CCD39B03",
      LENDING_POOL: "0x4df48B292C026f0340B60C582f58aa41E09fF0de",
      WETH_GATEWAY: "0x4424045DA06d5F71EcAA730fdAafEEd59b9825B0",
      LEVERAGER: "0x00",
    },
  },
  [CUSTOM_MARKET.proto_shibuya]: SHIBUYA_CONFIG,
  [CUSTOM_MARKET.proto_dev]: { ...SHIBUYA_CONFIG, chainId: CHAIN_ID.dev },
} as const;

export const CHAIN_MARKET_CONFIG: {
  [key in ChainId]: MarketDataType;
} = {
  [CHAIN_ID.astar]: MARKET_CONFIGS.proto_astar,
  [CHAIN_ID.shibuya]: MARKET_CONFIGS.proto_shibuya,
  [CHAIN_ID.dev]: MARKET_CONFIGS.proto_dev,
} as const;
