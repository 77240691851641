/* Autogenerated file. Do not edit manually. */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';
import type { Leverager } from './Leverager';

const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'pool',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_wastr',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
    ],
    name: 'close',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'getAvailableBorrows',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalCollateral',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'availableBorrows',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'priceASTR',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'available',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_ltv',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'hf',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
    ],
    name: 'getConfiguration',
    outputs: [
      {
        internalType: 'uint256',
        name: 'data',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
    ],
    name: 'getSToken',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
    ],
    name: 'getVDToken',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'lendingPool',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'interestRateMode',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'borrowRatio',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'loopCount',
        type: 'uint256',
      },
    ],
    name: 'loop',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'interestRateMode',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'borrowRatio',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'loopCount',
        type: 'uint256',
      },
    ],
    name: 'loopASTR',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
    ],
    name: 'ltv',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'asset',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'stoken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'interestRateMode',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'loopCount',
        type: 'uint256',
      },
    ],
    name: 'reverseLoop',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'lwastr',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'interestRateMode',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'loopCount',
        type: 'uint256',
      },
    ],
    name: 'reverseLoopASTR',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wastr',
    outputs: [
      {
        internalType: 'contract IWASTR',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'withdrawable',
    outputs: [
      {
        internalType: 'uint256',
        name: 'totalCollateral',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'totalDebt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'currentLiquidationThreshold',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'hf',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'surplusedHF',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'limitCollateral',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'withdrawableCollateral',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export class Leverager__factory {
  static readonly abi = _abi;
  static connect(
    address: string,
    signerOrProvider: Signer | Provider,
  ): Leverager {
    return new Contract(address, _abi, signerOrProvider) as Leverager;
  }
}
