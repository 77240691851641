/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "Audited by",
    "Powered by": "Powered by",
    "Please, connect your wallet": "Please, connect your wallet",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      "Please connect your wallet to see your supplies, borrowings, and open positions.",
    "Connect Wallet": "Connect Wallet",
    "You need to change to a supported chain":
      "You need to change to a supported chain",
    "Please switch the chain with your wallet.":
      "Please switch the chain with your wallet.",
    "Failed to switch the chain": "Failed to switch the chain",
    "In local-dev mode, only localhost:8545 network can be connected":
      "In local-dev mode, only localhost:8545 network can be connected",
    "SiO2 Finance is not yet depolyed to this network":
      "SiO2 Finance is not yet depolyed to this network",
    "Please switch to": "Please switch to",
    "Change Network": "Change Network",
    "No Data": "No Data",
    Show: "Show",
    Hide: "Hide",
    show: "show",
    hide: "hide",
    "Copy Success": "Copy Success",
    "No account": "No account",
    Network: "Network",
    "Copy Address": "Copy Address",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "Disconnect Wallet",
    Menu: "Menu",
    "Global Setting": "Global Setting",
    "Dark Mode": "Dark Mode",
    "Select Language": "Select Language",
    "Add Tokens": "Add Tokens",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n",
        ["SIO2_PRICE"],
        ". For more information, please visit our documentation.",
      ],
    Borrow: "Borrow",
    Repay: "Repay",
    Amount: "Amount",
    Max: "Max",
    "Transaction overview": "Transaction overview",
    "Borrow APY": "Borrow APY",
    "Borrow APR": "Borrow APR",
    Borrowed: "Borrowed",
    "Borrow Limit Used": "Borrow Limit Used",
    "Health Factor": "Health Factor",
    Liquidity: "Liquidity",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.',
    "Josh here!":
      'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.',
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.',
    "{0}": [["0"]],
    Collateral: "Collateral",
    ON: "ON",
    OFF: "OFF",
    Collateral_Amount: "Collateral_Amount",
    "Borrowed Available": "Borrowed Available",
    Disable: "Disable",
    Enable: "Enable",
    Deposited: "Deposited",
    Supply: "Supply",
    Withdraw: "Withdraw",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.",
    "Supply APY": "Supply APY",
    "Supply APR": "Supply APR",
    "Borrow Available": "Borrow Available",
    "This asset can't be used as collateral.":
      "This asset can't be used as collateral.",
    "Wallet Balance": "Wallet Balance",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits",
    Dashboard: "Dashboard",
    Market: "Market",
    Stake: "Stake",
    "Public Sale": "Public Sale",
    Faucet: "Faucet",
    "Transaction Preparing": "Transaction Preparing",
    "Waiting for transaction to be ready...":
      "Waiting for transaction to be ready...",
    "Confirm Transaction": "Confirm Transaction",
    "Approve sending the ERC-20 asset to the pool.":
      "Approve sending the ERC-20 asset to the pool.",
    "Transaction Pending": "Transaction Pending",
    "Waiting for the transaction to be confirmed...":
      "Waiting for the transaction to be confirmed...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "Approve the contract to borrow ERC-20 assets on your credit.",
    "Confirm the transaction.": "Confirm the transaction.",
    "Transaction Submitted": "Transaction Submitted",
    "Succeeded!": "Succeeded!",
    "Your transaction confirmed!": "Your transaction confirmed!",
    "Transaction Canceled": "Transaction Canceled",
    "You have canceled the transaction.": "You have canceled the transaction.",
    Error: "Error",
    "Enter amount": "Enter amount",
    "No balance to deposit": "No balance to deposit",
    "No balance to withdraw": "No balance to withdraw",
    "Insufficient collateral": "Insufficient collateral",
    "No liquidity to withdraw": "No liquidity to withdraw",
    "Health factor too low": "Health factor too low",
    "Borrowing limit reached": "Borrowing limit reached",
    "No liquidity to borrow": "No liquidity to borrow",
    "Not enough wallet balance to repay": "Not enough wallet balance to repay",
    "No borrowed balance to repay": "No borrowed balance to repay",
    "Enter leverage": "Enter leverage",
    "No balance to loop": "No balance to loop",
    "Supply Balance": "Supply Balance",
    "Borrow Balance": "Borrow Balance",
    Asset: "Asset",
    "Reward APR": "Reward APR",
    "Interest Rate": "Interest Rate",
    "Borrow Markets": "Borrow Markets",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "The weighted average of APY for all supplied assets,including incentives",
    Supplied: "Supplied",
    "Supply Markets": "Supply Markets",
    "Net APY": "Net APY",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can",
    "1. supply more collateral": "1. supply more collateral",
    "2. repay borrow positions": "2. repay borrow positions",
    "When your health factor is below 1, your collateral will be liquidated.":
      "When your health factor is below 1, your collateral will be liquidated.",
    "RISK DETAILS": "RISK DETAILS",
    Claim: "Claim",
    "Available SIO2 Rewards": "Available SIO2 Rewards",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.",
    "Learn more": "Learn more",
    Name: "Name",
    Action: "Action",
    Mint: "Mint",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.",
    "Total Supplied": "Total Supplied",
    "Total Borrowed": "Total Borrowed",
    "Supply Reward APR": "Supply Reward APR",
    "Borrow Interest Rate": "Borrow Interest Rate",
    "Borrow Reward APR": "Borrow Reward APR",
    Details: "Details",
    Price: "Price",
    Borrowing: "Borrowing",
    Yes: "Yes",
    No: "No",
    "Reserve Factor": "Reserve Factor",
    "Used as Collateral": "Used as Collateral",
    "Loan to Value": "Loan to Value",
    "Liquidation Threshold": "Liquidation Threshold",
    "Liquidation Penalty": "Liquidation Penalty",
    "Market Details": "Market Details",
    "Asset Pools": "Asset Pools",
    Detail: "Details",
    Others: "Others",
    total: "total",
    "Top3 Markets": "Top3 Markets",
    "Astar Network": "Astar Network",
    Token: "Token",
    "SIO2 Token(SIO2)": "SIO2 Token(SIO2)",
    "Total Raised": "Total Raised",
    "Sales Opened": "Sales Opened",
    TBD: "TBD",
    "Sales Closed": "Sales Closed",
    "This page is developed for public sale on mainnet, but not testnet.":
      "This page is developed for public sale on mainnet, but not testnet.",
    "SIO2 PUBLIC SALE": "SIO2 PUBLIC SALE",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch",
    Purchase: "Purchase",
    "$ {0}": ["$ ", ["0"]],
    "Stake SIO2": "Stake SIO2",
    Staking: "Staking",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.",
    "Total emission per day": "Total emission per day",
    "Claim Rewards": "Claim Rewards",
    "Funds in the Safety Module": "Funds in the Safety Module",
    "1 year": "1 year",
    "2 years": "2 years",
    "3 years": "3 years",
    "The amount you're trying to stake exceeds your balance by {0} SIO2": [
      "The amount you're trying to stake exceeds your balance by ",
      ["0"],
      " SIO2",
    ],
    "Locked Staking": "Locked Staking",
    Balance: "Balance",
    "Duration (years)": "Duration (years)",
    "Pool Share": "Pool Share",
    Confirm: "Confirm",
    ID: "ID",
    "Staked Amount": "Staked Amount",
    "Weighted Staked Amount": "Weighted Staked Amount",
    "Date Time": "Date Time",
    "Locked Until": "Locked Until",
    Redeem: "Redeem",
    "Locked Stakes": "Locked Stakes",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      "You don't have any locked stakes yet. Start by staking your SIO2 tokens.",
    Summary: "Summary",
    "Staking APR": "Staking APR",
    "Unweighted Stake": "Unweighted Stake",
    "Weighted Stake": "Weighted Stake",
  },
};
