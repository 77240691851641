import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import MessageModal from "@/component/modal/MessageModal";
import BorrowModal from "@/component/modal/dashboard/BorrowModal";
import SupplyModal from "@/component/modal/dashboard/SupplyModal";
import DashboardClaimModal from "@/component/modal/dashboard/ClaimModal";

import CollateralModal from "@/component/modal/dashboard/CollateralModal";
import PurchaseModal from "@/component/modal/public/PurchaseModal";
import WrapModal from "@/component/modal/WrapModal";
import { useWallet } from "../useWallet";
import { isSupportedChain } from "@/libs/config";
import { DEFAULT_CHAIN_ID } from "@/utils/env";
import type { MessageType, MessageData } from "@/component/modal/MessageModal";
import { t } from "@lingui/macro";
import ConnectWalletModal from "@/component/modal/ConnectWalletModal";

export type ModalType =
  | "deposit"
  | "withdraw"
  | "borrow"
  | "repay"
  | "collateral"
  | "dashboardClaim"
  | "purchase";

export type ModalContextType = {
  messageVisible: boolean;
  setMessageVisible: React.Dispatch<React.SetStateAction<boolean>>;
  openMessage: (data: any) => void;
  messageData: MessageData;

  contentVisible: boolean;
  setContentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  openContent: (data: any) => void;

  args: any;
  setArgs: React.Dispatch<React.SetStateAction<any>>;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType
);

export const ModalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [messageVisible, setMessageVisible] = useState(false);
  const [messageData, setMessageData] = useState<MessageData>({
    type: "" as MessageType,
    title: "",
    message: "",
  });
  const [contentVisible, setContentVisible] = useState(false);
  const [args, setArgs] = useState<any>({});
  const { chainId, switchChain } = useWallet();
  const isUnsupportedChain = useMemo(
    () => chainId && !isSupportedChain(chainId),
    [chainId]
  );

  useEffect(() => {
    if (isUnsupportedChain) {
      setContentVisible(false);
    }
  }, [isUnsupportedChain]);

  const closeModal = () => {
    setMessageVisible(false);

    setArgs({});
    setContentVisible(false);
  };
  const openMessage = (data: any) => {
    setMessageData(data);
    setMessageVisible(true);
  };
  const openContent = (data: any) => {
    if (isUnsupportedChain) {
      handleUnSupportChain();
      return;
    }

    setArgs(data);
    setContentVisible(true);
  };

  const handleUnSupportChain = () => {
    openMessage({
      type: "warning",
      title: t`You need to change to a supported chain`,
      message: t`Please switch the chain with your wallet.`,
    });
    if (switchChain) {
      switchChain(DEFAULT_CHAIN_ID).then(({ error }) => {
        if (!error) {
          setMessageVisible(false);
          return;
        }
        openMessage({
          title: `Failed to switch the chain`,
          message: error,
          type: "error",
        });
      });
    }
  };

  return (
    <ModalContext.Provider
      value={{
        messageVisible,
        setMessageVisible,
        openMessage,
        messageData,
        contentVisible,
        setContentVisible,
        openContent,

        args,
        setArgs,
        closeModal,
      }}
    >
      {children}
      <WrapModal>
        {args.type === "borrow" || args.type === "repay" ? (
          <BorrowModal></BorrowModal>
        ) : null}
        {args.type === "deposit" || args.type === "withdraw" ? (
          <SupplyModal></SupplyModal>
        ) : null}
        {args.type === "collateral" ? (
          <CollateralModal></CollateralModal>
        ) : null}
        {args.type === "dashboardClaim" ? (
          <DashboardClaimModal></DashboardClaimModal>
        ) : null}
        {args.type === "purchase" ? <PurchaseModal></PurchaseModal> : null}
        {args.type === "connectWallet" ? <ConnectWalletModal></ConnectWalletModal> : null}
      </WrapModal>
      <MessageModal></MessageModal>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }

  return context;
};
