import { useModalContext } from "@/hook/app/useModalContext";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import "./index.less";
import {
  SymbolWalletPolkadot,
  SymbolWalletTalisman,
  SymbolWalletSubWallet,
} from "src/assets/img/symbols/symbols";
const ConnectWalletModal: React.FC = () => {
  const { closeModal } = useModalContext();

  return (
    <div className="modal-body modal-borrow">
      <div className="close-wrap" onClick={closeModal}>
        <CloseOutlined />
      </div>
      <div className="title-wrap">
        <h3>Connect Wallet</h3>
      </div>

      <Space
        className="wallet-option-buttons"
        direction="vertical"
        style={{ width: "100%" }}
      >
        <Button size={"large"} type="text" block>
          <img height={16} src={SymbolWalletPolkadot} alt="" />
          Polkadot.js
        </Button>
        <Button size={"large"} type="text" block>
          <img height={16} src={SymbolWalletTalisman} alt="" />
          Talisman
        </Button>
        <Button size={"large"} type="text" block>
          <img height={16} src={SymbolWalletSubWallet} alt="" />
          SubWallet
        </Button>
      </Space>
    </div>
  );
};

export default ConnectWalletModal;
