import { CHAIN_ID } from "@/libs/config/network";
import { BigNumber } from "ethers";

export const GAS_ESTIMATOR_POLL_INTERVAL = 15000 as const;
// export const GAS_PRICE_SPEED = "average" as const;
export const GAS_PRICE_SPEED = "fastest" as const;
export const DEFAULT_GAS_PRICE = {
  MIN: BigNumber.from("3000000000"),
  // MAX: BigNumber.from("10000000000"),
  MAX: BigNumber.from("900000000000"),
} as const;

export const SIO2_PRICE = 0.02;
export const DEV_CHAIN: number[] = [CHAIN_ID.shibuya, CHAIN_ID.dev];

export const PUBLIC_SALE_TOTAL_RASIED = 50000000;
export const PUBLIC_SALE_PRICE = 0.012;
