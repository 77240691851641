/*eslint-disable*/ module.exports = {
  messages: {
    "Audited by": "감사 대상",
    "Powered by": "에 의해 구동",
    "Please, connect your wallet": "지갑을 연결해주세요",
    "Please connect your wallet to see your supplies, borrowings, and open positions.":
      "지갑을 연결하여 소모품, 차입금, 오픈 포지션을 확인하세요.",
    "Connect Wallet": "지갑 연결",
    "You need to change to a supported chain":
      "지원되는 체인으로 변경해야 합니다.",
    "Please switch the chain with your wallet.": "지갑으로 체인을 바꿔주세요.",
    "Failed to switch the chain": "체인 전환 실패",
    "In local-dev mode, only localhost:8545 network can be connected":
      "로컬 개발 모드에서는 localhost:8545 네트워크만 연결할 수 있습니다",
    "SiO2 Finance is not yet depolyed to this network":
      "SiO2 Finance는 아직 이 네트워크에 배포되지 않았습니다.",
    "Please switch to": "로 전환하십시오",
    "Change Network": "네트워크 변경",
    "No Data": "데이터 없음",
    Show: "보여 주다",
    Hide: "숨다",
    "Copy Success": "복사 성공",
    "No account": "계정 없음",
    Network: "회로망",
    "Copy Address": "주소 복사",
    "Astar Safe Wallet": "Astar Safe Wallet",
    "Disconnect Wallet": "지갑 연결 해제",
    Menu: "메뉴",
    "Global Setting": "글로벌 설정",
    "Dark Mode": "다크 모드",
    "Select Language": "언어 선택",
    "Add Tokens": "토큰 추가",
    "We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market\nprice right now. Therefore we calculate APR based on the estimated token price, $\n{SIO2_PRICE}. For more information, please visit our documentation.":
      [
        "우리는 SIO2 토큰을 공개적으로 출시하지 않았으므로 SIO2 토큰은 현재 시장 가격이 없습니다. 따라서 예상 토큰 가격 $ ",
        ["SIO2_PRICE"],
        "을 기준으로 APR을 계산합니다. 자세한 내용은 설명서를 참조하십시오.",
      ],
    Borrow: "빌리다",
    Repay: "갚다",
    Amount: "양",
    Max: "최대",
    "Transaction overview": "거래 개요",
    "Borrow APY": "APY를 빌리다",
    "Borrow APR": "두르다 APR",
    Borrowed: "빌린",
    "Borrow Limit Used": "사용된 차용 한도",
    "Health Factor": "건강 요인",
    Liquidity: "유동성",
    "Since interest and value fluctuate all the time, the maximum value\nof max may not be able to fully pay off all loans.":
      "이자 및 가치는 항상 변동하기 때문에 최대값의 최대값은 모든 대출을 완전히 갚지 못할 수 있습니다.",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor > 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      '여기 조쉬! 첫 번째 주는 크리스탈 광산의 "Canary Week"입니다. 모든 시장 상황에 대비하기 위해 사용자가 4보다 큰 상태를 유지하도록 권장합니다. 자세한 내용은 <0>문서</0>를 읽어보세요.',
    "Josh here!": "Josh here!",
    'Josh here! The first week is the "Canary Week" of our crystal mine. We encourage users to maintain a health factor> 4 to prepare for all market conditions. Read <0>the article</0> for more details.':
      '여기 조쉬! 첫 번째 주는 크리스탈 광산의 "카나리아 주간"입니다. 우리는 사용자가 모든 시장 상황에 대비하기 위해 건강 요소> 4를 유지하도록 권장합니다. 자세한 내용은 <0>문서</0>를 읽어보세요.',
    Collateral: "서로 나란한",
    ON: "켜짐",
    OFF: "끄다",
    Collateral_Amount: "담보 금액",
    "Borrowed Available": "대출 가능",
    Disable: "장애를 입히다",
    Enable: "할 수 있게 하다",
    Deposited: "예치",
    Supply: "공급",
    Withdraw: "철회하다",
    "To receive <0>ASTR staking rewards</0> from <1>Algem</1>, please supply your $nASTR via the <2>SiO2 adapter</2> within the Algem DApp.":
      "<1>Algem</1>으로부터 <0>ASTR 스테이킹 보상</0>을 받으려면 Algem DApp 내의 <2>SiO2 어댑터</2>를 통해 $nASTR을 공급하세요.",
    "Supply APY": "APY 공급",
    "Supply APR": "공급 APR",
    "Borrow Available": "대출 가능",
    "This asset can't be used as collateral.":
      "이 자산은 담보로 사용할 수 없습니다.",
    "Wallet Balance": "지갑 잔액",
    "Since the interest will continue to accumulate when confirming the transaction, the max number entered may not reflect the total you earned when the transaction is complete":
      "거래 확인 시 이자가 계속 누적되므로 입력한 최대 수는 거래 완료 시 적립된 총 금액을 반영하지 않을 수 있습니다.",
    "Since the interest and value fluctuate from time to time, the max\nvalue may not be able to completely remove all deposits":
      "이자 및 가치는 수시로 변동하기 때문에 최대\n값은 모든 예금을 완전히 제거하지 못할 수 있습니다",
    Dashboard: "계기반",
    Market: "시장",
    Stake: "말뚝",
    "Public Sale": "공개 판매",
    Faucet: "수도꼭지",
    "Transaction Preparing": "거래 준비 중",
    "Waiting for transaction to be ready...":
      "거래가 준비되기를 기다리는 중...",
    "Confirm Transaction": "트랜잭션 확인",
    "Approve sending the ERC-20 asset to the pool.":
      "ERC-20 자산을 풀로 보내는 것을 승인합니다.",
    "Transaction Pending": "트랜잭션 보류 중",
    "Waiting for the transaction to be confirmed...":
      "트랜잭션 확인을 기다리는 중...",
    "Approve the contract to borrow ERC-20 assets on your credit.":
      "당신의 신용으로 ERC-20 자산을 빌리는 계약을 승인하십시오.",
    "Confirm the transaction.": "트랜잭션 확인.",
    "Transaction Submitted": "제출된 거래",
    "Succeeded!": "성공!",
    "Your transaction confirmed!": "거래가 확인되었습니다!",
    "Transaction Canceled": "거래 취소됨",
    "You have canceled the transaction.": "거래를 취소했습니다.",
    Error: "오류",
    "Enter amount": "금액 입력",
    "No balance to deposit": "예금할 잔액이 없습니다",
    "No balance to withdraw": "인출할 잔액이 없습니다",
    "Insufficient collateral": "충분하지 않은 담보",
    "No liquidity to withdraw": "인출할 유동성이 없습니다",
    "Health factor too low": "상태 요소가 너무 낮습니다",
    "Borrowing limit reached": "대출 한도에 도달했습니다",
    "No liquidity to borrow": "차입할 유동성이 없습니다",
    "Not enough wallet balance to repay":
      "상환할 지갑 잔액이 충분하지 않습니다",
    "No borrowed balance to repay": "상환할 차입 잔액이 없습니다",
    "Enter leverage": "레버리지 입력",
    "No balance to loop": "루프할 균형이 없습니다",
    "Supply Balance": "공급 균형",
    "Borrow Balance": "차입 잔액",
    Asset: "유산",
    "Reward APR": "보상 APR",
    "Interest Rate": "이자율",
    "Borrow Markets": "차용 시장",
    APY: "APY",
    "The weighted average of APY for all supplied assets,including incentives":
      "센티브를 포함한 모든 공급 자산에 대한 APY의 가중 평균",
    Supplied: "공급",
    "Supply Markets": "공급 시장",
    "Net APY": "순 APY",
    "Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.":
      "순 APY는 인센티브를 포함하여 순자산에 대한 모든 공급 및 차입 포지션의 결합된 효과입니다. 부채 APY가 공급 APY보다 높으면 순 APY가 음수일 수 있습니다.",
    "Your health factor and loan to value determine the assurance of your collateral. To avoid liquidations you can":
      "귀하의 건강 요소와 가치에 대한 대출은 귀하의 담보 보장을 결정합니다. 청산을 피하기 위해 다음을 수행할 수 있습니다.",
    "1. supply more collateral": "1. 더 많은 담보 제공",
    "2. repay borrow positions": "2. 차입금 상환",
    "When your health factor is below 1, your collateral will be liquidated.":
      "건강 계수가 1 미만이면 담보가 청산됩니다.",
    "RISK DETAILS": "위험 세부 정보",
    Claim: "주장하다",
    "Available SIO2 Rewards": "사용 가능한 SIO2 보상",
    "Although the SIO2 rewards accrue every minute, the value on the UI only updates when you transact with the supply and borrow markets. Therefore you may receive more rewards than is displayed when clicking 'Claim'.":
      "SIO2 보상은 매분 발생하지만 UI의 값은 공급 및 차입 시장과 거래할 때만 업데이트됩니다. 따라서 '클레임'을 클릭할 때 표시되는 것보다 더 많은 보상을 받을 수 있습니다.",
    "Learn more": "더 알아보기",
    Name: "이름",
    Action: "동작",
    Mint: "민트",
    "To prevent spam bots, our testnet faucet only allows one mint per asset per day for each user.":
      "스팸 봇을 방지하기 위해 테스트넷 수도꼭지는 각 사용자에 대해 자산당 하루에 하나의 민트만 허용합니다.",
    "Total Supplied": "총 공급",
    "Total Borrowed": "총 차입금",
    "Supply Reward APR": "보상 APR 공급",
    "Borrow Interest Rate": "대출금리",
    "Borrow Reward APR": "보상 4월 빌리기",
    Details: "세부",
    Price: "가격",
    Borrowing: "차용",
    Yes: "예",
    No: "아니요",
    "Reserve Factor": "리저브 팩터",
    "Used as Collateral": "담보로 사용",
    "Loan to Value": "가치에 대한 대출",
    "Liquidation Threshold": "청산 기준",
    "Liquidation Penalty": "청산 패널티",
    "Market Details": "시장 세부 정보",
    "Asset Pools": "자산 풀",
    Detail: "세부 사항",
    Others: "기타",
    total: "합계",
    "Top3 Markets": "상위 3개 시장",
    "Astar Network": "아스타네트워크",
    Token: "토큰",
    "SIO2 Token(SIO2)": "SIO2 토큰(SIO2)",
    "Total Raised": "모금",
    "Sales Opened": "판매 개시",
    TBD: "보류 중",
    "Sales Closed": "영업 종료",
    "This page is developed for public sale on mainnet, but not testnet.":
      "이 페이지는 테스트넷이 아닌 메인넷에서 공개 판매를 위해 개발되었습니다.",
    "SIO2 PUBLIC SALE": "SIO2 공개 판매",
    "Token Release Rules: 2-year vesting with a 6-month cliff after\nlaunch":
      "토큰 출시 규칙: 출시 후 6개월 벼랑과 함께 2년 베스팅",
    Purchase: "구입",
    "$ {0}": ["$ ", ["0"]],
    "Stake SIO2": "스테이킹 SIO2",
    Staking: "스테이크",
    "SIO2 holders can stake their SIO2 in the Safety Module to add more\nsecurity to the protocol and earn Safety Incentives. In the case of a\nshortfall event, up to 30% of your stake can be slashed to cover the\ndeficit, providing an additional layer of protection for the protocol.":
      "SIO2 보유자는 SIO2를 안전 모듈에 스테이킹하여 프로토콜에 보안을 추가하고 안전 인센티브를 받을 수 있습니다. 부족 이벤트의 경우, 적자를 충당하기 위해 지분의 최대 30%를 삭감하여 프로토콜에 대한 추가 보호 계층을 제공할 수 있습니다.",
    "Total emission per day": "하루 총 배출량",
    "Claim Rewards": "보상 청구",
    "Funds in the Safety Module": "안전 모듈의 자금",
    "1 year": "1 년",
    "2 years": "2 년",
    "3 years": "3 년",
    "The amount you're trying to stake exceeds your balance by {0} SIO2": [
      "스테이킹하려는 금액이 귀하의 잔액을 ",
      ["0"],
      " SIO2 초과합니다.",
    ],
    "Locked Staking": "고정 스테이킹",
    Balance: "균형",
    "Duration (years)": "기간(년)",
    "Pool Share": "풀 쉐어",
    Confirm: "확인하다",
    ID: "ID",
    "Staked Amount": "스테이킹 금액",
    "Weighted Staked Amount": "가중 스테이킹 금액",
    "Date Time": "날짜 시간",
    "Locked Until": "까지 잠김",
    Redeem: "상환하다",
    "Locked Stakes": "잠긴 지분",
    "You don't have any locked stakes yet. Start by staking your SIO2 tokens.":
      "아직 잠긴 지분이 없습니다. SIO2 토큰을 스테이킹하여 시작하십시오.",
    Summary: "요약",
    "Staking APR": "스테이킹 APR",
    "Unweighted Stake": "가중되지 않은 지분",
    "Weighted Stake": "가중 지분",
  },
};
