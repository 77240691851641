import { useLocation } from "react-router-dom";
import "./index.less";

import { Button, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useResponsive } from "ahooks";
import MobileDraw from "./MobileDraw";
import { useCallback, useEffect, useState } from "react";

import ConnectDropDown from "./ConnectDropDown";
import { useWallet } from "@/hook/useWallet";
import { addERC20TokenToMetamask } from "@/libs/wallet-provider";
import { useMarketData } from "@/hook/useMarketData";
import { getNetworkConfig } from "@/libs/config";
import { ASSETS_DICT } from "@/constants/assets";
import { ChainId } from "@/libs/config/network";
import { RouteObject_, useRoutesArr } from "@/hook/app/useRoutesArr";
import { SIO2_PRICE } from "@/constants/config";
import SettingDropDown from "./SettingDropDown";
import { t } from "@lingui/macro";
import { useNavigate } from "@/hook/app/useNavigate";
import { useAppSetting } from "@/hook/app/useAppSetting";
import { useModalContext } from "@/hook/app/useModalContext";

export type TopHeaderProps = {
  renderLeft?: () => React.ReactNode;
  title?: string;
  renderRight?: () => React.ReactNode;
};

const TopHeader: React.FC<TopHeaderProps> = (props) => {
  const location = useLocation();
  const navigatePush = useNavigate();
  const responsive = useResponsive();
  const { routes } = useRoutesArr();
  const { account, connect, chainId } = useWallet();
  const [loading, setLoading] = useState(false);
  const [scrollBackground, setScrollBackground] = useState(false);
  const { settingData } = useAppSetting();
  const { data } = useMarketData();
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 10) {
      setScrollBackground(true);
    } else {
      setScrollBackground(false);
    }
  };

  const connectWallet = useCallback(async () => {
    try {
      setLoading(true);
      await connect("Metamask").catch(() => {});
      setLoading(false);
      // openContent({
      //   title: t`Connect Wallet`,
      //   message: "TESTS",
      //   type: "connectWallet",
      // });
    } catch (e) {
      console.log("error on activation", e);
      // disconnectWallet();
    }
  }, []);

  const onAddTokensClick = async () => {
    data?.assets.forEach(async (item) => {
      await addERC20TokenToMetamask(item.displaySymbol || item.symbol, item.underlyingAsset, item.decimals);
    });
    data?.assets.forEach(async (item) => {
      await addERC20TokenToMetamask(`s${item.displaySymbol || item.symbol}`, item.sTokenAddress, item.decimals);
    });
    onAddSio2Click();

    //
  };
  const onAddSio2Click = async () => {
    if (chainId) {
      const { rewardToken } = getNetworkConfig(chainId as ChainId);
      await addERC20TokenToMetamask(ASSETS_DICT.SiO2.symbol, rewardToken.underlyingAsset);
    }
  };

  const clickLinkItem = (link: RouteObject_) => {
    navigatePush(link.path);
  };
  const linkList = routes.filter((item) => item.isTopHeader);

  return (
    <div className="top-header">
      <div className={`top-header-main ${scrollBackground ? "scroll-change" : ""}`}>
        <div className="left-wrap">
          <div
            className="img-wrap"
            style={{ cursor: "pointer" }}
            onClick={() => (window.location.href = "https://www.sio2.finance/")}
          >
            <img
              src={settingData?.theme === "dark" ? require("@img/logo-dark.png") : require("@img/logo.png")}
              alt=""
            />
          </div>
          {responsive.md ? (
            <div className="list-wrap">
              {linkList.map((link) => {
                let isActive = location.pathname.includes(link.path);
                if (link.path === "/dashboard" && location.pathname === "/") {
                  isActive = true;
                }
                return (
                  <div className={`link-item ${isActive ? "active" : ""}`} key={link.path}>
                    <div className="link" onClick={() => clickLinkItem(link)}>
                      {isActive && <span className="iconfont icon-right"></span>}
                      {link.label}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="right-wrap">
          {responsive.md ? (
            <Button type="primary" className="add-token" onClick={onAddTokensClick}>
              {t`Add Tokens`}
            </Button>
          ) : null}
          <Button className="btn-main sio2-price" onClick={onAddSio2Click}>
            <img src={require("@img/symbols/symbol_sio2.png")} alt="" />
            <span>$ {SIO2_PRICE}</span>
            <Tooltip
              title={t` We have not launched the SIO2 token publicly, so the SIO2 token doesn't have a market
              price right now. Therefore we calculate APR based on the estimated token price, $
              ${SIO2_PRICE}. For more information, please visit our documentation.`}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Button>

          {account ? (
            <ConnectDropDown />
          ) : (
            <Button loading={loading} type="primary" onClick={connectWallet}>
              {t`Connect Wallet`}
            </Button>
          )}
          {responsive.md ? (
            <SettingDropDown />
          ) : (
            <MobileDraw linkList={linkList} onAddTokensClick={onAddTokensClick}></MobileDraw>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
