import BigNumber from 'bignumber.js';
import { rayDiv } from '../../ray.math';
import { calculateAccruedIncentives } from './calculate-accrued-incentives';
import {
  ReserveIncentiveWithFeedsResponse,
  UserReserveCalculationData,
  UserReserveIncentiveDataHumanizedResponse,
} from './types';

export interface CalculateUserReserveIncentivesRequest {
  reserveIncentives: ReserveIncentiveWithFeedsResponse; // token incentive data, from UiIncentiveDataProvider
  userReserveIncentives: UserReserveIncentiveDataHumanizedResponse; // user incentive data, from UiIncentiveDataProvider
  currentTimestamp: number;
  userReserveData: UserReserveCalculationData;
}

export interface CalculateUserReserveIncentivesResponse {
  sIncentives: BigNumber; // deposit incentives
  vdIncentives: BigNumber; // variable debt incentives
  sdIncentives: BigNumber; // stable debt incentives
}

// Calculate user deposit and borrow incentives for an individual reserve asset
export function calculateUserReserveIncentives({
  reserveIncentives,
  userReserveIncentives,
  currentTimestamp,
  userReserveData,
}: CalculateUserReserveIncentivesRequest): CalculateUserReserveIncentivesResponse {
  const totalDeposits = rayDiv(
    new BigNumber(userReserveData.totalLiquidity),
    new BigNumber(userReserveData.liquidityIndex),
  );
  const sIncentivesRequest = {
    principalUserBalance: new BigNumber(userReserveData.scaledSTokenBalance),
    reserveIndex: new BigNumber(
      reserveIncentives.sIncentiveData.tokenIncentivesIndex,
    ),
    userIndex: new BigNumber(
      userReserveIncentives.STokenIncentivesUserData.tokenIncentivesUserIndex,
    ),
    precision: reserveIncentives.sIncentiveData.precision,
    rewardTokenDecimals: reserveIncentives.sIncentiveData.rewardTokenDecimals,
    reserveIndexTimestamp:
      reserveIncentives.sIncentiveData.incentivesLastUpdateTimestamp,
    totalSupply: totalDeposits,
    currentTimestamp,
    incentiveIntialSupply:
      reserveIncentives.sIncentiveData.incentiveIntialSupply,
    incentiveInflactionStart:
      reserveIncentives.sIncentiveData.incentiveInflactionStart,
    incentiveDecayRatio: reserveIncentives.sIncentiveData.incentiveDecayRatio,
  };

  const vdIncentivesRequest = {
    principalUserBalance: new BigNumber(userReserveData.scaledVariableDebt),
    reserveIndex: new BigNumber(
      reserveIncentives.vdIncentiveData.tokenIncentivesIndex,
    ),
    userIndex: new BigNumber(
      userReserveIncentives.vdTokenIncentivesUserData.tokenIncentivesUserIndex,
    ),
    precision: reserveIncentives.vdIncentiveData.precision,
    rewardTokenDecimals: reserveIncentives.vdIncentiveData.rewardTokenDecimals,
    reserveIndexTimestamp:
      reserveIncentives.vdIncentiveData.incentivesLastUpdateTimestamp,
    totalSupply: new BigNumber(userReserveData.totalScaledVariableDebt),
    currentTimestamp,
    incentiveIntialSupply:
      reserveIncentives.vdIncentiveData.incentiveIntialSupply,
    incentiveInflactionStart:
      reserveIncentives.vdIncentiveData.incentiveInflactionStart,
    incentiveDecayRatio: reserveIncentives.vdIncentiveData.incentiveDecayRatio,
  };

  const sdIncentivesRequest = {
    principalUserBalance: new BigNumber(userReserveData.principalStableDebt),
    reserveIndex: new BigNumber(
      reserveIncentives.sdIncentiveData.tokenIncentivesIndex,
    ),
    userIndex: new BigNumber(
      userReserveIncentives.sdTokenIncentivesUserData.tokenIncentivesUserIndex,
    ),
    precision: reserveIncentives.sdIncentiveData.precision,
    rewardTokenDecimals: reserveIncentives.sdIncentiveData.rewardTokenDecimals,
    reserveIndexTimestamp:
      reserveIncentives.sdIncentiveData.incentivesLastUpdateTimestamp,
    totalSupply: new BigNumber(userReserveData.totalPrincipalStableDebt),
    currentTimestamp,
    incentiveIntialSupply:
      reserveIncentives.sdIncentiveData.incentiveIntialSupply,
    incentiveInflactionStart:
      reserveIncentives.sdIncentiveData.incentiveInflactionStart,
    incentiveDecayRatio: reserveIncentives.sdIncentiveData.incentiveDecayRatio,
  };

  const sIncentives = calculateAccruedIncentives(sIncentivesRequest);
  const vdIncentives = calculateAccruedIncentives(vdIncentivesRequest);
  const sdIncentives = calculateAccruedIncentives(sdIncentivesRequest);

  return { sIncentives, vdIncentives, sdIncentives };
}
